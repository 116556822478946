import { useContext, useState, useEffect } from 'react'

// ASSETS
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect-icon-only.png'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'
import PhotoProfile from './PhotoProfile/PhotoProfile'
import CompanyInformation from './CompanyInformation/CompanyInformation'
import EditProfile from './EditProfile/EditProfile'
import DialogChangePassword from 'components/DialogChangePassword/DialogChangePassword'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// DATA DUMMY
import { dummyFileAttachment } from './profileData'

// MUIS
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import DeleteIcon from '@mui/icons-material/Delete'
import ImageIcon from '@mui/icons-material/Image'
import UploadFileIcon from '@mui/icons-material/UploadFile'

// SERVICE
import { getUserProfile, changeUserPassword } from 'services/profile'

// STYLES
import useStyles from './profileUseStyles'

// UTILS
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const Profile = () => {
  const { auth, setAuth, setSnackbarObject} = useContext(AllPagesContext)

  const initialColumns = [
    {
      field: 'document_title',
      headerName: 'Document Title',
      flex: 1,
      minWidth: 200,
      hide: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <CustomTooltipBlack 
            title='View file' 
            placement='bottom'
          >
            <Button 
              variant='text' 
              startIcon={<ImageIcon className={classes.imageIcon}/>}
              className={classes.imageIcon}
            >
              {params.value}
            </Button>
          </CustomTooltipBlack>
        )
      }
    },
    {
      field: 'upload_date',
      headerName: 'Upload Date',
      flex: 1,
      minWidth: 200,
      hide: false,
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 200,
      hide: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack 
            direction='row'
            alignItems='center'
          >
            {/* UPLOAD */}
            <CustomTooltipBlack 
              title='Upload file' 
              placement='bottom'
            >
              <IconButton onClick={() => console.log('upload')}>
                <UploadFileIcon/>
              </IconButton>
            </CustomTooltipBlack>

            {/* DELETE */}
            <CustomTooltipBlack 
              title='Delete file' 
              placement='bottom'
            >
              <IconButton onClick={() => console.log('delete')}>
                <DeleteIcon/>
              </IconButton>
            </CustomTooltipBlack>
          </Stack>
        )
      }
    },
  ]

  const initialNewPasswordForm = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  const initialUpdateProfileForm = {
    personInCharge: auth?.fullName?? '-',
    organizationName: auth?.organizationName?? '-',
    email: auth?.email?? '-',
    phoneNumber: auth?.phoneNumber?? '-',
    address: auth?.address?? '-',
    // npwpNumber: '',
    // siubNumber: '',
    // nibNumber: '',
    // ktaAsdeki: '',
  }

  const classes = useStyles()
  const [ search, setSearch ] = useState('')
  const [ isEditprofile, setIsEditProfile ] = useState(false)
  const [ updateProfileForm, setUpdateProfileForm ] = useState(initialUpdateProfileForm)
  const [ dialogConfirmationObject, setDialogConfirmationObject ] = useState({})
  const [ newPassword, setNewPassword ] = useState(initialNewPasswordForm)
  const [ userProfile, setUserProfile ] = useState(null)

  const companyProfilData = [
    {
      title: 'Person in charge',
      value: userProfile?.fullName?? '-'
    },
    {
      title: 'Organization name',
      value: userProfile?.organizationName?? '-'
    },
    {
      title: 'Company email',
      value: userProfile?.companyEmail?? '-'
    },
    {
      title: 'Phone number',
      value: userProfile?.phoneNumber?? '-'
    },
    {
      title: 'Alamat',
      value: userProfile?.address?? '-'
    },
    // {
    //   title: 'NPWP number',
    //   value: '-'
    // },
    // {
    //   title: 'SIUP number',
    //   value: '-'
    // },
    // {
    //   title: 'NIB number',
    //   value: '-'
    // },
    // {
    //   title: 'KTA Asdeki',
    //   value: '-'
    // },
  ]
  
  const handleConfirmationContinueButtonClick = async () => {
    const abortController = new AbortController()
 
    let bodyParams = {
      old_password: newPassword?.currentPassword,
      new_password: newPassword?.newPassword,
      confirm_new_password: newPassword?.confirmNewPassword
    }
 
    const resultChangePassword = await changeUserPassword(abortController.signal, auth.accessToken, bodyParams)

    if(resultChangePassword.status === 200) {
      setDialogConfirmationObject({})
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully changed the password',
      })
    } 
    
    else if(resultChangePassword.status !== 200) {
      setDialogConfirmationObject({})
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to change the password',
      })
    }
  }

  const loadUserProfileData = async (inputIsMounted, inputAbortController) => {
    const resultUserProfile = await getUserProfile(inputAbortController.signal, auth.accessToken)

    if (resultUserProfile.status === 200 && inputIsMounted) {
      const userObject = {
        ...auth,
        ...resultUserProfile.data,
      }

      setUserProfileToLocalStorage(userObject)
      setAuth(userObject)
      setUserProfile(resultUserProfile.data)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadUserProfileData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <>
      {/* APPBAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='NLE Account'
        hasFab={false}
        hasSearch={false}
      />

      {/* MAIN CONTENT */}
      <LoadingPaper isLoading={false} className={classes.mainContent}>
        {/* CONTENT LEFT / PHOTO PROFILE */}
        <PhotoProfile 
          photoProfile={LogoProductNleConnet}
          setDialogConfirmationObject={setDialogConfirmationObject}
        />
        
        {/* CONTENT RIGHT / COMPANY INFORMATION */}
        { isEditprofile ? 
          <EditProfile
            updateProfileForm={updateProfileForm}
            setUpdateProfileForm={setUpdateProfileForm}
            setIsEditProfile={setIsEditProfile}
            reloadData={loadUserProfileData}
          /> :
          <CompanyInformation
            detailCompany={companyProfilData}
            initialColumns={initialColumns}
            rows={dummyFileAttachment}
            setIsEditProfile={setIsEditProfile}
          /> 
        }
        
      </LoadingPaper>

      {/* DIALOG CONFIRMATION */}
      <DialogChangePassword
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        onContinueButtonClick={handleConfirmationContinueButtonClick}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
        setNewPassword={setNewPassword}
      />
    </>
  )
}

export default Profile