import { useState, useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

// MUIS
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

// MUI ICONS
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ClassIcon from '@mui/icons-material/Class'
import DescriptionIcon from '@mui/icons-material/Description'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EventNoteIcon from '@mui/icons-material/EventNote'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'

// SERVICE
import { downloadInvoice, cancelBookingOrder } from 'services/order'

// STYLES
import useStyles from './itemDetailUseStyles'

// UTILITIES
import {
  addSeparatorsForNumber,
  capitalizeEachWord,
} from 'utilities/string'
import { convertDate } from 'utilities/date'

const ItemDetail = ({ itemDetail, reloadData }) => {
  const classes = useStyles()
  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true)
  const [isOrderDetailExpanded, setIsOrderDetailExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dialogConfirmationObject, setDialogConfirmationObject] = useState({})

  const getStatusBooking = (inputItem) => {
    if (inputItem === 'WAITING') return 'Unpaid'
    else if (inputItem === 'SUCCESS') return 'Paid'
    else if (inputItem === 'CANCEL') return 'Canceled'
    else if (inputItem === 'EXPIRED') return 'Booking expired'
    else return '-'
  }

  const calculatetotalPrice = (inputItem) => {
    let tempTotalPrice = 0
    let calculate = inputItem.forEach((item) => (tempTotalPrice += item?.price ?? 0))
    return addSeparatorsForNumber(tempTotalPrice)
  }

  // HANDLE DOWNLOAD INVOICE
  const handleDownloadInvoice = async () => {
    setIsLoading(true)
    const abortController = new AbortController()
    const resultDownloadInvoice = await downloadInvoice(abortController.signal, auth.accessToken, itemDetail.id)

    if (resultDownloadInvoice.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resultDownloadInvoice.data]))
      let alink = document.createElement('a')
      alink.href = url
      alink.download = 'invoice.pdf'
      alink.click()

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully download the file',
      })

      setIsLoading(false)
    } else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Failed download file',
      })
    }
  }

  // HANDLE CANCEL BUTTON
  const handleCancelButton = async () => {
    const abortController = new AbortController()

    const resultCancelOrder = await cancelBookingOrder(abortController.signal, auth.accessToken, itemDetail?.id)

    if (resultCancelOrder.status === 200) {
      // RELOAD DATA GRID LIST ITEM
      reloadData(true, abortController.signal)
      // SHOW SNACKBAR
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully cancel the order',
      })
      // CLSOE DIALOG CONFIRMATION
      setDialogConfirmationObject({})
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to cancel the order',
      })
      setDialogConfirmationObject({})
    }
  }
  console.log(itemDetail)
  return (
    <Stack
      justifyContent='space-between'
      height='100%'
    >
      <Stack
        sx={{ overflowY: 'auto' }}
        height='100%'
      >
        {/* MAIN INFORMATION */}
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          {/* MAIN TITLE */}
          <Typography className={classes.mainTitle}>
            Main Information
          </Typography>

          {/* EXPAND ICON */}
          <IconButton onClick={() => setIsMainInfoExpanded(current => !current)}>
            <ExpandMoreIcon sx={{
              transform: isMainInfoExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.7s'
            }} />
          </IconButton>
        </Stack>

        {/* MAIN INFORMATION CONTENT */}
        <Collapse sx={{ minHeight: 'unset !important' }} in={isMainInfoExpanded} timeout='auto' unmountOnExit>
          {/* STATUS */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <FiberManualRecordIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText primary='Status' secondary={getStatusBooking(itemDetail?.booking_status)} />
            </ListItem>
          </List>

          {/* BOOKING TYPE */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <ClassIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText primary='Booking' secondary={capitalizeEachWord(itemDetail?.booking_type ?? '-')} />
            </ListItem>
          </List>

          {/* TRANSACTION DATE */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <EventNoteIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText primary='Transaction Date' secondary={convertDate(itemDetail?.paid_date, 'dd MMM yyyy HH:mm aa') || '-'} />
            </ListItem>
          </List>

          {/* BOL NO */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <BookmarkIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText primary='Bill of Lading No.' secondary={itemDetail?.bill_landing || '-'} />
            </ListItem>
          </List>

          {/* CUSTOMER NAME */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <FactCheckIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText primary='Customer Name' secondary={itemDetail?.full_name || '-'} />
            </ListItem>
          </List>

          {/* INVOICE */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.invoiceListItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <DescriptionIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText
                onClick={handleDownloadInvoice}
                primary='Invoice'
                secondary={itemDetail?.invoice_no ?? '-'}
              />
            </ListItem>
          </List>

          {/* TOTAL AMOUNT */}
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <LocalOfferIcon className={classes.listIcon} />
              </ListItemAvatar>
              <ListItemText primary='Total Amount' secondary={`IDR ${calculatetotalPrice(itemDetail?.items)}`} />
            </ListItem>
          </List>
        </Collapse>

        {/* ORDER DETAILS */}
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          {/* MAIN TITLE */}
          <Typography className={classes.mainTitle}>
            Order Details
          </Typography>

          {/* EXPAND ICON */}
          <IconButton onClick={() => setIsOrderDetailExpanded(current => !current)}>
            <ExpandMoreIcon sx={{
              transform: isOrderDetailExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.7s'
            }} />
          </IconButton>
        </Stack>

        {/* ORDER DETAIL CONTENT */}
        <Collapse in={isOrderDetailExpanded} timeout='auto' unmountOnExit>
          {itemDetail?.items?.map((item, index) => (
            <List key={index} className={classes.list} disablePadding>
              <ListItem className={classes.orderDetailListItem}>
                <ListItemAvatar className={classes.listAvatar}>
                  <StickyNote2Icon className={classes.listIcon} />
                </ListItemAvatar>
                {itemDetail.booking_type === 'LOADING' ?
                  <ListItemText primary={`${item.item_name.itemCode} - ${item.item_name.itemType}`} secondary={`${item?.quantity} x IDR ${addSeparatorsForNumber(item.price / item?.quantity)}`} /> :
                  <ListItemText primary={`${item.item_name.itemCode} - ${item.item_name.itemType}`} secondary={`IDR ${addSeparatorsForNumber(item.price)}`} />
                }

              </ListItem>
            </List>
          ))}
        </Collapse>
      </Stack>

      {/* CANCEL BUTTON */}
      <Stack>
        <Button
          onClick={() => setDialogConfirmationObject(itemDetail)}
          variant='outlined'
          disabled={itemDetail?.invoice_url !== null && itemDetail?.booking_status === 'WAITING' ? false : true}
        >
          Cancel Order
        </Button>
      </Stack>


      {/* LOADING COMPONENT */}
      <LoadingComponent isLoading={isLoading} />

      {/* DIALOG CONFIRMATION */}
      <DialogConfirmation
        title='Confirm cancel order'
        caption='Are you sure to cancel this order?'
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        cancelButtonText='Back'
        continueButtonText='Yes, cancel.'
        onContinueButtonClick={() => handleCancelButton()}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
      />
    </Stack>
  )
}

export default ItemDetail