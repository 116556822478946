import React from 'react'
import PropTypes from 'prop-types'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './flyoutUseStyles'

const Flyout = (props) => {
  const { 
    children,
    isFlyoutShown,
    flyoutWidth,
  } = props

  const classes = useStyles()

  return (
    // NOTE: HAS TRIED TO USE SLIDE COMPONENT BUT STILL FAILED
    <Box 
      className={classes.flyout}
      sx={{ 
        width: `${flyoutWidth}px`, 
        transform: isFlyoutShown ? 'none' : `translateX(${flyoutWidth + 24}px)`, 
        transition: 'transform 1s'
      }}
    >
      {children}
    </Box>
  )
}

Flyout.defaultProps = {
  isFlyoutShown: false,
  flyoutWidth: 400,
}

Flyout.propTypes = {
  children: PropTypes.node.isRequired,
  isFlyoutShown: PropTypes.bool.isRequired,
  flyoutWidth: PropTypes.number.isRequired,
}

export default Flyout