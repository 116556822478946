// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnActionsButtonEdit: {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.outlinedHoverBackground,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.outlinedHoverBackground, 0.24),
    },
  },
  columnActionsButtonDelete: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.outlinedHoverBackground,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.outlinedHoverBackground, 0.24),
    },
  },
}))

export default useStyles