import { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

// ASSETS
import LogoProductIconOnly from 'assets/images/logos/product-nle-connect-icon-only.svg'
import LogoProductIconOnlyPng from 'assets/images/logos/product-nle-connect-icon-only.png'

// COMPONENTS
import AuthenticationRoute from 'components/Routes/AuthenticationRoute'
import PrivateRoute from 'components/Routes/PrivateRoute'
import Snackbar from 'components/Snackbar/Snackbar'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
// import { useTheme } from '@mui/material/styles'

// ROUTES
import routes from 'routes/routes'

const App = () => {
  const { snackbarObject, setSnackbarObject } = useContext(AllPagesContext)

  // const theme = useTheme()

  const getRouteComponent = (inputItem) => {
    if (inputItem.routeType === 'authentication') {
      return (
        <AuthenticationRoute>
          {inputItem.element}
        </AuthenticationRoute>
      )
    }
    else if (inputItem.routeType === 'private') {
      return (
        <PrivateRoute>
          {inputItem.element}
        </PrivateRoute>
      )
    }
    else if (inputItem.routeType === 'free') return inputItem.element
  }

  useEffect(() => {
    // UPDATE FAVICON
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = LogoProductIconOnly

    const appleTouchIconElement = document.getElementById('apple-touch-icon')
    appleTouchIconElement.href = LogoProductIconOnlyPng

    // CHANGE THE CHAT BOT STYLE (INTENTIONALLY COMMENTED)
    // window.zESettings = {
    //   webWidget: {
    //     color: { theme: theme.palette.primary.main },
    //     offset: { 
    //       horizontal: '24px', 
    //       vertical: '80px', 
    //     },
    //   },
    // }
  }, [])

  return (
    <>
      <Routes>
        {routes.map((item, index) => (
          <Route 
            key={index}
            path={item.path} 
            element={getRouteComponent(item)}
          />
        ))}
      </Routes>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />
    </>
  )
}

export default App
