import { useContext, useState } from 'react'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { 
  Autocomplete,
  FormControl,
  FormHelperText,
  Button,
  Stack, 
  Typography,
  TextField,
} from '@mui/material'

// MUI ICONS
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import SaveIcon from '@mui/icons-material/Save'

// STYLES 
import useStyles from '../../orderUseStyles'

const EditPaymentMethod = ({ setIsEditingPaymentMethod }) => {
  const classes = useStyles()
  const {orderForms, setOrderForms} = useContext(PrivateLayoutContext)
  const { setSnackbarObject } = useContext(AllPagesContext)
  
  const [isValidatingForms, setIsValidatingForms] = useState(false)
  
  const paymentMethodList = [
    // {
    //   label : 'Mandiri Virtual Account',
    //   value : 'MANDIRI'
    // },
    // {
    //   label : 'BCA Virtual Account',
    //   value : 'BCA'
    // },
    {
      label : 'BRI Virtual Account',
      value : 'BRI',
    },
    {
      label : 'BNI Virtual Account',
      value : 'BNI'
    },
    {
      label : 'Permata Bank Virtual Account',
      value : 'PERMATA'
    },
    {
      label : 'BSI Virtual Account',
      value : 'BSI'
    },
    {
      label : 'Bank BJB Virtual Account',
      value : 'BJB'
    },
  ]
  
  // HANDLE PAYMENT METHOD CHANGE
  const handlePaymentMethodChange = (inputValue) => {
    setOrderForms(current => ({
      ...current,
      paymentMethod: inputValue,
    }))
  }

  // HANDLE FIELD VALIDATION
  const handleFieldValidation = () => {
    if(orderForms.paymentMethod === '') {
      setIsValidatingForms(true)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Required fields must be filled in',
      })
    } else {
      setIsValidatingForms(false)
      setIsEditingPaymentMethod(false)
    }
  }

  return (
    <Stack>
      {/* TITLE */}
      <Stack
        marginTop='20px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography className={classes.typography1}>
          Contact Order Details
        </Typography>

        <Button 
          startIcon={<SaveIcon/>}
          variant='outlined'
          onClick={handleFieldValidation}
        >
          Save
        </Button>
      </Stack>

      {/* BOOKING */}
      <Stack
        direction='row'
        alignItems='center'  
        marginTop='20px'   
      >
        <AccountBalanceIcon className={classes.formIcon}/>
        <FormControl 
          fullWidth
          error
        >
          <Autocomplete
            disableClearable
            fullWidth
            disablePortal
            options={paymentMethodList}
            value={orderForms?.paymentMethod || null}
            onChange={(event, value) => handlePaymentMethodChange(value)}
            isOptionEqualToValue={(option, value) => option?.label === value?.label}
            renderInput={(params) => <TextField {...params} variant='standard' label='Payment Method' />}
          />
          {/* HELPER TEXT */}
          {(isValidatingForms && !orderForms.paymentMethod ) && 
          <FormHelperText>This field can't be empty</FormHelperText>}
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default EditPaymentMethod