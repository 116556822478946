// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 704,
  },
  headerCloseIcon: {
    marginLeft: 15,
    marginRight: 16,
    cursor: 'pointer'
  },
  divider: {
    marginTop: 30,
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500,
  },
  typography2: {
    fontSize: 14,
    fontWeight: 500,
  },
  formIcon: {
    color: '#00000099',
    marginRight: 12,
    marginTop: 12,
  },
  fleetManagerForm: {
    width: '100%',
  },
  skuForm: {
    width: '100%'
  }
}))

export default useStyles