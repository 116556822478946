// MUIS
import { 
  Button, 
  IconButton, 
  Stack, 
  Typography 
} from '@mui/material'

// MUI ICON
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

// STYLES
import useStyles from '../reportUseStyles'

// UTILS
import { convertDate } from 'utilities/date'

const GeneratedReport = (props) => {
  const classes = useStyles()

  const { 
    isContentExpanded,
    seIsContentExpanded,
    setIsCreatingReport,
    setIsReportMovesShown,
    setIsReportPaymentShown,
  } = props

  const dummyReport = [
    {
      name: 'Report on moves',
      date: new Date(),
      type: 'moves'
    },
    {
      name: 'Report payment',
      date: new Date(),
      type: 'payment'
    },
  ]

  // HANDLE REPORT LIST CLICK
  const handleReportListClick = (inputItem) => {
    if(inputItem.type === 'moves') {
      setIsReportMovesShown(current => !current)
      setIsReportPaymentShown(false)
    }
    else if(inputItem.type === 'payment') {
      setIsReportPaymentShown(current => !current)
      setIsReportMovesShown(false)
    }
  }

  return (
    <Stack 
      sx={{
        width: isContentExpanded ? '240px' : '40px',
        transition: 'width 0.5s',
      }}
      padding={isContentExpanded ? '0px' : '20px 0px'}
      className={classes.generatedReportRoot}
    >
      {/* TITLE */}
      <Stack
        direction={isContentExpanded ? 'row' : 'column'}
        padding={isContentExpanded ? '20px 20px 0px 20px' : '0px'}
        alignItems='center'
        justifyContent='space-between'
      >
        { isContentExpanded && <Typography className={classes.typography1}>
          Generated reports
        </Typography>}

        <IconButton 
          onClick={() => seIsContentExpanded(current => !current)}
          size='small'
        >
          <KeyboardArrowLeftIcon
            sx={{
              transform: isContentExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.5s',
            }}
          />
        </IconButton>

        { !isContentExpanded && <Typography className={classes.verticalText}>
          Generated reports
        </Typography>}
      </Stack>

      {/* CREATE REPORT */}
      {isContentExpanded && 
      <Stack 
        width='160px' 
        paddingLeft='15px'
        marginBottom='12px'
      >
        <Button 
          sx={{textTransform: 'none'}}
          startIcon={<AddIcon/>}
          onClick={() => {
            setIsCreatingReport(true)
            setIsReportMovesShown(false)
            setIsReportPaymentShown(false)
          }}
        >
          Create report
        </Button>
      </Stack>}

      {/* REPORT LIST */}
      {isContentExpanded && dummyReport.map((item, index) => (
        <Stack 
          key={index}
          borderBottom='1px solid #0000001F'
          borderTop={index === 0 ? '1px solid #0000001F' : 'unset'}
          padding='4px 20px'
          sx={{cursor: 'pointer'}}
          onClick={() => handleReportListClick(item)}
        >
          <Typography className={classes.typography2}>
            {item.name}
          </Typography>
          <Typography className={classes.typography3}>
            Generated: {convertDate(item.date, 'dd MMM yyyy HH:mm a')}
          </Typography>
        </Stack>
      ))}
      
      
    </Stack>
  )
}

export default GeneratedReport