// MUIS
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

// MUI X DATA GRIDS
import { useGridApiContext } from '@mui/x-data-grid'

const CellEditSelect = (props) => {
  const { 
    id, value, field, 
    inputList,
  } = props

  const apiContextRef = useGridApiContext()

  return (
    <FormControl fullWidth>
      {/* SELECT */}
      <Select
        value={value}
        onChange={(event) => apiContextRef.current.setEditCellValue({
          id, 
          field, 
          value: event.target.value,
        })}
      >
        {inputList.map((item, index) => (
          <MenuItem 
            key={index}
            value={item.value}
          >
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CellEditSelect