// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      padding: 16,
      width: 480,
      borderRadius: 4,
    },
  },
  dialogContent: {
    margin: '12px 0px',
    padding: '0px 4px',
  },
  fieldNumber : {
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }
  },
}))

export default useStyles