import { useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// UTILITIES
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const Impersonate = () => {
  const navigate = useNavigate()
  const params = useParams()

  const { setAuth } = useContext(AllPagesContext)

  useEffect(() => {
    const userObject = { accessToken: params.token }

    setUserProfileToLocalStorage(userObject)
    setAuth(userObject)
    navigate('/')
  }, [])

  return (<></>)
}

export default Impersonate