// ASSETS
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect.svg'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheck from '@mui/icons-material/Check'

// MUI LABS
import LoadingButton from '@mui/lab/LoadingButton'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

const ResetPasswordSuccess = () => {
  const layoutClasses = useLayoutStyles()

  return (
    <Stack alignItems='center'>
      {/* PRODUCT LOGO */}
      <Box
        component='img'
        src={LogoProductNleConnet}
        alt=''
        className={layoutClasses.productLogo}
      />

      {/* SUCCESS ICON */}
      <Avatar 
        className={`${layoutClasses.infoAvatar2} ${layoutClasses.infoItem}`}
      >
        <IconCheck className={layoutClasses.infoAvatarIcon2}/>
      </Avatar>

      {/* SUCCESS TEXT */}
      <Typography
        variant='subtitle1'
        className={layoutClasses.infoItem}
      >
        <span className='textSemiBold'>
          Your password has been<br/>
          changed successfully
        </span>
      </Typography>


      <Stack
        direction='row'
        className={layoutClasses.formButtonContainer}
      >
        {/* SIGN IN BUTTON */}
        <LoadingButton
          variant='contained'
          className={layoutClasses.formButton}
          href='/sign-in'
        >
          Back to Sign In
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default ResetPasswordSuccess