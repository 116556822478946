import { useState, useEffect, useContext} from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'
import MainContentItem from './MainContentItem/MainContentItem'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// DATA
import { 
  getChartOptionsFM,
  getChartOptionsMS,
} from './homeData'

// MOMENT
import moment from 'moment'

// MUIS
import { useTheme } from '@mui/material/styles'

// SERVICES
import {
  getMoveStatisticSCountDuration,
  getFleetManagerCountDuration,
} from 'services/gateMove'

// STYLES
import useStyles from './homeUseStyles'

const Home = () => {
  const { auth, 
    setSnackbarObject,
  } = useContext(AllPagesContext)
  const classes = useStyles()

  const theme = useTheme()

  const initialListButtonMS = [
    {
      day : '7 DAY',
      isClicked : false
    },
    {
      day : '30 DAY',
      isClicked : true
    },
  ]

  const initialListButtonFM = [
    {
      day : '7 DAY',
      isClicked : false
    },
    {
      day : '30 DAY',
      isClicked : true
    },
  ]

  const chartColors = [
    {
      color : theme.palette.info.main
    },
    {
      color : theme.palette.secondary.main
    },
    {
      color : theme.palette.warning.main
    }
  ]

  const chartColorsFM = [
    theme.palette.info.main, 
    theme.palette.secondary.main, 
    theme.palette.warning.main, 
    theme.palette.success.main, 
    theme.palette.error.main
  ]
  
  // MS = MOVE STATISTIC
  const [ listButtonMS, setListButtonMS ] = useState(initialListButtonMS)
  const [ filteredDayMS, setFilteredDayMS ] = useState(30)
  const [ totalMovesMS, setTotalMovesMS ] = useState(0)
  const [ moveStatisticListChart, setMoveStatisticListChart ] = useState([])
  const [ xaxisChartMS, setXaxisChartMS ] = useState([])
  // FM = FLEET MANAGER
  const [ listButtonFM, setListButtonFM ] = useState(initialListButtonFM)
  const [ filteredDayFM, setFilteredDayFM ] = useState(30)
  const [ fleetMngrName, setFleetMngrName ] = useState([])
  const [ fleetManagerListChart, setFleetManagerListChart ] = useState([])
  const [ xaxisChartFM, setXaxisChartFM ] = useState([])
  
  // HANDLE LIST BUTTON CLICK MS
  const handleListButtonClickMS = (inputData) => {
    const newListButton = [...listButtonMS].map((item) => {
      if(item.day === inputData.day) item.isClicked = true
      else item.isClicked = false
      return item
    })
    setListButtonMS(newListButton)

    if(inputData.day === '7 DAY') setFilteredDayMS(7)
    else setFilteredDayMS(30)
  }

  // HANDLE LIST BUTTON CLICK FL
  const handleListButtonClickFM = (inputData) => {
    const newListButton = [...listButtonFM].map((item) => {
      if(item.day === inputData.day) item.isClicked = true
      else item.isClicked = false
      return item
    })
    setListButtonFM(newListButton)

    if(inputData.day === '7 DAY') setFilteredDayFM(7)
    else setFilteredDayFM(30)
  }

  // HANDLE FLEET MANAGER ITEM CLICK (FILTER)
  const handlefleetManagerItemClick = (inputItem) => {
    // VALIDATE IS A ACTIVED LIST
    const activedList = [...fleetManagerListChart].find((item) => item.isActive && item.name === inputItem.name)

    // CALCULATE TOTAL ACTIVED LIST
    let countActiveList = 0
    const calculateAactiveList = [...fleetManagerListChart].forEach((item) => {
      if(item.isActive === true) countActiveList++
    })

    // HANDLE FILTER
    if(activedList && countActiveList < 4) {
      const newListFleetManager = [...fleetManagerListChart].map((item) => {
        if(item.name === inputItem.name) item.isActive = !item.isActive
        return item
      })
      setFleetManagerListChart(newListFleetManager)
    } else if(countActiveList < 3) {
      const newListFleetManager = [...fleetManagerListChart].map((item) => {
        if(item.name === inputItem.name) item.isActive = !item.isActive
        return item
      })
      setFleetManagerListChart(newListFleetManager)
    } 
    // HANDLE WHEN ACTIVED LIST >= 3 && !activedList
    else setSnackbarObject({
      open: true,
      severity:'success',
      title:'',
      message:'Maximum choice of 3 data'
    })
  }

  // FETCH API MS
  const loadMovesStatistic = async (inputIsMounted, inputAbortController) => {
    const resultMoveStatistic = await getMoveStatisticSCountDuration(inputAbortController.signal, auth.accessToken, filteredDayMS)

    if(resultMoveStatistic.status === 200 && inputIsMounted) {
      // SET TOTAL MOVES
      setTotalMovesMS(resultMoveStatistic.data.total_moves)

      // CREATE MOVE STATISTIC LIST DATES
      let listDate = []
      const today = moment(new Date()).format('YYYY-MM-DD')

      for(let i = 0; i < filteredDayMS; i++ ) {
        const listItem = {
          tx_date: moment(today).subtract(i, 'day').format('YYYY-MM-DD'),
          total_gate_in: 0,
          total_gate_out: 0,
          total_gateMove: 0
        }
        listDate.push(listItem)
      }

      // REVERSE LIST DATE
      listDate.reverse()

      // PAIRING DATA FROM API
      const moveStatisticListDate = listDate.map((item, index) => {
        let moveStatisticItem = {...item}

        const filteredMoveStatisticList = resultMoveStatistic.data.list_moves.find((data) => data.tx_date === item.tx_date)

        if(filteredMoveStatisticList) {
          moveStatisticItem = {
            ...item,
            total_gate_in: filteredMoveStatisticList.total_gate_in,
            total_gate_out: filteredMoveStatisticList.total_gate_out,
            total_gateMove: filteredMoveStatisticList.total_gateMove,
          }
        }

        return moveStatisticItem
      })

      // CREATE MOVE STATISTIC LIST ITEM CHART 
      const moveStatisticListChart = [
        {
          name : 'GATE IN',
          data : moveStatisticListDate?.map((item) => item.total_gate_in)
        },
        {
          name : 'GATE OUT',
          data : moveStatisticListDate?.map((item) => item.total_gate_out)
        },
        {
          name : 'ALL MOVES',
          data : moveStatisticListDate?.map((item) => item.total_gateMove)
        },
      ]

      setMoveStatisticListChart(moveStatisticListChart)
    }
  }

  // CREATE XAXIS CHART MS
  const createXaxisChartMS = () => {
    const today = moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
    let xaxisCategories = []

    for (let i = 1; i <= filteredDayMS; i++) {
      xaxisCategories.push(moment(today).subtract(i, 'day').format('DD'))
    }
    setXaxisChartMS(xaxisCategories.reverse())
  }

  // FETCH API FM
  const loadFleetManager = async (inputIsMounted, inputAbortController) => {
    const resultFleetManager = await getFleetManagerCountDuration(inputAbortController.signal, auth.accessToken, filteredDayFM)
    
    // RESULT API
    const resultFM = resultFleetManager?.data?.list_moves

    // GET ALL FLEET MANAGER NAME AND REMOVE DUPLICATE FM
    const getFleetManagerName = () => {
      let tempArray = []
      resultFM.forEach((item, index) => {
        tempArray.push(item.fleet_manager)
        setFleetMngrName([...new Set(tempArray)])
      })
    }

    // CREATE & PAIRING FLEET MANAGER LIST DATES (30 / 7 DAYS)
    const pairingData = (inputData, inputItem) => {
      const filteredData = inputData?.filter(
        (item) => item.fleet_manager === inputItem
      )
      let listDate = []
      const today = moment(new Date()).format('YYYY-MM-DD')
  
      for (let i = 0; i < filteredDayFM; i++) {
        const listItem = {
          tx_date: moment(today).subtract(i, 'day').format('YYYY-MM-DD'),
          total_gate_in: 0,
          total_gate_out: 0,
          total_gateMove: 0,
          fleet_manager: ''
        }
        listDate.push(listItem)
      }

      // REVERSE LIST DATE
      listDate.reverse()
  
      const finalData = listDate.map((item, index) => {
        let finalItem = { ...item }
  
        const itemAPI = filteredData?.find(
          (outputItem) => outputItem.tx_date === item.tx_date
        )
  
        if (itemAPI)
          finalItem = {
            ...item,
            total_gate_in: itemAPI.total_gate_in,
            total_gate_out: itemAPI.total_gate_out,
            total_gateMove: itemAPI.total_gateMove,
            fleet_manager: itemAPI.fleet_manager
          }
  
        return finalItem
      })
      return finalData.map((item) => item.total_gateMove)
    }

    // CALCULATE TOTAL MOVES BY FLEET MANAGER
    const calculateTotalMove = (inputData, inputItem) => {
      const filteredData = inputData?.filter(
        (item) => item.fleet_manager === inputItem
      )
      let totalMove = 0
      filteredData.forEach((item) => (totalMove += item.total_gateMove))
      return totalMove
    }

    // FINAL DATA (CREATE LIST FLEET MANAGER)
    const generatelistChart = () => {
      const result = fleetMngrName.map((item,index) => {
        return {
          name: item,
          data: pairingData(resultFM, item),
          totalMove: calculateTotalMove(resultFM, item),
        }
      })

      // SORT FM LIST DESCENDING
      const sortedListChart = result.sort((a,b) => b.totalMove - a.totalMove)

      // ADD COLOR
      const addedColorListChart = sortedListChart.map((item, index) => {
        return {
          ...item,
          color : chartColorsFM[index],
          isActive : index === 0 || index === 1 || index === 2 ? true : false
        }
      })
      
      // SET FLEET MANAGER LIST CHART & SLICE INDEX 0 - 4
      setFleetManagerListChart(addedColorListChart.slice(0,5))
    }

    if(resultFleetManager.status === 200 && inputIsMounted) {
      getFleetManagerName()
      generatelistChart()
    }
  }

  // CREATE XAXIS CHART FM
  const createXaxisChartFM = () => {
    const today = moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
    let xaxisCategories = []

    for (let i = 1; i <= filteredDayFM; i++) {
      xaxisCategories.push(moment(today).subtract(i, 'day').format('DD'))
    }
    setXaxisChartFM(xaxisCategories.reverse())
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadMovesStatistic(isMounted, abortController)
    loadFleetManager(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  // SIDE EFFECT MOVE STATISTIC
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadMovesStatistic(isMounted, abortController)
    createXaxisChartMS()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filteredDayMS])

  // SIDE EFFECT MOVE FLEET MANAGER
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadFleetManager(isMounted, abortController)
    createXaxisChartFM()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filteredDayFM, fleetMngrName.length])
  
  return (
    <>
      {/* APPBAR */}
      <AppBar
        pageTitle='Home'
        hasFab={false}
        hasSearch={false}
      />

      {/* MAIN CONTENT */}
      <LoadingPaper 
        isLoading={false}
        className={classes.mainContent}
      >
        {/* MOVE STATISTICS */}
        <MainContentItem 
          title='Move Statistics'
          chartOptions={getChartOptionsMS(
            theme,
            chartColors.map(item => item.color),
            filteredDayMS,
            xaxisChartMS,
          )}
          chartSeriesList={moveStatisticListChart}
          type='moveStatistic'
          listButtonMS={listButtonMS}
          handleListButtonClick={handleListButtonClickMS}
          totalMoves={totalMovesMS}
        />

        {/* MOVES BY FLEET MANAGER */}
        <MainContentItem 
          title=''
          fleetManagerList={fleetManagerListChart}
          chartOptions={getChartOptionsFM(
            theme,
            fleetManagerListChart.map(item => item.isActive && item.color),
            filteredDayFM,
            xaxisChartFM,
          )}
          chartSeriesList={fleetManagerListChart.filter((item)=> item.isActive)}
          type='moveShipping'
          listButtonFM={listButtonFM}
          handleListButtonClickFM={handleListButtonClickFM}
          handlefleetManagerItemClick={handlefleetManagerItemClick}
        />
      </LoadingPaper>
    </>
  )
}

export default Home