//APIS
import { axiosPrivate } from 'apis/axios'

export const getUserProfile = async (inputSignal, inputToken) => {
  try {
    const response = await axiosPrivate.get(
      '/api/profile',
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const editUserProfile = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.put(
      '/api/update',
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const changeUserPassword = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.put(
      '/api/change-password',
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getDepoBankAccount = async (inputSignal, inputToken) => {
  try {
    const response = await axiosPrivate.get(
      'api/depo/bank',
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const createDepoBankAccount = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.post(
      'api/depo/bank/change',
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}