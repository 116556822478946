// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: 12,
    cursor: 'pointer'
  },
  headerRoot: {
    marginBottom: 12,
  },
  headerIcon: {
    marginRight: 8,
  },
  listItem : {
    borderRadius : 4,
    padding : '6px 0px',
    height : 30,
    marginLeft : 15,
    width : 'max-content',
  },
  checkIcon : {
    minWidth : 34,
    color : theme.palette.primary.main
  },
  listItemButton : {
    '&:hover' : {
      backgroundColor : 'transparent !important'
    }
  },
  paperTitle: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
}))

export default useStyles