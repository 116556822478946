import { useState, useContext, useEffect } from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICON
import IconDownload from '@mui/icons-material/Download'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { postInventoryListSearch } from 'services/inventory'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'

// UTILITIES
import { getThisWeekDateAndTime } from 'utilities/date'
import { capitalizeEachWord } from 'utilities/string'

const Inventory = () => {
  const { auth } = useContext(AllPagesContext)
  const layoutClasses = useLayoutStyles()

  // DATA GRID INTIAL COLUMNS
  const initialColumns = [
    {
      field: 'depot',
      headerName: 'Depot',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'fleet_manager',
      headerName: 'Fleet Manager',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'container_number',
      headerName: 'Container no',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'iso_code',
      headerName: 'ISO code',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'clean',
      headerName: 'Clean',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (typeof params.id === 'number') return (
          <Typography
            variant='inherit'
            color={params?.row?.clean === true ? 'success.main' : 'error.main'}
          >
            {params?.row?.clean === true ? 'Yes' : 'No'}
          </Typography>
        )
      }
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'date_manufacturer',
      headerName: 'Date Manufacturer',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack 
          direction='row'
          className={layoutClasses.columnActions}
        >
          {/* DOWNLOAD ICON */}
          <CustomTooltipBlack 
            title='Download' 
            placement='bottom'
          >
            <IconButton>
              <IconDownload/>
            </IconButton>
          </CustomTooltipBlack>
        </Stack>
      ),
    },
  ]

  // DATA GRID GROUP BY LIST
  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Fleet Manager',
      value: 'fleet_manager',
    },
  ]

  // DATA GRID GROUPING COLDEF
  const groupingColDef = {
    headerName: 'Fleet manager',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) return params.row.fleet_manager

      return (
        <Typography 
          variant='subtitle1' 
          className={layoutClasses.groupingRow}
          noWrap
        >
          {capitalizeEachWord(params.rowNode.groupingKey.replaceAll('_', ' '))}
        </Typography>
      )
    },
  }

  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState(null)
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen ] = useState(false)
  const [ dateRangeTimeValue, setDateRangeTimeValue ] = useState(getThisWeekDateAndTime())
  const [ tableData, setTableData ] = useState([])
  const [ mustReloadDataGrid, setMustReloadDataGrid ] = useState(true)
  const [ search, setSearch ] = useState('')

  // DATA GRID HANDLE COLUMNS MENU ITEM CLICK
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if(selectedGroupBy.value && inputItem.field === 'fleet_manager') {}
    else { tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide }
    setSelectedColumnList(tempSelectedColumnList)
  }

  // HANDLE SELECT DATE RANGE PICKER
  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)
  }

  // FETCH INVENTORY LIST DATA
  const loadInventoryListData = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const newFilters = reject(isEmpty, filters)

    const resultPostInventoryList = await postInventoryListSearch(
      inputAbortController.signal, 
      {
        page: pageNumber,
        size: pageSize,
        sort: order && orderBy ? `${orderBy},${order}` : 'id,desc',
      },
      {
        globalSearch: search,
        ...newFilters,
      },
      auth.accessToken,
    )

    if (resultPostInventoryList.status === 200 && inputIsMounted) {
      setTableData(resultPostInventoryList.data.rows.map((item, index)=>{
        return {
          ...item,
          id: index,
        }
      }))
      setTotal(resultPostInventoryList.data.totalElements)
    }

    setIsDataGridLoading(false)
    setMustReloadDataGrid(false)
  }
  
  // HANDLE FETCH TABLE DATA
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    mustReloadDataGrid && loadInventoryListData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [mustReloadDataGrid])

  // HANDLE RELOAD TABLE DATA
  useEffect(() => {
    setMustReloadDataGrid(true)
  }, [filters, search, pageSize, pageNumber, order, orderBy])

  // HANDLE DATA GRID COLUMNS WHILE GROUPING 
  useEffect(() => {
    if(selectedGroupBy.value) {
  
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if(item.field === selectedGroupBy.value) item.hide = true
        else item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
  
    } else {
  
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  
  }, [selectedGroupBy])

  return (
    <>
      {/* APP BAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Inventory'
        hasFab={false}
      />

      {/* CONTENT */}
      <LoadingPaper isLoading={isDataGridLoading}>
        <DataGridFilters
          // COLUMN
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          // FILTER
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          // GROUP BY ROW
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
          // TOTAL
          totalRows={total}
          // DATE RANGE TIME
          dateRangeValue={dateRangeTimeValue}
          isDateRangeTimePickerOpen={isDateRangeTimePickerOpen} 
          isWithDateTimePicker={false}
          setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
          handleSelectDateRangePickerButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelDateRangePickerButtonClick={() => setIsDateRangeTimePickerOpen(false)}
          // DOWNLOAD
          isDownloadEnabled={selectionModel.length > 0}
          handleDownloadButtonClick={() => console.log('download')}
        />

        <DataGridTable
          // BASE
          initialColumns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          rows={tableData}
          // PAGINATION
          total={total}
          page={pageNumber}
          pageSize={pageSize}
          // ORDER
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPageNumber}
          setPageSize={setPageSize}
          // FILTER
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          // GROUP BY ROW
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [ row.fleet_manager, row.id ]}
          groupingColDef={groupingColDef}
          // SELECTION
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
        />

      </LoadingPaper>
    </>
  )
}

export default Inventory