import { useState, useEffect, useContext } from 'react'
import moment from 'moment'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import CellEditDateAndTimePicker from 'components/DataGridEdit/CellEditDateAndTimePicker'
import CellEditSelect from 'components/DataGridEdit/CellEditSelect'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogImportCSV from './DialogImportCSV/DialogImportCSV'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CONSTANTS
import { movesConstants } from './movesConstants'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// DATA GRID PRO
import {
  GridRowModes,
} from '@mui/x-data-grid-pro'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCircle from '@mui/icons-material/Circle'
import IconEdit from '@mui/icons-material/Edit'
import IconLocationOn from '@mui/icons-material/LocationOn'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { 
  postGateMoveListSearch,
  putUpdateGateMoveItem,
  postCreateNewGateMove,
} from 'services/gateMove'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'

// UTILITIES
import { signOutUser } from 'utilities/authentication'
import { 
  convertDate,
  getLastTwoWeekDate, 
} from 'utilities/date'
import { 
  addSeparatorsForNumber,
  capitalizeEachWord, 
  getDefaultErrorMessage, 
} from 'utilities/string'

const Moves = () => {
  const layoutClasses = useLayoutStyles()

  const { 
    auth, setAuth, 
    setSnackbarObject,
  } = useContext(AllPagesContext)

  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)
  const [ rowModesModel, setRowModesModel ] = useState({})
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState(null)
  const [ orderBy, setOrderBy ] = useState(null)
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen ] = useState(false)
  const [ dateRangeTimeValue, setDateRangeTimeValue ] = useState(getLastTwoWeekDate())
  const [ tableData, setTableData ] = useState([])
  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)
  const [ mustReloadDataGrid, setMustReloadDataGrid ] = useState(true)
  const [ search, setSearch ] = useState('')
  const [ isCreateNewRow, setIsCreateNewRow ] = useState(false)
  const [ isEditingRow, setIsEditingRow ] = useState(false)
  const [ isImportingCsv, setIsImportingCsv ] = useState(false)
  const [ isSpeedDialOpen, setIsSpeedDialOpen ] = useState(false)

  // INTITAL NEW ROW
  const initialNewRow = {
    id : tableData.length + 1,
    status : 'waiting',
    process_type : 'gate_in',
    tx_date : new Date(),
    depot: '',
    fleet_manager : '',
    container_number : 0,
    iso_code : '',
    amount : 'Rp. 0',
    condition : 'available',
    damage_by : '',
    clean : 'Yes',
    grade : '',
    date_manufacturer : new Date(),
    order_number : '0',
    customer : '',
    vessel : '',
    voyage : '',
    discharge_port : '',
    delivery_port : '',
    carrier : '',
    transport_number : '',
    driver_name : '',
    tare : '',
    payload : '',
    max_gross : '',
    remarks : '',
    source : 'API',
    longitude : '',
    latitude : '',
    location : '',
    gateMoveType : '',
    isNew: true,
  }

  // HANDLE ADD NEW MOVE (ADD AN EMPTY ROW)
  const handleAddNewMove = () => {
    setTableData((oldTableData) => [initialNewRow, ...oldTableData,])
    setRowModesModel((oldModels) => ({
      ...oldModels,
      [tableData?.length + 1] : {mode : GridRowModes.Edit, fieldToFocus : 'process_type' }
    }) )
    setIsCreateNewRow(true)
  }

  // HANDLE EDIT ROW
  const handleIconEditClick = (id) => {
    setRowModesModel((oldModels) => ({
      ...oldModels,
      [id] : {mode : GridRowModes.Edit, fieldToFocus : 'process_type' }
    }) )

    setTableData(current => current.map((item) => {
      if(item.id === id) item.isEditingRow = true
      else item.isEditingRow = false
      return item
    }))
  }

  // HANDLE DISCARD EDITING ROW
  const handleIconDiscard = (id) => {
    setRowModesModel((oldModels) => ({
      ...oldModels,
      [id] : {mode : GridRowModes.View, ignoreModifications: true }
    }) )

    setTableData(current => current.map((item) => {
      if(item.id === id) item.isEditingRow = false
      else item.isEditingRow = false
      return item
    }))
  }

  // HANDLE SAVE BUTTON CLICK
  const handleSaveClick = (id) => {
    setRowModesModel((oldModels) => ({
      ...oldModels,
      [id] : {mode : GridRowModes.View}
    })) 
  }
  
  // HANDLE DISCARD NEW ROW
  const handleDiscardNewRow = (id)  => {
    setTableData((oldTableData) => [...oldTableData.filter((item) => item.id !== id)])
    setIsCreateNewRow(false)
  }

  // INITIAL COLUMNS
  const initialColumns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        // TO DO: FIX THIS FIELD
        let color
        if (params.value?.toLowerCase() === 'submit') color = 'success'
        else if (params.value?.toLowerCase() === 'waiting') color = 'warning'

        if (typeof params.id === 'number') return (
          <Stack 
            direction='row'
            alignItems='center'
          >
            {/* ICON */}
            <IconCircle
              className={layoutClasses.columnIconStatus}
              color={color}
            />

            {/* TEXT */}
            <Typography
              variant='inherit'
              color={`${color}.main`}
            >
              {capitalizeEachWord(params.value)}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'process_type',
      headerName: 'Process Type',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      renderCell: (params) => {
        let color
        if (params.value?.toLowerCase()?.includes('gate_in')) color = 'info.main'
        else if (params.value?.toLowerCase()?.includes('gate_out')) color = 'primary.main'

        if (typeof params.id === 'number') return (
          <Typography
            variant='inherit'
            color={color}
          >
            {capitalizeEachWord(params.value?.replaceAll('_', ' '))}
          </Typography>
        )
      },
      renderEditCell: (params) => (
        <CellEditSelect 
          {...params}
          inputList={movesConstants.processTypeOptionList}
        />
      ),
    },
    {
      field: 'tx_date',
      headerName: 'Date & Time',
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: false,
      editable: true,
      valueGetter: (params) => convertDate(params.value, 'dd MMM yyyy HH:mm aa'),
      renderEditCell: (params) => (
        <CellEditDateAndTimePicker 
          {...params}
          type='dateAndTimePicker'
        />
      ),
    },
    {
      field: 'id',
      headerName: 'NLE ID',
      flex: 1,
      minWidth: 80,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'depot',
      headerName: 'Depot',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'fleet_manager',
      headerName: 'Fleet Manager',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'container_number',
      headerName: 'Container No',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'iso_code',
      headerName: 'ISO Code',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      renderCell: (params) => {
        if (typeof params.id === 'number') return <Stack direction='row' justifyContent='space-between' width='100%' paddingRight='30px'>
          <Typography variant='inherit'>
            Rp.
          </Typography>
          <Typography variant='inherit'>
            {addSeparatorsForNumber(params.value)}
          </Typography>
        </Stack>
      }
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => capitalizeEachWord(params.value?.replaceAll('_', ' ')),
      renderEditCell: (params) => (
        <CellEditSelect 
          {...params}
          inputList={movesConstants.conditionOptionList}
        />
      ),
    },
    {
      field: 'damage_by',
      headerName: 'Damaged By',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => capitalizeEachWord(params.value?.replaceAll('_', ' ')),
      renderEditCell: (params) => (
        <CellEditSelect 
          {...params}
          inputList={movesConstants.damagedByOptionList}
        />
      ),
    },
    {
      field: 'clean',
      headerName: 'Clean',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      editable: true,
      renderCell: (params) => (
        typeof params.id === 'number' &&
        <Typography
          variant='inherit'
          color={params.value === 'yes' ? 'success.main' : 'error.main'}
        >
          {params.value === 'yes' ? 'Yes' : 'No'}
        </Typography>
      ),
      renderEditCell: (params) => (
        <CellEditSelect 
          {...params}
          inputList={movesConstants.cleanOptionList}
        />
      ),
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'date_manufacturer',
      headerName: 'Date Manufactured',
      flex: 1,
      minWidth: 140,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => convertDate(params.value, 'MMM yyyy'),
      renderEditCell: (params) => (
        <CellEditDateAndTimePicker 
          {...params}
          type='datePicker'
          views={[ 'year', 'month' ]}
        />
      ),
    },
    {
      field: 'order_number',
      headerName: 'Order Number',
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'vessel',
      headerName: 'Vessel',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'voyage',
      headerName: 'Voyage',
      flex: 1,
      minWidth: 90,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'discharge_port',
      headerName: 'Discharge Port',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'delivery_port',
      headerName: 'Delivery Port',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      editable: true,
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'transport_number',
      headerName: 'Transport ID',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
    },
    {
      field: 'driver_name',
      headerName: 'Driver Name',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      editable: true,
    },
    {
      field: 'tare',
      headerName: 'Tare (KGS)',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => addSeparatorsForNumber(params.value),
    },
    {
      field: 'payload',
      headerName: 'Payload',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => addSeparatorsForNumber(params.value),
    },
    {
      field: 'max_gross',
      headerName: 'Max Gross (KGS)',
      flex: 1,
      minWidth: 140,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => addSeparatorsForNumber(params.value),
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      editable: true,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      editable: true,
      valueGetter: (params) => capitalizeEachWord(params.value?.replaceAll('_', ' ')),
      renderEditCell: (params) => (
        <CellEditSelect 
          {...params}
          inputList={movesConstants.sourceOptionList}
        />
      ),
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        typeof params.id === 'number' &&
        <IconButton
          size='small'
          // TO DO: FIX THIS ONCLICK PROP
          onClick={() => console.log('clicked')}
        >
          <IconLocationOn 
            color='secondary'
            size='small'
          />
        </IconButton>
      ),
    },
    {
      field: 'gateMoveType',
      headerName: 'Move Type',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
      editable: false,
      renderCell: (params) => {
        let color
        if (params.value?.toLowerCase() === 'gate_in') color = 'info.main'
        else if (params.value?.toLowerCase() === 'gate_out') color = 'primary.main'

        if (typeof params.id === 'number') return (
          <Typography
            variant='inherit'
            color={color}
          >
            {capitalizeEachWord(params.value?.replaceAll('_', ' '))}
          </Typography>
        )
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if(!params.row.isNew && params.row.isEditingRow) {
          return <Stack 
            direction='row'
            className={layoutClasses.columnActions}
          >
            {/* SAVE ICON */}
            <CustomTooltipBlack
              onClick={() => handleSaveClick(params.id)}
              title='Save' 
              placement='bottom'
            >
              <IconButton>
                <SaveIcon/>
              </IconButton>
            </CustomTooltipBlack>

            {/* DISCARD ICON */}
            <CustomTooltipBlack 
              onClick={() => handleIconDiscard(params.id)}
              title='Discard' 
              placement='bottom'
            >
              <IconButton>
                <ClearIcon/>
              </IconButton>
            </CustomTooltipBlack>

          </Stack>
        }

        else if(!params.row.isNew && !params.row.isEditingRow) {
          return <Stack 
            direction='row'
            className={layoutClasses.columnActions}
          >
            {/* EDIT ICON */}
            <CustomTooltipBlack 
              onClick={() => handleIconEditClick(params.id)}
              title='Edit' 
              placement='bottom'
            >
              <IconButton>
                <IconEdit/>
              </IconButton>
            </CustomTooltipBlack>
          </Stack>
        }
        
        else {
          return <Stack 
            direction='row'
            className={layoutClasses.columnActions}
          >
            {/* DELETE ICON */}
            <CustomTooltipBlack
              onClick={() => handleSaveClick(params.id)}
              title='Save' 
              placement='bottom'
            >
              <IconButton>
                <SaveIcon/>
              </IconButton>
            </CustomTooltipBlack>

            <CustomTooltipBlack
              onClick={() => handleDiscardNewRow(params.id)}
              title='Discard' 
              placement='bottom'
            >
              <IconButton>
                <ClearIcon/>
              </IconButton>
            </CustomTooltipBlack>
          </Stack>
        }
        
      },
    },
  ]

  // GROUP BY LIST
  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Type',
      value: 'process_type',
    },
    {
      title: 'By Fleet Manager',
      value: 'fleet_manager',
    },
  ]

  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  
  // GET COLDEF HEADER NAME
  const getColDefHeadername = () => {
    if(selectedGroupBy.value === 'process_type') return 'Process Type'
    else if(selectedGroupBy.value === 'fleet_manager') return 'Fleet Manager'
  }          
  
  // GROUPING COLDEF
  const groupingColDef = {
    headerName: getColDefHeadername(),
    renderCell: (params) => {
      if(selectedGroupBy.value === 'process_type') {
        let tempColor 
        if (params.row?.process_type?.toLowerCase()?.includes('gate_in')) tempColor = 'info.main'
        else if (params.row?.process_type?.toLowerCase()?.includes('gate_out')) tempColor = 'primary.main'
      
        if (!params.rowNode.isAutoGenerated) return (
          <Typography
            variant='inherit'
            color={tempColor}
          >
            {capitalizeEachWord(params?.row?.process_type?.replaceAll('_', ' '))}
          </Typography>
        )

        return (
          <Typography 
            variant='subtitle1' 
            className={layoutClasses.groupingRow}
            noWrap
          >
            {capitalizeEachWord(params.rowNode.groupingKey.replaceAll('_', ' '))}
          </Typography>
        )
      }

      else if(selectedGroupBy.value === 'fleet_manager') {
        if (!params.rowNode.isAutoGenerated) return params?.row?.fleet_manager

        return (
          <Typography 
            variant='subtitle1' 
            className={layoutClasses.groupingRow}
            noWrap
          >
            {capitalizeEachWord(params.rowNode.groupingKey.replaceAll('_', ' '))}
          </Typography>
        )
      }
      
    },
  }
  
  // GET TREE DATAPATH CHILDREN
  const getTreeDataPathChildren = (inputRow) => {
    if(selectedGroupBy.value === 'process_type' && inputRow.process_type !== null) return inputRow.process_type
    else if(selectedGroupBy.value === 'fleet_manager' && inputRow.fleet_manager !== null) return inputRow.fleet_manager
    else return inputRow.process_type
  }

  // HANDLE COLUMNS MENU ITEM CLICK
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if(
      // TO DO: CHANGE THESE FIELDS LATER
      (selectedGroupBy.value && inputItem.field === 'process_type') ||
      (selectedGroupBy.value && inputItem.field === 'fleet_manager')
    ) {}
    else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }
  
  // HANDLE SELECT DATE RANGE PICKER
  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)
  }

  // FETCH GATE MOVE LIST DATA
  const loadGateMoveListData = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const { 
      tx_date, id, clean, vessel, voyage, 
      discarge_port, delivery_port, driver_name, 
      remarks, location, report, ...restOfFilters
    } = filters

    const newFilters = reject(isEmpty, restOfFilters)

    const resultGateMoveList = await postGateMoveListSearch(
      inputAbortController.signal, 
      {
        page: pageNumber,
        size: pageSize,
        sort: order && orderBy ? `${orderBy},${order}` : 'id,desc',
      },
      {
        globalSearch: search,
        from: dateRangeTimeValue[0] !== null ? moment(dateRangeTimeValue[0]).format('YYYY-MM-DD') : '',
        to: dateRangeTimeValue[1] !== null ? moment(dateRangeTimeValue[1]).format('YYYY-MM-DD') : '',
        ...newFilters,
      },
      auth.accessToken,
    )
    
    if (resultGateMoveList.status === 200 && inputIsMounted) {
      setTotal(resultGateMoveList.data.totalElements)
      setTableData(resultGateMoveList.data.rows)
    }
    else if (resultGateMoveList.status === 403) signOutUser(setAuth)

    setIsDataGridLoading(false)
    setMustReloadDataGrid(false)
  }

  // FUNCTION CREATE NEW ROW
  const createNewRow = async (inputResolve, inputReject, inputNewRow, inputOldRow) => {
    setIsDataGridLoading(true)
    const abortController = new AbortController()
    
    const bodyParams = {
      tx_date :  new Date(inputNewRow.tx_date),
      process_type: inputNewRow.process_type,
      fleet_manager: inputNewRow.fleet_manager,
      container_number: inputNewRow.container_number,
      iso_code: inputNewRow.iso_code,
      condition: inputNewRow.condition,
      clean: inputNewRow.clean === true ? 'TRUE' : 'FALSE',
      grade: inputNewRow.grade,
      order_number: inputNewRow.order_number,
      customer: inputNewRow.customer,
      carrier: inputNewRow.carrier,
      transport_number: inputNewRow.transport_number,
      tare: +inputNewRow?.tare?.replaceAll('.', ''),
      payload: +inputNewRow?.payload?.replaceAll('.', ''),
      date_manufacturer: convertDate(inputNewRow.date_manufacturer, 'yyyy-MM'),
      max_gross: +inputNewRow?.max_gross?.replaceAll('.', ''),
      longitude: inputNewRow.longitude,
      latitude: inputNewRow.latitude,
      damage_by: inputNewRow.damage_by,
      amount: inputNewRow.amount?.replace('Rp. ', '')?.replaceAll('.', '')?.replaceAll(',', ''),
      depot: inputNewRow.depot,
      vessel: inputNewRow.vessel,
      voyage: inputNewRow.voyage,
      discharge_port: inputNewRow.discharge_port,
      remarks: inputNewRow.remarks,
      delivery_port: inputNewRow.delivery_port,
      driver_name: inputNewRow.driver_name,
    }

    const resultCreateNewRow = await postCreateNewGateMove(
      abortController.signal,
      bodyParams,
      auth.accessToken,
    )

    // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY UPDATING THE MOVE ITEM
    if (resultCreateNewRow.status === 201) {
      setIsDialogAddOrEditOpen(false)

      setMustReloadDataGrid(true)

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully create the gate move item',
      })

      // ACCEPT THE ROW CHANGES
      inputResolve(inputNewRow)

      // SET COLUMN NEW ROW MODE TO EDIT MODE TYPE
      setIsCreateNewRow(false)
    }

    // SHOW ERROR MESSAGE AFTER FAILED TO UPDATE THE MOVE ITEM
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: 'Failed to create the gave move item',
        message: getDefaultErrorMessage(resultCreateNewRow),
      })

      // REJECT THE ROW CHANGES
      inputReject(inputOldRow)
    }

    setIsDataGridLoading(false)
    abortController.abort()
  }
  
  // FUNCTION UPDATE ROW
  const udpateMoveItem = async (inputResolve, inputReject, inputNewRow, inputOldRow) => {
    setIsDataGridLoading(true)

    // HANDLE ERROR BODY PARAMS REQUEST
    if(inputNewRow) {
      inputNewRow.tx_date = new Date(inputNewRow?.tx_date)
      inputNewRow.txDateFormatted = inputNewRow?.tx_date
      inputNewRow.source = inputNewRow?.source?.toUpperCase()
      inputNewRow.max_gross = inputNewRow?.max_gross?.replaceAll('.', '')
      inputNewRow.tare = inputNewRow?.tare?.replaceAll('.', '')
      inputNewRow.payload = inputNewRow?.payload?.replaceAll('.', '')
    }

    const abortController = new AbortController()
    
    const resultUpdateRowItem = await putUpdateGateMoveItem(
      abortController.signal, 
      inputNewRow,
      auth.accessToken,
    )

    // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY UPDATING THE MOVE ITEM
    if (resultUpdateRowItem.status === 200) {
      setIsDialogAddOrEditOpen(false)

      setMustReloadDataGrid(true)

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully updating the gate move item',
      })

      // ACCEPT THE ROW CHANGES
      inputResolve(inputNewRow)
    }
    // SHOW ERROR MESSAGE AFTER FAILED TO UPDATE THE MOVE ITEM
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: 'Failed to update the gave move item',
        message: getDefaultErrorMessage(resultUpdateRowItem),
      })

      // REJECT THE ROW CHANGES
      inputReject(inputOldRow)
    }

    setIsDataGridLoading(false)
    abortController.abort()
  }

  // PROCESS ROW UPDATE
  const processRowUpdate = (newRow, oldRow) => new Promise((resolve, reject) => {
    // CREATE NEW ROW
    if(newRow.isNew){
      createNewRow(resolve, reject, newRow, oldRow)
    }
    // UPDATE ROW
    else {
      udpateMoveItem(resolve, reject, newRow, oldRow)
    }
  })
 
  // HANDLE FETCH TABLE DATA
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    mustReloadDataGrid && loadGateMoveListData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [mustReloadDataGrid])

  // HANDLE RELOAD TABLE DATA
  useEffect(() => {
    let isMounted = true

    isMounted && setMustReloadDataGrid(true)

    return () => {
      isMounted = false
    }
  }, [filters, search, pageSize, pageNumber, order, orderBy, dateRangeTimeValue])

  // HANDLE DATA GRID COLUMNS WHILE GROUPING 
  useEffect(() => {
    if(selectedGroupBy.value) {

      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if(item.field === selectedGroupBy.value) item.hide = true
        else item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)

    } else {

      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }

  }, [selectedGroupBy])
  
  // HANDLE IS COLUMNS EDITABLE WHILE CREATE OR EDIT ROW
  useEffect(() => {
    if (isCreateNewRow) {
      setSelectedColumnList((current) => {
        return current.map((item, index) => {
          if (item.field === 'discharge_port' || 
          item.field === 'voyage' || 
          item.field === 'vessel' || 
          item.field === 'longitude' ||
          item.field === 'latitude' ||
          item.field === 'order_number') item.editable = true
          
          else if (item.field === 'gateMoveType' || item.field === 'source') item.editable = false
          else if (item.field === 'actions') item.editable = false
          return { ...item }
        })
      })
    } else {
      setSelectedColumnList((current) => {
        return current.map((item, index) => {
          if (item.field === 'discharge_port' || 
          item.field === 'voyage' || 
          item.field === 'vessel' || 
          item.field === 'longitude' ||
          item.field === 'latitude' ||
          item.field === 'order_number') item.editable = false

          else if (item.field === 'gateMoveType' || item.field === 'source') item.editable = true
          else if (item.field === 'actions') item.editable = false
          return { ...item }
        })
      })
    }
  }, [isCreateNewRow])

  // HANDLE ROW EDIT START
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  // HANDLE ROW EDIT STOP
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  return (
    <>
      {/* APPBAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Moves'
        hasFab={false}
        hasSpeedDial={true}
        fabOnClickButton={handleAddNewMove}
        setIsImportingCsv={setIsImportingCsv}
        isSpeedDialOpen={isSpeedDialOpen}
        setIsSpeedDialOpen={setIsSpeedDialOpen}
      />
      
      {/* CONTENT */}
      <LoadingPaper isLoading={isDataGridLoading}>
        <DataGridFilters
          // COLUMN
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          // FILTER
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          // GROUP BY ROW
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
          // DATE RANGE TIME
          isWithDateTimePicker={true}
          isWithTimePicker={false}
          dateRangeValue={dateRangeTimeValue}
          isDateRangeTimePickerOpen={isDateRangeTimePickerOpen} 
          setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
          handleSelectDateRangePickerButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelDateRangePickerButtonClick={() => setIsDateRangeTimePickerOpen(false)}
          // DOWNLOAD BUTTON CLICK
          handleDownloadButtonClick={() => {}}
        />

        <DataGridTable
          // BASE
          initialColumns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          rows={tableData}
          // PAGINATION
          total={total}
          page={pageNumber}
          setPage={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          // ORDER
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          // FILTER
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          // GROUP BY ROW
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [ getTreeDataPathChildren(row) , row.id ]}
          groupingColDef={groupingColDef}
          // SELECTION
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
          // EDIT ORR ADD ROW
          experimentalFeatures={{ newEditingApi: true }}
          editMode='row'
          isCellEditable={(params) => params.row.status.toLowerCase() === 'waiting'}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.log('onProcessRowUpdateError', error)}
          rowModesModel={rowModesModel}
          onRowDoubleClick={(params, event, details ) => handleIconEditClick(params.id)}

          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
        />

        {/* DIALOG IMPORT CSV FILE */}
        <DialogImportCSV 
          isImportingCsv={isImportingCsv} 
          setIsImportingCsv={setIsImportingCsv}
          setIsSpeedDialOpen={setIsSpeedDialOpen}
          setMustReloadDataGrid={setMustReloadDataGrid}
          setIsDataGridLoading={setIsDataGridLoading}
        />
      </LoadingPaper>
    </>
  )
}

export default Moves