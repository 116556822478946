// MUIS
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

const Form2 = (props) => {
  const { form, handleFormChange } = props

  const layoutClasses = useLayoutStyles()

  return (
    <>
      {/* TITLE */}
      <Typography 
        variant='h6'
        className={layoutClasses.pageTitle}
      >
        PIC Organization
      </Typography>

      {/* FULL NAME FORM */}
      <FormControl 
        variant='standard'
        className={layoutClasses.formInput}
        color='secondary'
      >
        {/* FULL NAME LABEL */}
        <InputLabel>
          Full Name
        </InputLabel>

        {/* FULL NAME INPUT */}
        <Input 
          type='text'
          name='fullName'
          value={form.fullName}
          onChange={handleFormChange}
        />
      </FormControl>

      {/* ORGANIZATION NAME FORM */}
      <FormControl 
        variant='standard'
        className={layoutClasses.formInput}
        color='secondary'
      >
        {/* ORGANIZATION NAME LABEL */}
        <InputLabel>
          Organization Name
        </InputLabel>

        {/* ORGANIZATION NAME INPUT */}
        <Input
          type='text'
          name='organizationName'
          value={form.organizationName}
          onChange={handleFormChange}
        />
      </FormControl>
    </>
  )
}

export default Form2