import { Stack, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'

// COMPONENTS
import DataGridTable from 'components/DataGridTable/DataGridTable'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// UTILITIES
import { 
  convertDate,
} from 'utilities/date'
import { 
  addSeparatorsForNumber,
  capitalizeEachWord,
  getDefaultErrorMessage,
} from 'utilities/string'

// SERVICE 
import { postCreateMultipleGateMove } from 'services/gateMove'

// STYLES
import useStyles from './dialogImportCSVUSeStyles'
import { useContext } from 'react'

const ReviewCsvFileComponent = (props) => {

  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const { isDataHaveEmptyColumn,
    setIsImportingCsv,
    setListData,
    setIsDataImported,
    setIsDataHaveEmptyColumn,
    setIsSpeedDialOpen,
    setMustReloadDataGrid,
    setIsDataGridLoading,
  } = props

  const classes = useStyles()

  const { listData } = props
  
  const initialColumns = [
    {
      field: 'process_type',
      headerName: 'Process Type',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      renderCell: (params) => {
        let color
        if (params.value?.toLowerCase()?.includes('gate_in')) color = 'info.main'
        else if (params.value?.toLowerCase()?.includes('gate_out')) color = 'primary.main'

        if (typeof params.id === 'number') return (
          <Typography
            variant='inherit'
            color={color}
          >
            {capitalizeEachWord(params.value?.replaceAll('_', ' '))}
          </Typography>
        )
      },
    },
    {
      field: 'tx_date',
      headerName: 'Date & Time',
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => convertDate(params.value, 'dd MMM yyyy HH:mm aa'),
    },
    {
      field: 'depot',
      headerName: 'Depot',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'fleet_manager',
      headerName: 'Fleet Manager',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'container_number',
      headerName: 'Container No',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'iso_code',
      headerName: 'ISO Code',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      renderCell: (params) => (
        <Stack direction='row' justifyContent='space-between' width='100%' paddingRight='30px'>
          <Typography variant='inherit'>
            Rp.
          </Typography>
          <Typography variant='inherit'>
            {addSeparatorsForNumber(params.value.replaceAll('.', ''))}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => capitalizeEachWord(params.value?.replaceAll('_', ' ')),
    },
    {
      field: 'damage_by',
      headerName: 'Damaged By',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => capitalizeEachWord(params.value?.replaceAll('_', ' ')),
    },
    {
      field: 'clean',
      headerName: 'Clean',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      renderCell: (params) => (
        typeof params.id === 'number' &&
        <Typography
          variant='inherit'
          color={params.value === 'yes' ? 'success.main' : 'error.main'}
        >
          {params.value === 'yes' ? 'Yes' : 'No'}
        </Typography>
      ),
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'date_manufacturer',
      headerName: 'Date Manufactured',
      flex: 1,
      minWidth: 140,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => convertDate(params.value, 'MMM yyyy'),
    },
    {
      field: 'order_number',
      headerName: 'Order Number',
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'vessel',
      headerName: 'Vessel',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'voyage',
      headerName: 'Voyage',
      flex: 1,
      minWidth: 90,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'discharge_port',
      headerName: 'Discharge Port',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'delivery_port',
      headerName: 'Delivery Port',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'transport_number',
      headerName: 'Transport ID',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'driver_name',
      headerName: 'Driver Name',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'tare',
      headerName: 'Tare (KGS)',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => addSeparatorsForNumber(params.value),
    },
    {
      field: 'payload',
      headerName: 'Payload',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => addSeparatorsForNumber(params.value),
    },
    {
      field: 'max_gross',
      headerName: 'Max Gross (KGS)',
      flex: 1,
      minWidth: 140,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
      valueGetter: (params) => addSeparatorsForNumber(params.value),
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: false,
      cellClassName: (params) => {
        if (params.value === '') {
          return 'empty-value'
        }
      },
    },
  ]

  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ order, setOrder ] = useState(null)
  const [ orderBy, setOrderBy ] = useState(null)
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ tableData, setTableData ] = useState([])

  const handleCreateGateMove = async () => {
    setIsDataGridLoading(true)
    const abortController = new AbortController()
    const newListData = [...listData].map((item) => {
      return {
        ...item,
        amount: +item.amount.replaceAll('.','').replaceAll(',',''),
        tare: +item.tare.replaceAll('.','').replaceAll(',',''),
        payload: +item.payload.replaceAll('.','').replaceAll(',',''),
        max_gross: +item.max_gross.replaceAll('.','').replaceAll(',',''),
      }
    })

    const resultCreateMultipleGateMove = await postCreateMultipleGateMove(abortController.signal, newListData, auth.accessToken)

    if(resultCreateMultipleGateMove.status === 200) {
      setMustReloadDataGrid(true)
      setListData([])
      setIsImportingCsv(false)
      setIsDataImported(false)
      setIsDataHaveEmptyColumn(false)
      setTimeout(() => {
        setIsSpeedDialOpen(false)
      }, 500)

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully create the gate move item',
      })
    }

    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: 'Failed to create the gave move item',
        message: getDefaultErrorMessage(resultCreateMultipleGateMove),
      })
    }

    setIsDataGridLoading(false)
    abortController.abort()
  }

  const handleCLoseButton = () => {
    setListData([])
    setIsImportingCsv(false)
    setIsDataImported(false)
    setIsDataHaveEmptyColumn(false)
    setTimeout(() => {
      setIsSpeedDialOpen(false)
    }, 200)
  }

  useEffect(() => {
    if(listData.length > 0) {
      const newListData = listData.map((item, index) => {
        return {
          ...item,
          id: index
        }
      })

      setTableData(newListData)
    }
  }, [listData])

  return (
    <Stack padding='24px' height='520px' sx={{overflowY: 'scroll'}}>

      {/* CAPTION */}
      {isDataHaveEmptyColumn ? 
        <Typography variant='h6' marginBottom='16px'>
          There are several empty fields, please complete them.
        </Typography> :
        <Typography variant='h6' marginBottom='16px'>
          Please check the data before sending it to the database.
        </Typography>
      }

      {/* DATA GRID */}
      <Stack className={classes.dataGridContainer}>
        <DataGridTable
          // BASE
          initialColumns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          rows={tableData}
          // PAGINATION
          total={total}
          page={pageNumber}
          setPage={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          // ORDER
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          // FILTER
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          // GROUP BY ROW
          selectedGroupBy={{}}
          getTreeDataPath={(row) => [ row.tx_date , row.id ]}
          groupingColDef={{}}
          // SELECTION
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
          checkboxSelection={false}
        />
      </Stack>

      {/* BUTTON */}
      <Stack 
        direction='row'
        justifyContent='flex-end'
        marginTop='24px'
      >
        {isDataHaveEmptyColumn ? 
          <Button 
            variant='contained'
            onClick={() => handleCLoseButton()}
          >
            Close
          </Button> : 
          <Button 
            variant='contained'
            onClick={() => handleCreateGateMove()}
          >
            Create Gate Moves
          </Button>
        }
      </Stack>

    </Stack>
  )
}

export default ReviewCsvFileComponent