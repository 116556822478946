import { useState, useContext, useEffect } from 'react'

// ASSETS
import LoadIcon from 'assets/images/logos/orders-load-icon.svg'
import UnloadingIcon from 'assets/images/logos/orders-unloading-icon.svg'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogAddOrEditItem from './DialogAddOrEditItem'
import Flyout from 'components/Flyout/Flyout'
import ItemDetail from './ItemDetail/ItemDetail'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

// MUI ICONS
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICE
import {
  searchDepoItemList,
  deleteItems,
} from 'services/items'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'

// UTILITIES
import {
  addSeparatorsForNumber,
  capitalizeEachWord,
  getDefaultErrorMessage
} from 'utilities/string'

const Items = () => {
  const layoutClasses = useLayoutStyles()
  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)
  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const initialColumns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        if (typeof params.id === 'number') return (
          <Stack
            direction='row'
            alignItems='center'
          >
            <Stack
              width='8px'
              height='8px'
              borderRadius='50px'
              marginRight='8px'
              sx={{ backgroundColor: params.value === true ? '#4CAF50' : '#ED6C02' }}
            />

            <Typography
              color={params.value === true ? '#4CAF50' : '#ED6C02'}
              fontSize='inherit'>
              {params.value === true ? 'Active' : 'Inactive'}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {

        if (typeof params.id === 'number') return <Stack
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <Box
            component='img'
            src={params.value === 'LOADING' ? LoadIcon : UnloadingIcon}
          />

          <Box>{params.value === 'LOADING' ? 'Loading' : 'Unloading'}</Box>
        </Stack>

      }
    },
    {
      field: 'fleet',
      headerName: 'Fleet Manager',
      flex: 1,
      minWidth: 300,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        return (
          <Stack maxWidth='250px'>
            <Typography
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontSize: 'inherit'
              }}
            >
              {params?.value?.fleet_manager_company}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'sku',
      headerName: 'SKU',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'item_name',
      headerName: 'Name',
      flex: 1,
      minWidth: 250,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (typeof params.id === 'number') return (
          <Typography fontSize='inherit'>
            {params?.value?.itemCode} - {params?.value?.itemType}
          </Typography>
        )
      }
    },
    {
      field: 'price',
      headerName: 'Selling Price',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        if (typeof params.id === 'number') return (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            width='95px'
          >
            <Typography fontSize='inherit'>
              IDR
            </Typography>
            <Typography fontSize='inherit'>
              {addSeparatorsForNumber(params.value)}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 150,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        return <Stack
          direction='row'
          className={layoutClasses.columnActions}
        >
          {/* EDIT BUTTON */}
          <CustomTooltipBlack
            onClick={() => handleEditItem(params.row)}
            title='Edit'
            placement='bottom'
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </CustomTooltipBlack>

          {/* DUPLICATE BUTTON */}
          <CustomTooltipBlack
            onClick={() => handleDuplcateItem(params.row)}
            title='Duplicate'
            placement='bottom'
          >
            <IconButton>
              <FileCopyIcon />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE BUTTON */}
          <CustomTooltipBlack
            onClick={() => setDialogConfirmationObject(params.row)}
            title='Delete'
            placement='bottom'
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </CustomTooltipBlack>
        </Stack>
      },
    },
  ]

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Fleet Manager',
      value: 'fleet',
    },
    {
      title: 'By Status',
      value: 'status',
    },
  ]

  const flyoutWidth = 387

  const [isDataGridLoading, setIsDataGridLoading] = useState(false)
  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [total, setTotal] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const [filters, setFilters] = useState({})
  const [selectionModel, setSelectionModel] = useState([])
  const [isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen] = useState(false)
  const [dateRangeTimeValue, setDateRangeTimeValue] = useState([])
  const [tableData, setTableData] = useState([])
  const [dialogConfirmationObject, setDialogConfirmationObject] = useState({})
  const [search, setSearch] = useState('')
  const [isFlyoutShown, setIsFlyoutShown] = useState(false)
  const [itemDetail, setItemDetail] = useState(null)
  const [actionType, setActionType] = useState('NEW_ITEM')
  const [editOrDuplicateItemData, setEditOrdDuplicateItemData] = useState(null)

  // GET COLDEF HEADER NAME
  const getColDefHeadername = () => {
    if (selectedGroupBy.value === 'fleet') return 'Fleet Manager'
    else if (selectedGroupBy.value === 'status') return 'Status'
  }

  const groupingColDef = {
    headerName: getColDefHeadername(),
    renderCell: (params) => {
      if (selectedGroupBy.value === 'fleet') {
        if (!params.rowNode.isAutoGenerated) return params.row.fleet.fleet_manager_company

        return (
          <Typography
            variant='subtitle1'
            className={layoutClasses.groupingRow}
            noWrap
          >
            {capitalizeEachWord(params?.rowNode?.groupingKey?.replaceAll('_', ' '))}
          </Typography>
        )
      }

      else if (selectedGroupBy.value === 'status') {
        if (!params.rowNode.isAutoGenerated) return (
          <Stack
            direction='row'
            alignItems='center'
          >
            <Stack
              width='8px'
              height='8px'
              borderRadius='50px'
              marginRight='8px'
              sx={{ backgroundColor: params?.row?.status === true ? '#4CAF50' : '#ED6C02' }}
            />

            <Typography
              color={params?.row?.status === true ? '#4CAF50' : '#ED6C02'}
              fontSize='inherit'>
              {params?.row?.status === true ? 'Active' : 'Inactive'}
            </Typography>
          </Stack>
        )

        return (
          <Typography
            variant='subtitle1'
            className={layoutClasses.groupingRow}
            noWrap
          >
            {params.rowNode.groupingKey ? 'Active' : 'False'}
          </Typography>
        )
      }
    },
  }

  const loadDepoItemlist = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const queryParams = {
      page: pageNumber,
      size: pageSize,
    }

    const newFilters = reject(isEmpty, filters)

    if (search) newFilters.globalSearch = search
    if (filters.price) newFilters.price = +filters.price
    if (filters.fleet) newFilters.fleetName = filters.fleet

    const resultDepoItemList = await searchDepoItemList(
      inputAbortController.signal,
      auth.accessToken,
      queryParams,
      newFilters,
    )

    if (resultDepoItemList.status === 200 && inputIsMounted) {
      setTableData(resultDepoItemList?.data?.rows)
      setTotal(resultDepoItemList?.data?.totalElements)
      setIsDataGridLoading(false)
    }
  }

  const handleEditItem = (inputRow) => {
    setEditOrdDuplicateItemData(inputRow)
    setIsDialogAddOrEditOpen(true)
    setActionType('EDIT_ITEM')
  }

  const handleDuplcateItem = (inputRow) => {
    setEditOrdDuplicateItemData(inputRow)
    setIsDialogAddOrEditOpen(true)
    setActionType('DUPLICATE_ITEM')
  }

  const handleDialogAddOrEditOpen = () => {
    setIsDialogAddOrEditOpen(true)
  }

  const handleConfirmationContinueButtonClick = async () => {
    const abortController = new AbortController()
    let paramsId
    if (dialogConfirmationObject.multiple) paramsId = dialogConfirmationObject.multiple
    else paramsId = [dialogConfirmationObject.id]

    const resultDeleteItem = await deleteItems(abortController.signal, auth.accessToken, paramsId)
    if (resultDeleteItem.status === 200) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: paramsId.length === 1 ? 'Successfully delete the item' : 'Successfully delete the items',
      })
      loadDepoItemlist(true, abortController.signal)
      setDialogConfirmationObject({})
      setSelectionModel([])
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: paramsId.length === 1 ? 'Failed to delete the item' : 'Failed to delete the items',
        message: getDefaultErrorMessage(resultDeleteItem),
      })
      setDialogConfirmationObject({})
    }
    abortController.abort()
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      // TO DO: CHANGE THESE FIELDS LATER
      (selectedGroupBy.value && inputItem.field === 'fleet') ||
      (selectedGroupBy.value && inputItem.field === 'status')
    ) { }
    else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const getTreeDataPathChildren = (inputRow) => {
    if (selectedGroupBy.value === 'fleet' && inputRow.fleet !== null) return inputRow.fleet.fleet_manager_company
    else if (selectedGroupBy.value === 'status' && inputRow.status !== null) return inputRow.status
    else return inputRow.fleet.fleet_manager_company
  }

  const flyoutIcon = () => {
    return (
      <Stack
        direction='row'
        width={isFlyoutShown ? '455px' : 'auto'}
        justifyContent='space-between'
        alignItems='center'
      >
        {isFlyoutShown &&
          <Typography variant='h6'>
            Information
          </Typography>}

        <IconButton onClick={() => setIsFlyoutShown(current => !current)}>
          <KeyboardTabIcon sx={{
            color: '#0000008A',
            transition: 'transform 0.5s',
            transform: isFlyoutShown ? 'rotate(0deg)' : 'rotate(180deg)'
          }} />
        </IconButton>
      </Stack>
    )
  }

  useEffect(() => {
    if (selectionModel.length === 0) setItemDetail(null)
    else if (selectionModel.length === 1) {
      const selectedItem = [...tableData].find((item) => item.id === selectionModel[0])
      setItemDetail(selectedItem)
      if (!isFlyoutShown) setIsFlyoutShown(true)
    }
    else if (selectionModel.length > 1) {
      setItemDetail(null)
    }

  }, [selectionModel])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoItemlist(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filters, search, pageSize, pageNumber, order, orderBy,])

  // HANDLE DATA GRID COLUMNS WHILE GROUPING 
  useEffect(() => {
    if (selectedGroupBy.value) {

      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === selectedGroupBy.value) item.hide = true
        else item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)

    } else {

      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }

  }, [selectedGroupBy])

  return (
    <>
      {/* APP BAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Items'
        hasFab={true}
        fabOnClickButton={handleDialogAddOrEditOpen}
        extraComponent={flyoutIcon()}
      />

      {/* CONTENT & FLYOUT */}
      <Stack
        direction='row'
        position='relative'
        height='100%'
        sx={{ paddingRight: isFlyoutShown ? `${flyoutWidth + 24}px` : 0 }}
      >
        <LoadingPaper isLoading={isDataGridLoading}>
          <DataGridFilters
            // COLUMN
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            // FILTER
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            // GROUP BY ROW
            groupByList={groupByList}
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
            // DATE RANGE TIME
            isWithDateTimePicker={false}
            dateRangeValue={dateRangeTimeValue}
            isDateRangeTimePickerOpen={isDateRangeTimePickerOpen}
            setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
            handleSelectDateRangePickerButtonClick={() => { }}
            handleCancelDateRangePickerButtonClick={() => setIsDateRangeTimePickerOpen(false)}
            // DELETE
            isWithDeleteIcon={true}
            isDeleteEnabled={selectionModel.length > 0}
            handleDeleteButtonClick={() => setDialogConfirmationObject({ multiple: selectionModel })}
          />

          <DataGridTable
            // BASE
            initialColumns={initialColumns}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            rows={tableData}
            // PAGINATION
            total={total}
            page={pageNumber}
            pageSize={pageSize}
            // ORDER
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPageNumber}
            setPageSize={setPageSize}
            // FILTER
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            // GROUP BY ROW
            selectedGroupBy={selectedGroupBy}
            getTreeDataPath={(row) => [getTreeDataPathChildren(row), row.id]}
            groupingColDef={groupingColDef}
            // SELECTION
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
        </LoadingPaper>

        {/* FLYOUT */}
        <Flyout isFlyoutShown={isFlyoutShown} flyoutWidth={flyoutWidth}>
          {itemDetail ?
            <ItemDetail itemDetail={itemDetail} /> :
            <Typography
              variant='subtitle2'
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              Select an entry from the list
            </Typography>
          }
        </Flyout>
      </Stack>

      {/* DIALOG ADD ORD EDIT ITEM */}
      <DialogAddOrEditItem
        actionType={actionType}
        editOrDuplicateItemData={editOrDuplicateItemData}
        setActionType={setActionType}
        reloadData={loadDepoItemlist}
      />

      {/* DIALOG CONFIRMATION */}
      <DialogConfirmation
        title={'Delete confirmation'}
        caption={'Are you sure to delete this item?'}
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        cancelButtonText='Back'
        continueButtonText='Delete'
        onContinueButtonClick={handleConfirmationContinueButtonClick}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
      />
    </>
  )
}

export default Items