import { useContext, useState, useEffect, useRef } from 'react'

// COMPONENTS
import DetailPaymentModal from './DetailPaymentModal'
import EditContact from './EditContact'
import EditOrderDetails from './EditOrderDetails'
import EditPaymentMethod from './EditPaymentMethod'
import PaymentMethod from './PaymentMethod'
import ReviewContact from './ReviewContact'
import ReviewOrderDetails from './ReviewOrderDetails'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import { 
  IconButton,
  Stack, 
  Typography 
} from '@mui/material'

// MUI ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// STYLES
import useStyles from '../../orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const ReviewOrders = () => {
  const classes = useStyles()

  const { orderListItems } = useContext(PrivateLayoutContext)

  const containerReff = useRef(null)

  const [isEditingContact, setIsEditingContact] = useState(false)
  const [isEditingOrderDetails, setIsEditingOrderDetails] = useState(false)
  const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false)
  const [isPaymentDetailOpen, setIsPaymentDetailOpen] = useState(false)

  // NOTE : TAKE OUT BY REQUEST
  const fee = 0

  // HANDLE TOTAL PRICE
  let totalPrice = fee
  for (let i = 0; i < orderListItems.length; i++) {
    totalPrice += orderListItems[i]?.price?? 0
  }

  useEffect(() => {
    if(containerReff) {
      containerReff.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [])
  
  return (
    <Stack ref={containerReff}>
      {/* CONTACT */}
      {isEditingContact ? 
        <EditContact setIsEditingContact={setIsEditingContact} /> : 
        <ReviewContact setIsEditingContact={setIsEditingContact} />
      }

      {/* ORDER DETAILS */}
      {isEditingOrderDetails ? 
        <EditOrderDetails setIsEditingOrderDetails={setIsEditingOrderDetails}/> : 
        <ReviewOrderDetails setIsEditingOrderDetails={setIsEditingOrderDetails}/>
      }

      {/* PAYMENT METHOD */}
      {isEditingPaymentMethod ?
        <EditPaymentMethod setIsEditingPaymentMethod={setIsEditingPaymentMethod}/> : 
        <PaymentMethod setIsEditingPaymentMethod={setIsEditingPaymentMethod}/>
      }
      

      {/* TOTAL PAYMENT */}
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        marginTop='20px'
      >
        <Typography className={classes.typography1}>
          Total Payment
        </Typography>

        <Stack
          direction='row'
          alignItems='center'
        >
          <Typography className={classes.typography5}>
            Rp {addSeparatorsForNumber(totalPrice)}
          </Typography>

          <IconButton onClick={() => setIsPaymentDetailOpen(true)}>
            <ExpandMoreIcon/>
          </IconButton>
        </Stack>
      </Stack>

      {/* DETAIL PAYMENT MODAL */}
      <DetailPaymentModal
        isPaymentDetailOpen={isPaymentDetailOpen}
        setIsPaymentDetailOpen={setIsPaymentDetailOpen}
        totalPrice={totalPrice}
        fee={fee}
        listOrder={orderListItems}
      />
    </Stack>
  )
}

export default ReviewOrders