// UTILITIES
import { capitalizeEachWord } from 'utilities/string'

export const generateOptionList = (inputList) => {
  const output = inputList.map(item => {
    return {
      text: capitalizeEachWord(item.replaceAll('_', ' ')),
      value: item,
    }
  })

  return output
}