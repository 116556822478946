// CONSTANTS
import { values } from 'constants/values'

export const getChartOptionsFM = (
  inputTheme,
  inputColorList,
  inputFilteredDay,
  inputXaxisCategories,
) => {
  return {
    chart: {
      fontFamily: values.fontFamily,
      foreColor: inputTheme.palette.text.primary,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
      },
      type: 'bar',
      zoom: {
        enabled: true,
        type: 'x',  
        autoScaleYaxis: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: inputColorList,
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: inputFilteredDay === 30 ? 4 : 10,
        columnWidth: inputFilteredDay === 30 ? '75%' : '50%',
        endingShape: 'rounded',
        horizontal: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          return value
        },
      },
    },
    xaxis: {
      categories: inputXaxisCategories,
      labels: {
        style: {
          fontSize: 14,
        },
      },
      tickPlacement: 'on',
    },
    legend : {
      show : false
    }
  }
}
export const getChartOptionsMS = (
  inputTheme,
  inputColorList,
  inputFilteredDay,
  inputXaxisCategories,
) => {
  return {
    chart: {
      fontFamily: values.fontFamily,
      foreColor: inputTheme.palette.text.primary,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
      },
      type: 'bar',
      zoom: {
        enabled: true,
        type: 'x',  
        autoScaleYaxis: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: inputColorList,
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: inputFilteredDay === 30 ? 4 : 10,
        columnWidth: inputFilteredDay === 30 ? '75%' : '50%',
        endingShape: 'rounded',
        horizontal: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          return value
        },
      },
    },
    xaxis: {
      categories: inputXaxisCategories,
      labels: {
        style: {
          fontSize: 14,
        },
      },
      tickPlacement: 'on',
    },
    legend : {
      show : true
    }
  }
}