import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import DialogAddOrdEditFleet from './DialogAddOrEditFleet'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconEdit from '@mui/icons-material/Edit'
import IconDelete from '@mui/icons-material/Delete'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import {
  getDepoFleetController,
  deleteFleetManager,
  postDepoFleetControllerSearch,
} from 'services/fleetManager'

// STYLES
import useStyles from './fleetManagerUseStyles'
import useLayoutStyles from 'styles/layoutPrivate'

// UTILITIES
import { getDefaultErrorMessage } from 'utilities/string'
import { Box } from '@mui/system'

const FleetManager = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)

  const initialColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'fleet_manager_company',
      headerName: 'Fleet Manager',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Stack 
          direction='row'
          className={layoutClasses.columnActions}
        >
          {/* EDIT ICON */}
          <CustomTooltipBlack
            onClick={() => handleEditClick(params.row)}
            title='Edit'
            placement='bottom'
          >
            <IconButton>
              <IconEdit/>
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack 
            onClick={() => handleDeleteClick(params.row)}
            title='Delete' 
            placement='bottom'
          >
            <IconButton>
              <IconDelete/>
            </IconButton>
          </CustomTooltipBlack>

        </Stack>
      },
    },
  ]

  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState(null)
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ tableData, setTableData ] = useState([])
  const [ dialogConfirmationObject, setDialogConfirmationObject ] = useState({})
  const [ actionType, setActionType ] = useState('')
  const [ editFleetManager, setEditFleetManager ] = useState(null)
  const [ search, setSearch ] = useState('')

  const handleDialogAddOrEditOpen = () => {
    setIsDialogAddOrEditOpen(true)
    setActionType('ADD_FLEET_MANAGER')
  }

  const handleEditClick = (inputRowData) => {
    setEditFleetManager(inputRowData)
    setIsDialogAddOrEditOpen(true)
    setActionType('EDIT_FLEET_MANAGER')
  }

  const handleDeleteClick = (inputRowData) => {
    setDialogConfirmationObject({
      fleetCode: inputRowData.code,
      confirmationTitle: 'Delete confirmation',
      confirmationCaption: inputRowData.name,
      ...inputRowData,
    })
  }

  const handleConfirmationContinueButtonClick = async () => {
    const abortController = new AbortController()

    const resultDeleteRequest = await deleteFleetManager(
      abortController.signal,
      {fleet_code: dialogConfirmationObject.fleetCode},
      auth.accessToken
    )

    // SHOWING A SUCCESS SNACKBAR AFTER SUCCESSFULLY DELETING THE FLEET
    if (resultDeleteRequest.status === 200) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message:  'Successfully delete the fleet',
      })
      loadDepoFleetListData(true, abortController.signal)
      setDialogConfirmationObject({})
      setSelectionModel([])
    } else if (resultDeleteRequest.status === 400){
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: 'Cannot delete this fleet',
        message: 'The fleet is currently in use',
      })
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: 'Failed to delete the fleet',
        message: getDefaultErrorMessage(resultDeleteRequest),
      })
      setDialogConfirmationObject({})
    }
    abortController.abort()
  }

  const loadDepoFleetListData = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const resultDepoFleetList = await getDepoFleetController(
      inputAbortController.signal, 
      {
        page: pageNumber,
        size: pageSize,
        sort: order && orderBy ? `${orderBy},${order}` : 'id,desc',
      },
      auth.accessToken,
    )
    
    if (resultDepoFleetList.status === 200 && inputIsMounted) {
      setTableData(resultDepoFleetList.data.rows) 
      setTotal(resultDepoFleetList.data.totalElements)
    }

    setIsDataGridLoading(false)
  }

  const searchDepoFleetListData = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const newFilters = reject(isEmpty, filters)

    const resultDepoFleetList = await postDepoFleetControllerSearch(
      inputAbortController.signal, 
      {
        page: pageNumber,
        size: pageSize,
        sort: order && orderBy ? `${orderBy},${order}` : 'id,desc',
      },
      {
        globalSearch: search,
        ...newFilters,
      },
      auth.accessToken,
    )
    
    if (resultDepoFleetList.status === 200 && inputIsMounted) {
      setTableData(resultDepoFleetList.data.rows) 
      setTotal(resultDepoFleetList.data.totalElements)
    }

    setIsDataGridLoading(false)
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoFleetListData(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filters, pageSize, pageNumber, order, orderBy])

  useEffect(() => {
    let isMounted = true
    const abortControler = new AbortController()

    searchDepoFleetListData(isMounted, abortControler)

    return () => {
      isMounted = false
      abortControler.abort()
    }

  }, [filters, search, pageSize, pageNumber, order, orderBy])

  return (
    <>
      {/* APPBAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Fleet manager'
        hasFab={true}
        fabOnClickButton={handleDialogAddOrEditOpen}
      />

      {/* CONTENT */}
      <LoadingPaper isLoading={isDataGridLoading}>
        <Box
          direction='row'
          alignItems='center'
          className={classes.root}
        >
          <Typography 
            variant='body2'
            className={classes.totalRow}
          >
            Total: {total} Fleet manager
          </Typography>
        </Box>

        <DataGridTable
          // BASE
          initialColumns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          rows={tableData}
          // PAGINATION
          total={total}
          page={pageNumber}
          pageSize={pageSize}
          // ORDER
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPageNumber}
          setPageSize={setPageSize}
          // FILTER
          setFilters={setFilters}
          // GROUP BY ROW
          getTreeDataPath={()=>[]}
          // SELECTION
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}    
        />
      </LoadingPaper>

      {/* DIALOG ADD OR EDIT FLEET MANAGER */}
      <DialogAddOrdEditFleet
        actionType={actionType}
        editFleetManager={editFleetManager}
        reloadData={loadDepoFleetListData}
        setEditFleetManager={setEditFleetManager}
      />

      {/* DIALOG CONFIRMATION */}
      <DialogConfirmation
        title={dialogConfirmationObject && dialogConfirmationObject.confirmationTitle}
        caption={dialogConfirmationObject && dialogConfirmationObject.confirmationCaption}
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        cancelButtonText='Cancel'
        continueButtonText={dialogConfirmationObject.type}
        onContinueButtonClick={handleConfirmationContinueButtonClick}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
      />
    </>
  )
}

export default FleetManager