import { IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'

// COMPONENTS
import ImportCsvComponent from './ImportCsvComponent'
// import ReviewCsvFileComponent from './ReviewCsvFileComponent'

// STYLES
import useStyles from './dialogImportCSVUseStyles'

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close'
import { useEffect } from 'react'

const DialogImportCSV = (props) => {
  const { 
    isImportingCsv,
    setIsImportingCsv,
    setIsSpeedDialOpen,
    setMustReloadDataGrid,
    setIsDataGridLoading,
  } = props

  const classes = useStyles()

  const [listData, setListData] = useState([])
  const [isDataImported, setIsDataImported] = useState(false)
  const [isDataHaveEmptyColumn, setIsDataHaveEmptyColumn] = useState(false)
  
  const handleCloseButton = () => {
    setListData([])
    setIsImportingCsv(false)
    setIsDataImported(false)
    setIsDataHaveEmptyColumn(false)
    setTimeout(() => {
      setIsSpeedDialOpen(false)
    }, 200)
  } 
  
  useEffect(() => {
    if(isDataImported === true) {
      let countEmptyField = 0
      listData.forEach((item) => {
        if(item.status === '' || item.booking === '' || item.tx_date === '' || item.bolNo ==='' || item.customer ==='' || item.invoice ==='' || item.amount ==='') countEmptyField+=1
      })

      if(countEmptyField > 0) setIsDataHaveEmptyColumn(true)
    }
  }, [isDataImported])
  
  return (
    <Modal open={isImportingCsv}>
      <Stack className={classes.root}>
        {/* HEADER */}
        <Stack 
          justifyContent='space-between'
          direction='row'
          height='64px'
          sx={{backgroundColor:'#F96649'}}
          alignItems='center'
          padding='0px 24px'
          color='white'
        >
          {/* TITLE */}
          <Typography variant='h6'>
            Import
          </Typography>

          {/* ICON CLOSE */}
          <IconButton onClick={() => handleCloseButton()}>
            <CloseIcon sx={{color: 'white'}}/>
          </IconButton>
        </Stack>

        {/* CONTENT */}
        {isDataImported ? 
          // <ReviewCsvFileComponent
          //   listData={listData}
          //   isDataHaveEmptyColumn={isDataHaveEmptyColumn}
          //   setListData={setListData}
          //   setIsImportingCsv={setIsImportingCsv}
          //   setIsDataImported={setIsDataImported}
          //   setIsDataHaveEmptyColumn={setIsDataHaveEmptyColumn}
          //   setIsSpeedDialOpen={setIsSpeedDialOpen}
          //   setMustReloadDataGrid={setMustReloadDataGrid}
          //   setIsDataGridLoading={setIsDataGridLoading}
          // /> :
          <></> :
          <ImportCsvComponent 
            setIsImportingCsv={setIsImportingCsv}
            setListData={setListData}
            setIsDataImported={setIsDataImported}/>
        }
      </Stack>
    </Modal>
  )
}

export default DialogImportCSV