import { useContext, useEffect, useState } from 'react'

// COMPONENT
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// DATE RANGE PICKER
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

// MUIS
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  IconButton,
  Divider,
  Button,
} from '@mui/material'

// MUI ICONS
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import CategoryIcon from '@mui/icons-material/Category'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EmailIcon from '@mui/icons-material/Email'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import MapIcon from '@mui/icons-material/Map'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import StyleIcon from '@mui/icons-material/Style'
import TextFormatIcon from '@mui/icons-material/TextFormat'

// SERVICE
import { getDepoListItem, checkBOLFromINSW } from 'services/order'

// STYLES
import useStyles from '../../orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const OrderForm = ({ isValidatingForms, setIsLoading }) => {
  const classes = useStyles()

  const {
    orderForms, setOrderForms,
    orderListItems, setOrderListItems
  } = useContext(PrivateLayoutContext)

  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const bookingTypeList = [
    {
      label: 'Unloading'
    },
    {
      label: 'Loading'
    },
  ]

  const paymentMethodList = [
    // {
    //   label : 'Mandiri Virtual Account',
    //   value : 'MANDIRI'
    // },
    // {
    //   label : 'BCA Virtual Account',
    //   value : 'BCA'
    // },
    {
      label: 'BRI Virtual Account',
      value: 'BRI',
    },
    {
      label: 'BNI Virtual Account',
      value: 'BNI'
    },
    {
      label: 'Permata Bank Virtual Account',
      value: 'PERMATA'
    },
    {
      label: 'BSI Virtual Account',
      value: 'BSI'
    },
    {
      label: 'Bank BJB Virtual Account',
      value: 'BJB'
    },
  ]

  const [depoListItem, setDepoListItem] = useState([])
  const [bookingType, setBookingType] = useState(true)

  // HANDLE CHECK BOL NO FROM INSW
  const handleCheckBOL = async () => {
    setIsLoading(true)
    const abortController = new AbortController()

    const queryParams = {
      bol: orderForms.bolNo,
      id: auth.id
    }
    const resultData = await checkBOLFromINSW(abortController.signal, auth.accessToken, queryParams)

    const tempListItem = []

    depoListItem.forEach((item) => {
      resultData?.data?.container?.filter((item) => item.activeStatus === true).forEach((newItem) => {
        if (newItem?.itemResponse?.id === item?.id) {
          tempListItem.push({
            containerNo: newItem.noContainer,
            containerType: '',
            id: Math.floor(Math.random() * 1000000),
            price: item?.price,
            tempPrice: item?.price,
            quantity: 1,
            value: item
          })
        }
      })
    })

    if (resultData.status === 200 && tempListItem.length > 0) {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: `Data from Bill of Lading No. ${orderForms.bolNo} found`,
      })
      // SET ORDER ITEM
      setOrderListItems(tempListItem)
      // PARSING DATA TO DETAIL ORDERS
      setOrderForms(current => {
        return {
          ...current,
          consignee: resultData?.data?.consignee,
          npwp: resultData?.data?.npwpConsignee
        }
      })
    } else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: `Data from Bill of Lading No. ${orderForms.bolNo} not found`,
      })
    }
  }

  // LOAD DEPO LIST ITEM
  const loadDepoListItem = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: auth?.id,
      type: orderForms.booking.label === 'Unloading' ? 'UNLOADING' : 'LOADING'
    }
    const resultDepoListItem = await getDepoListItem(inputAbortController.signal, queryParams)
    if (resultDepoListItem.status === 200 && inputIsMounted) {
      setDepoListItem(resultDepoListItem.data)
    }
  }

  // HANDLE ORDER FORM CHANGE
  const handleOrderFormsChange = (event) => {
    setOrderForms(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // ADD NEW CONTAINER LIST
  const handleAddNewContainerList = () => {
    let newForm = {
      containerNo: '',
      containerType: '',
      quantity: 1,
      id: Math.floor(Math.random() * 1000000)
    }
    setOrderListItems(current => [...current, newForm])
  }

  // DELETE CONTAINER LIST
  const deleteContainerList = (inputIndex) => {
    const newList = [...orderListItems]
    newList.splice(inputIndex, 1)
    setOrderListItems(newList)
  }

  // HANDLE CONTAINER NO CHANGE
  const handleContainerNoChange = (inputItem, inputEvent) => {
    const newList = [...orderListItems].map((item) => {
      if (item.id === inputItem.id) item.containerNo = inputEvent.target.value
      return item
    })
    setOrderListItems(newList)
  }

  // HANDLE QUANTITY CHANGE
  const handleQuantityChange = (inputItem, inputEvent) => {
    const newList = [...orderListItems].map((item) => {
      if (item.id === inputItem.id) item.quantity = inputEvent.target.value
      return item
    })
    setOrderListItems(newList)
  }

  // HANDLE ITEMS CHANGE
  const handleContainerTypeChange = (inputItem, inputValue) => {
    const newList = [...orderListItems].map((item) => {
      if (item.id === inputItem.id) {
        item.value = inputValue
        item.tempPrice = inputValue?.price
        item.price = inputValue?.price
        item.quantity = 1
      }
      return item
    })
    setOrderListItems(newList)
  }

  // HANDLE BOOKING TYPE CHANGE
  const handleBookingTypeChange = (value) => {
    setBookingType(current => !current)
    setOrderForms(current => ({ ...current, booking: value }))
    setOrderListItems([
      {
        containerNo: '',
        value: '',
        quantity: 1,
        id: 1,
      }
    ])
  }

  // HANDLE PAYMENT METHOD CHANGE
  const handlePaymentMethodChange = (inputValue) => {
    setOrderForms(current => ({
      ...current,
      paymentMethod: inputValue,
    }))
  }

  // HANDLE TOTAL PRICE
  let totalPrice = 0
  for (let i = 0; i < orderListItems.length; i++) {
    totalPrice += (orderListItems[i]?.price * orderListItems[i]?.quantity) ?? 0
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListItem(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [bookingType])

  return (
    <>
      {/* CONTACT */}
      <>
        {/* TITLE */}
        <Typography className={classes.typography1}>
          Contact
        </Typography>

        {/* FULL NAME */}
        <Stack
          direction='row'
          alignItems='center'
          marginTop='20px'
          width='100%'
        >
          <TextFormatIcon className={classes.formIcon} />
          <FormControl
            fullWidth
            error
          >
            <TextField
              fullWidth
              label='Full Name'
              variant='standard'
              name='name'
              value={orderForms.name}
              onChange={(event) => handleOrderFormsChange(event)}
              error={(isValidatingForms && orderForms.name === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && orderForms.name === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>

        {/* PHONE NUMBER & EMAIL */}
        <Stack
          direction='row'
          justifyContent='space-between'
          marginTop='20px'
        >

          {/* PHONE NUMBER */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <ContactPhoneIcon className={classes.formIcon} />
            <FormControl
              fullWidth
              error
            >
              <TextField
                className={classes.fieldNumber}
                fullWidth
                type='number'
                label='Phone Number'
                variant='standard'
                name='phoneNumber'
                value={orderForms.phoneNumber}
                onChange={(event) => handleOrderFormsChange(event)}
                error={(isValidatingForms && orderForms.phoneNumber === '') ? true : false}
              />
              {/* HELPER TEXT */}
              {(isValidatingForms && orderForms.phoneNumber === '') &&
                <FormHelperText>This field can't be empty</FormHelperText>}
            </FormControl>
          </Stack>

          {/* EMAIL */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <EmailIcon className={classes.formIcon} />
            <FormControl
              fullWidth
              error
            >
              <TextField
                fullWidth
                label='Email'
                variant='standard'
                name='email'
                type='email'
                value={orderForms.email}
                onChange={(event) => handleOrderFormsChange(event)}
                error={(isValidatingForms && orderForms.email === '') ? true : false}
              />
              {/* HELPER TEXT */}
              {(isValidatingForms && orderForms.email === '') &&
                <FormHelperText>This field can't be empty</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>
      </>

      {/* ORDER DETAILS */}
      <>
        {/* TITLE */}
        <Typography marginTop='29px' className={classes.typography1}>
          Order Details
        </Typography>

        {/* BOOKING & DATE */}
        <Stack
          direction='row'
          justifyContent='space-between'
          marginTop='20px'
        >

          {/* BOOKING */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <CategoryIcon className={classes.formIcon} />
            <Autocomplete
              fullWidth
              disableClearable
              disablePortal
              options={bookingTypeList}
              value={orderForms.booking || null}
              onChange={(event, value) => handleBookingTypeChange(value)}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={(params) => <TextField {...params} variant='standard' label='Booking' />}
            />
          </Stack>

          {/* DATE */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <DateRangeIcon className={classes.formIcon} />
            <FormControl
              fullWidth
              error
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label='Date mobile'
                  inputFormat='dd MMMM yyyy'
                  value={orderForms?.date}
                  onChange={(newValue) => setOrderForms(current => ({ ...current, date: newValue }))}
                  renderInput={(params) => <TextField fullWidth {...params} variant='standard' label='Date' />}
                />
              </LocalizationProvider>
              {/* HELPER TEXT */}
              {(isValidatingForms && !orderForms.date) &&
                <FormHelperText>This field can't be empty</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>

        {/* BOL NO & CONSIGNEE */}
        <Stack
          direction='row'
          justifyContent='space-between'
          marginTop='20px'
        >
          {/* BOL NO */}
          <Stack
            direction='row'
            alignItems='flex-end'
            width='50%'
          >
            <Stack
              direction='row'
              alignItems='center'
              width='100%'
            >
              <BookmarkIcon className={classes.formIcon} />
              <FormControl
                fullWidth
                error
              >
                <TextField
                  fullWidth
                  label={orderForms.booking.label === 'Unloading' ? 'Bill of Lading No.' : 'Delivery Order No.'}
                  variant='standard'
                  name='bolNo'
                  value={orderForms.bolNo}
                  onChange={(event) => handleOrderFormsChange(event)}
                  error={(isValidatingForms && orderForms.bolNo === '') ? true : false}
                />
                {/* HELPER TEXT */}
                {(isValidatingForms && orderForms.bolNo === '') &&
                  <FormHelperText>This field can't be empty</FormHelperText>}
              </FormControl>
            </Stack>
            {/* BUTTON CHECK BOL FOR UNLOADING ORDER ONLY */}
            {orderForms?.booking?.label === 'Unloading' && <Stack borderBottom='1px solid #0000006b'>
              <Button
                size='small'
                onClick={handleCheckBOL}
                disabled={orderForms.bolNo !== '' ? false : true}
              >
                Check
              </Button>
            </Stack>}
          </Stack>


          {/* CONSIGNEE */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <ApartmentIcon className={classes.formIcon} />
            <FormControl
              fullWidth
              error
            >
              <TextField
                fullWidth
                label={orderForms.booking.label === 'Unloading' ? 'Consignee' : 'Shipper'}
                variant='standard'
                name='consignee'
                value={orderForms.consignee}
                onChange={(event) => handleOrderFormsChange(event)}
                error={(isValidatingForms && orderForms.consignee === '') ? true : false}
              />
              {/* HELPER TEXT */}
              {(isValidatingForms && orderForms.consignee === '') &&
                <FormHelperText>This field can't be empty</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>

        {/* NPWP & NPWP ADDRESS */}
        <Stack
          direction='row'
          justifyContent='space-between'
          marginTop='20px'
        >
          {/* NPWP */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <FormatListNumberedIcon className={classes.formIcon} />
            <FormControl
              fullWidth
              error
            >
              <TextField
                fullWidth
                type='number'
                className={classes.fieldNumber}
                label='NPWP'
                variant='standard'
                name='npwp'
                value={orderForms.npwp}
                onChange={(event) => handleOrderFormsChange(event)}
                error={(isValidatingForms && orderForms.npwp === '') ? true : false}
              />
              {/* HELPER TEXT */}
              {(isValidatingForms && orderForms.npwp === '') &&
                <FormHelperText>This field can't be empty</FormHelperText>}
            </FormControl>
          </Stack>

          {/* NPWP ADDRESS */}
          <Stack
            direction='row'
            alignItems='center'
            width='48%'
          >
            <MapIcon className={classes.formIcon} />
            <FormControl
              fullWidth
              error
            >
              <TextField
                fullWidth
                label='NPWP Company Address'
                variant='standard'
                name='npwpAddress'
                value={orderForms.npwpAddress}
                onChange={(event) => handleOrderFormsChange(event)}
                error={(isValidatingForms && orderForms.npwpAddress === '') ? true : false}
              />
              {/* HELPER TEXT */}
              {(isValidatingForms && orderForms.npwpAddress === '') &&
                <FormHelperText>This field can't be empty</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>

        {/* ITEMS */}
        {orderListItems.map((item, index) => (
          <Stack
            key={index}
            marginTop='20px'
            spacing={2}
          >
            {/* LIST ITEMS */}
            <Stack
              direction='row'
              alignItems='center'
            >
              <Stack
                direction='row'
                alignItems='center'
                width='91%'
              >
                <StyleIcon className={classes.formIcon} />
                <FormControl
                  fullWidth
                  error
                >
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={depoListItem}
                    value={item?.value || null}
                    onChange={(event, value) => handleContainerTypeChange(item, value)}
                    isOptionEqualToValue={(options, value) => options?.item_name?.itemCode === value.item_name?.itemCode}
                    renderInput={(params) => <TextField {...params} variant='standard' label='Items' />}
                    getOptionLabel={(option) => `${option.item_name.itemCode} - ${option.item_name.itemType}`}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Stack width='100%'>
                          {/* ITEM NAME & PRICE */}
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                          >
                            <Typography
                              variant='body2'
                              sx={{ fontWeight: 500 }}
                            >
                              {option?.item_name?.itemCode} - {option?.item_name?.itemType}
                            </Typography>

                            <Typography variant='caption'>
                              {`Rp. ${addSeparatorsForNumber(option.price)}`}
                            </Typography>
                          </Stack>

                          {/* FLEET MANAGER */}
                          <Typography variant='caption'>
                            {option?.fleet?.fleet_manager_company}
                          </Typography>

                          {/* SKU */}
                          <Typography variant='caption'>
                            {option.sku}
                          </Typography>
                        </Stack>
                      </li>
                    )}
                  />
                  {/* HELPER TEXT */}
                  {(isValidatingForms && item.value === '') &&
                    <FormHelperText>This field can't be empty</FormHelperText>}
                </FormControl>

              </Stack>
              {/* COUNTER BUTTON */}
              <Stack
                direction='row'
                alignItems='center'
              >
                {/* REMOVE CONTAINER */}
                <IconButton
                  onClick={() => deleteContainerList(index)}
                  disabled={orderListItems.length > 1 ? false : true}
                  sx={(theme) => ({ color: theme.palette.primary.main })}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>

                {/* ADD CONTAINER */}
                <IconButton
                  onClick={() => handleAddNewContainerList()}
                  sx={(theme) => ({ color: theme.palette.primary.main })}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Stack>
            </Stack>

            {/* CONTAINER NO / QUANTITY */}
            {orderForms.booking.label === 'Unloading' ?
              // CONTAINER NO
              <Stack
                direction='row'
                alignItems='center'
              >
                <ChromeReaderModeIcon className={classes.formIcon} />
                <FormControl
                  fullWidth
                  error
                >
                  <TextField
                    fullWidth
                    label='Container No'
                    variant='standard'
                    name='containerNo'
                    value={item?.containerNo}
                    onChange={(event) => handleContainerNoChange(item, event)}
                    error={(isValidatingForms && item.containerNo === '') ? true : false}
                  />
                  {/* HELPER TEXT */}
                  {(isValidatingForms && item.containerNo === '') &&
                    <FormHelperText>This field can't be empty</FormHelperText>}
                </FormControl>
              </Stack> :

              // QUANTITY
              <Stack
                direction='row'
                alignItems='center'
              >
                <ControlPointDuplicateIcon className={classes.formIcon} />
                <TextField
                  fullWidth
                  disabled={item?.value?.id === undefined ? true : false}
                  type='number'
                  inputProps={{ min: 1 }}
                  label='Quantity'
                  variant='standard'
                  name='quantity'
                  value={item?.quantity}
                  onChange={(event) => handleQuantityChange(item, event)}
                />
              </Stack>
            }
          </Stack>
        ))}
      </>

      {/* PRICE DETAIL */}
      <>
        {/* TITLE */}
        <Typography marginTop='29px' className={classes.typography1}>
          Price Detail
        </Typography>

        {/* CONTENT */}
        <Stack className={classes.priceDetailContainer}>
          {/* ROWS */}
          {orderListItems.map((item, index) => (
            <Stack
              key={index}
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
              paddingTop={index !== 0 ? '16px' : '0px'}
              paddingBottom='16px'
              sx={{ borderTop: index !== 0 ? '1px dashed #35B5654D' : 'unset' }}
            >
              <Stack width='80%'>
                {/* ITEM NAME & FLEET */}
                <CustomTooltipBlack
                  followCursor
                  title={`${item?.value?.item_name?.itemCode} - ${item?.value?.item_name?.itemType} (${item?.value?.fleet?.fleet_manager_company})`}
                  placement='bottom'
                >
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0.5}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    {item?.value?.item_name?.itemCode && <Typography className={classes.orderFormItemName}>
                      {item?.value?.item_name?.itemCode} - {item?.value?.item_name?.itemType} ({item?.value?.fleet?.fleet_manager_company})
                    </Typography>}
                  </Stack>
                </CustomTooltipBlack>

                {/* SKU */}
                <Stack marginLeft='12px'>
                  {item?.value?.sku && <Typography className={classes.typography2}>
                    - SKU : {item?.value?.sku}
                  </Typography>}
                </Stack>

                {/* DESCRIPTION */}
                <CustomTooltipBlack
                  followCursor
                  title={item?.value?.description}
                  placement='bottom'
                >
                  <Stack marginLeft='10px'>
                    {item?.value?.description && <Typography className={classes.orderFormDesc}>
                      - Desc : {item?.value?.description}
                    </Typography>}
                  </Stack>
                </CustomTooltipBlack>
              </Stack>

              <Typography className={classes.typography3}>
                Rp {addSeparatorsForNumber(item?.price * +item?.quantity)}
              </Typography>
            </Stack>
          ))}

          {/* DIVIDER */}
          <Divider />

          {/* TOTAL PAYMENT */}
          <Stack
            justifyContent='space-between'
            direction='row'
            alignItems='center'
            marginTop='24px'
            marginBottom='4px'
          >
            <Typography className={classes.typography1}>
              Total Payment
            </Typography>

            <Typography className={classes.typography1}>
              Rp {addSeparatorsForNumber(totalPrice)}
            </Typography>
          </Stack>
        </Stack>
      </>

      {/* PAYMENT METHOD */}
      {/* TITLE */}
      <Typography marginTop='29px' className={classes.typography1}>
        Payment Method
      </Typography>

      {/* BOOKING */}
      <Stack
        direction='row'
        alignItems='center'
        marginTop='20px'
      >
        <AccountBalanceIcon className={classes.formIcon} />
        <FormControl
          fullWidth
          error
        >
          <Autocomplete
            disableClearable
            fullWidth
            disablePortal
            options={paymentMethodList}
            value={orderForms.paymentMethod || null}
            onChange={(event, value) => handlePaymentMethodChange(value)}
            isOptionEqualToValue={(option, value) => option?.label === value?.label}
            renderInput={(params) => <TextField {...params} variant='standard' label='Payment Method' />}
          />
          {/* HELPER TEXT */}
          {(isValidatingForms && !orderForms.paymentMethod) &&
            <FormHelperText>This field can't be empty</FormHelperText>}
        </FormControl>
      </Stack>
    </>
  )
}

export default OrderForm