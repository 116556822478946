import { useContext } from 'react'

// COMPONENT
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import {
  Button,
  Stack, 
  Typography,
} from '@mui/material'

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

// STYLES 
import useStyles from '../../orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'
import { convertDate } from 'utilities/date'

const ReviewOrderDetails = (props) => {
  const { setIsEditingOrderDetails } = props
  const classes = useStyles()
  const { 
    orderForms,
    orderListItems,
  } = useContext(PrivateLayoutContext)

  return (
    <>
      {/* TITLE */}
      <Typography
        marginTop='35px'
        className={classes.typography1}
      >
        Order Details
      </Typography>

      {/* BOL NO */}
      <Stack
        marginTop='20px'
        direction='row'
        height='72px'
        justifyContent='space-between'
        alignItems='center'
        borderTop='1px solid #0000001F'
        borderBottom='1px solid #0000001F'
      >
        <Typography className={classes.typography3}>
          {orderForms?.bolNo}
        </Typography>

        <Button 
          startIcon={<EditIcon/>}
          variant='outlined'
          onClick={() => setIsEditingOrderDetails(true)}
        >
          Edit
        </Button>
      </Stack>

      {/* BOOKING */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          Booking
        </Typography>

        <Typography className={classes.typography3}>
          {orderForms?.booking?.label}
        </Typography>
      </Stack>

      {/* DATE */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          Date
        </Typography>

        <Typography className={classes.typography3}>
          {convertDate(orderForms?.date, 'dd MMMM yyyy')}
        </Typography>
      </Stack>

      {/* CONSIGNEE */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          {orderForms.booking.label === 'Unloading' ? 'Consignee' : 'Shipper'}
        </Typography>

        <Typography className={classes.typography3}>
          {orderForms?.consignee}
        </Typography>
      </Stack>

      {/* NPWP */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          NPWP
        </Typography>

        <Typography className={classes.typography3}>
          {orderForms?.npwp}
        </Typography>
      </Stack>

      {/* NPWP */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          NPWP Company Address
        </Typography>

        <Typography className={classes.typography3}>
          {orderForms?.npwpAddress}
        </Typography>
      </Stack>

      {/* ITEMS */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          List Items
        </Typography>

        {orderListItems.map((item, index) => (
          <Stack
            key={index}
            alignItems='flex-start'
            justifyContent='space-between'
            direction='row'
            marginTop='10px'
            paddingBottom='10px'
            borderBottom={index === 0 ? '1px dashed #D9D9D9' : '1px solid #D9D9D9'}
          >
            <Stack  width='80%'>
              {/* ITEM NAME & FLEET */}
              <CustomTooltipBlack 
                followCursor
                title={`${item?.value?.item_name?.itemCode} - ${item?.value?.item_name?.itemType} (${item?.value?.fleet?.fleet_manager_company})`}
                placement='bottom'
              >
                <Stack 
                  alignItems='center'
                  direction='row'
                  spacing={0.5}
                >
                  <FiberManualRecordIcon className={classes.dotIcon}/>
                  <Typography className={classes.orderFormItemName}>
                    {item?.value?.item_name?.itemCode} - {item?.value?.item_name?.itemType} ({item?.value?.fleet?.fleet_manager_company})
                  </Typography>
                </Stack>
              </CustomTooltipBlack>

              {/* SKU & CONTAINER NUMBER / QUANTITY */}
              <Stack 
                alignItems='center'
                direction='row'
                marginLeft='12px'
              >
                <Typography width='200px' className={classes.typography2}>
                  - SKU : {item?.value?.sku}
                </Typography>
                {orderForms?.booking?.label === 'Unloading' ? 
                  <Typography className={classes.typography2}>
                    - Container No : {item?.containerNo} 
                  </Typography> : 
                  <Typography className={classes.typography2}>
                    - Quantity : {item?.quantity} 
                  </Typography>
                }
              </Stack>

              {/* DESCRIPTION */}
              <CustomTooltipBlack
                followCursor 
                title={item?.value?.description}
                placement='bottom'
              >
                <Stack>
                  <Typography marginLeft='12px' className={classes.orderFormDesc}>
                    - Desc : {item?.value?.description}
                  </Typography>
                </Stack>
              </CustomTooltipBlack>
            </Stack>

            <Typography className={classes.typography3}>
              Rp {addSeparatorsForNumber(item?.price)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  )
}

export default ReviewOrderDetails