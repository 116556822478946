export const dummyCompanyProfile = [
  {
    title: 'Person in charge',
    value: 'Alex'
  },
  {
    title: 'Organization name',
    value: 'Transdepo'
  },
  {
    title: 'Company email',
    value: 'justin@transdepo.com'
  },
  {
    title: 'Phone number',
    value: '084121774823'
  },
  {
    title: 'Alamat',
    value: 'Yogyakarta'
  },
  {
    title: 'NPWP number',
    value: '-'
  },
  {
    title: 'SIUP number',
    value: 'Alex'
  },
  {
    title: 'NIB number',
    value: '-'
  },
  {
    title: 'KTA Asdeki',
    value: 'Alex'
  },
]

export const dummyFileAttachment = [
  {
    document_title: 'NPWP number',
    upload_date: '27 MAR 2022',
    action: 'action',
    id: 1,
  },
  {
    document_title: 'SIUP number',
    upload_date: '27 MAR 2022',
    action: 'action',
    id: 2,
  },
  {
    document_title: 'NIB number',
    upload_date: '27 MAR 2022',
    action: 'action',
    id: 3,
  },
  {
    document_title: 'KTA ASDEKI',
    upload_date: '27 MAR 2022',
    action: 'action',
    id: 4,
  },
]