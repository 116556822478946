//APIS
import { axiosPrivate } from 'apis/axios'
import axios from 'apis/axios'

export const getDepoOrderList = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      `/api/depo/order?size=${inputQueryParams}`,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getDepoListItem = async (inputSignal, inputQueryParams) => {
  try {
    const response = await axios.get(
      `api/booking/depo/item?depo_id=${inputQueryParams.id}&type=${inputQueryParams.type}`,
      { signal: inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const createNewUnloadingOrder = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.post(
      '/api/depo/order/unloading',
      inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const createNewLoadingOrder = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.post(
      '/api/depo/order/loading',
      inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const searchDepoListOrders = async (inputSignal, inputToken, inputBodyParams, inputQueryParams) => {
  try {
    const response = await axiosPrivate.post(
      `api/depo/order/search?page=${inputQueryParams.page}&size=${inputQueryParams.size}`,
      inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const xenditPayment = async (inputSignal, inputBodyParams, inputToken) => {
  try {
    const response = await axios.post(
      'api/depo/order/payment',
      inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` }
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const downloadInvoice = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      `api/depo/order/invoice/${inputQueryParams}`,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
        responseType: 'blob'
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const checkBOLFromINSW = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      `api/insw/${inputQueryParams.bol}?depoId=${inputQueryParams.id}`,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const cancelBookingOrder = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.put(
      `api/depo/order/cancel?booking_id=${inputQueryParams}`,
      {},
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}