import { useState, useContext } from 'react'

// ASSETS
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect.svg'

// COMPONENTS
import Form1 from './Form1'
import Form2 from './Form2'
import FormFinish from './FormFinish'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI LABS
import LoadingButton from '@mui/lab/LoadingButton'

// RAMDA
import * as Ramda from 'ramda'

// SERVICES
import { 
  getCheckUserEmail,
  getCheckUserPhoneNumber, 
  postRegisterUser,
} from 'services/depoOwner'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

// UTILITIES
import { getDefaultErrorMessage } from 'utilities/string'
import {
  isEmailFormatValid,
  isPasswordFormatValid,
  isPhoneNumberFormatValid,
} from 'utilities/validation'

const SignUp = () => {
  const layoutClasses = useLayoutStyles()
  
  const { setSnackbarObject } = useContext(AllPagesContext)

  const initialSignUpForm = {
    email: '',
    phoneNumber: '',
    password: '',
    fullName: '',
    organizationName: '',
    address: '',
  }

  const initialErrorForm = {
    email: null,
    phoneNumber: null,
    password: null,
    address: null,
  }

  const [ signUpForm, setSignUpForm ] = useState(initialSignUpForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ selectedForm, setSelectedForm ] = useState(1)

  const handleSignUpFormChange = (event) => {
    setSignUpForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const updateErrorForm = (inputName, inputNewValue) => {
    setErrorForm(current => ({
      ...current,
      [inputName]: inputNewValue,
    }))
  } 

  const getSelectedForm = () => {
    if (selectedForm === 1) return (
      <Form1 
        form={signUpForm}
        handleFormChange={handleSignUpFormChange}
        errorForm={errorForm}
      />
    )
    else if (selectedForm === 2) return (
      <Form2
        form={signUpForm}
        handleFormChange={handleSignUpFormChange}
      />
    )
    else if (selectedForm === 3) return <FormFinish form={signUpForm}/>
  }

  const checkForm1WithTheAPI =  async () => {
    setErrorForm(initialErrorForm)

    const resultCheckUserPhoneNumber = await getCheckUserPhoneNumber(signUpForm.phoneNumber)
    const resultCheckUserEmail = await getCheckUserEmail(signUpForm.email)
  
    let errorPhoneNumber = null
    let errorEmail = null

    // CHECK THE PHONE NUMBER WITH THE API
    if (
      resultCheckUserPhoneNumber.status === 200 &&
      resultCheckUserPhoneNumber.data.exist
    ) errorPhoneNumber = 'Phone number already exists'
    else if (resultCheckUserPhoneNumber.status !== 200) {
      // TO DO: HANDLE ERROR 400 AND 500 FROM CALLING THE API HERE
    }

    // CHECK THE EMAIL WITH THE API
    if (
      resultCheckUserEmail.status === 200 &&
      resultCheckUserEmail.data.exist
    ) errorEmail = 'Email already exists'
    else if (resultCheckUserEmail.status !== 200) {
      // TO DO: HANDLE ERROR 400 AND 500 FROM CALLING THE API HERE
    }

    const newErrorForm = {
      ...initialErrorForm,
      phoneNumber: errorPhoneNumber,
      email: errorEmail,
    }

    // SHOW ERROR FORM MESSAGES HERE OR PROCEED TO THE NEXT FORM
    if (Ramda.isEmpty(Ramda.reject(Ramda.equals(null), newErrorForm))) setSelectedForm(2)
    else setErrorForm(newErrorForm)
  }

  const validateForm1 = () => {
    if (!isEmailFormatValid(signUpForm.email)) updateErrorForm('email', 'Email can only consist of letters, numbers, and special characters (@.-_).')
    else updateErrorForm('email', null)

    if (signUpForm.address === '') updateErrorForm('address', 'Address can\'t be empty.')
    else updateErrorForm('email', null)
    
    //TEMPORARY COMMENT BY REQUEST
    /*   if (!isPhoneNumberFormatValid(signUpForm.phoneNumber)) updateErrorForm('phoneNumber', 'The mobile number must consist of 8-15 digits.')
    else updateErrorForm('phoneNumber', null) */

    if (!isPasswordFormatValid(signUpForm.password)) updateErrorForm('password', 'Minimum 8 characters length')
    else updateErrorForm('password', null)

    if (isEmailFormatValid(signUpForm.email) && isPhoneNumberFormatValid(signUpForm.phoneNumber) && isPasswordFormatValid(signUpForm.password) && signUpForm.address !== '') {
      checkForm1WithTheAPI()
    }
  }

  const handleFormButtonClick = async (inputEvent) => {
    inputEvent.preventDefault()
    
    if (selectedForm === 1) validateForm1()
    else if (selectedForm === 2) {
      // REGISTER THE USER
      const resultRegisterUser = await postRegisterUser({
        companyEmail: signUpForm.email,
        phoneNumber: signUpForm.phoneNumber,
        password: signUpForm.password,
        fullName: signUpForm.fullName,
        organizationName: signUpForm.organizationName,
        address: signUpForm.address,
      })

      // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY REGISTRING THE USER
      if (resultRegisterUser.status === 201) {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully creating a user',
        })

        setSelectedForm(3)
      }
      // SHOW ERROR MESSAGE AFTER FAILED TO REGISTR THE USER
      else {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Failed to register the user',
          message: getDefaultErrorMessage(resultRegisterUser),
        })
      }
    }
  }

  return (
    <form  
      className={layoutClasses.root}
      onSubmit={handleFormButtonClick}
    >
      {/* PRODUCT LOGO */}
      {(selectedForm === 1 || selectedForm === 2) &&
      <Box
        component='img'
        src={LogoProductNleConnet}
        alt=''
        className={layoutClasses.productLogo}
      />}

      {/* SELECTED FORM */}
      {getSelectedForm()}

      {/* ACTION BUTTONS */}
      {(selectedForm === 1 || selectedForm === 2) &&
      <Stack 
        direction='row'
        spacing='12px'
        className={layoutClasses.formButtonContainer}
      >
        {/* JOIN TEAM BUTTON */}
        <Button
          href='/sign-in'
          className={layoutClasses.formButton}
          variant='outlined'
        >
          Sign In
        </Button>

        {/* CONTINUE/SIGN UP BUTTON */}
        <LoadingButton
          className={layoutClasses.formButton}
          loading={false}
          variant='contained'
          type='submit'
        >
          {selectedForm === 1 && 'Continue'}
          {selectedForm === 2 && 'Sign Up'}
        </LoadingButton>
      </Stack>}

      {/* AGREEMENT TEXT */}
      {selectedForm === 2 &&
      <Typography 
        variant='caption'
        className={layoutClasses.extraText}
      >
        By registering you have agreed to our<br/>
        <Link 
          underline='hover'
          href='/privacy-policy'
        >
          Privacy Policy
        </Link> and&nbsp;
        <Link 
          underline='hover'
          href='/terms-and-conditions'
        >
          Term & Conditions
        </Link>.
      </Typography>}
    </form>
  )
}

export default SignUp