//APIS
import axios, { axiosPrivate } from 'apis/axios'

export const deleteRejectJoinRequest = async (inputAndroidId, inputToken) => {
  try {
    const response = await axiosPrivate.delete(
      `/api/approve-join-request/${inputAndroidId}`,
      { 
        headers: {'Authorization': `Bearer ${inputToken}`} 
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getCheckUserEmail = async (inputEmail) => {
  try {
    const response = await axios.get(`/api/register/depo-owner-accounts/check-email/${inputEmail}`)
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getCheckUserPhoneNumber = async (inputPhoneNumber) => {
  try {
    const response = await axios.get(`/api/register/depo-owner-accounts/check-phone/${inputPhoneNumber}`)
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postApproveJoinRequest = async (inputBodyParams, inputToken) => {
  try {
    const response = await axiosPrivate.post(
      '/api/approve-join-request',
      inputBodyParams,
      { 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postAuthenticateUser = async (inputBodyParams) => {
  try {
    const response = await axios.post('/api/authenticate', inputBodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postForgotPassword = async (inputEmail) => {
  try {
    const response = await axios.post(`/api/forgot-password?email=${inputEmail}`)
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postRegisterUser = async (inputBodyParams) => {
  try {
    const response = await axios.post('/api/register/depo-owner-accounts', inputBodyParams)
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postResetPassword = async (inputBodyParams) => {
  try {
    const response = await axios.post('/api/reset-password',  inputBodyParams)
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postSendInvitation = async (inputBodyParams, inputToken) => {
  try {
    const response = await axiosPrivate.post(
      '/api/send-invitation', 
      inputBodyParams,
      { 
        headers: {'Authorization': `Bearer ${inputToken}`} 
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getRegisterResendEmail = async (inputEmail)=>{
  try {
    const response = await axios.get(`/api/register/resend/${inputEmail}`)
    return response
  } catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}