// MUIS
import { 
  IconButton, 
  Stack, 
  Typography 
} from '@mui/material'

// MUI ICON
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

// STYLES
import useStyles from '../reportUseStyles'

const AvailableReport = (props) => {
  const classes = useStyles()
  const { 
    isContentExpanded, 
    seIsContentExpanded,
    setIsCreatingMoves,
    setIsCreatingPayment,
    resetFleetMaangerList,
  } = props

  return (
    <Stack 
      sx={{
        width: isContentExpanded ? '240px' : '40px',
        transition: 'width 0.5s',
      }}
      padding={isContentExpanded ? '0px' : '20px 0px'}
      className={classes.generatedReportRoot}
    >
      {/* TITLE */}
      <Stack
        direction={isContentExpanded ? 'row' : 'column'}
        padding={isContentExpanded ? '20px 20px 0px 20px' : '0px'}
        alignItems='center'
        justifyContent='space-between'
      >
        { isContentExpanded && <Typography className={classes.typography1}>
          Available report
        </Typography>}

        <IconButton 
          onClick={() => seIsContentExpanded(current => !current)}
          size='small'
        >
          <KeyboardArrowLeftIcon
            sx={{
              transform: isContentExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.5s',
            }}
          />
        </IconButton>

        { !isContentExpanded && <Typography className={classes.verticalText}>
          Available report
        </Typography>}
      </Stack>

      {/* ACTIVITY */}
      {isContentExpanded && 
      <Stack
        height='44px'
        marginTop='10px'
        padding='10px 20px'
        sx={{backgroundColor: '#EEEEEE'}}
      >
        <Typography
          variant='subtitle2'
          sx={(theme) => ({color: theme.palette.text.secondary})}
        >
          Activity
        </Typography>
      </Stack>}

      {/* MOVES */}
      {isContentExpanded && 
      <Stack 
        className={classes.movesContainer}
        onClick={() => {
          setIsCreatingMoves(current => !current)
          setIsCreatingPayment(false)
          resetFleetMaangerList()
        }}
      >
        <Typography variant='caption'>
          Moves
        </Typography>
        <Typography className={classes.typography3}>
          Detail moves history
        </Typography>
      </Stack>}

      {/* PAYMENT REPORTS */}
      {isContentExpanded && 
      <Stack 
        className={classes.movesContainer}
        onClick={() => {
          setIsCreatingPayment(current => !current)
          setIsCreatingMoves(false)
          resetFleetMaangerList()
        }}
      >
        <Typography variant='caption'>
          Payment reports
        </Typography>
        <Typography className={classes.typography3}>
          Detail payment
        </Typography>
      </Stack>}
    </Stack>
  )
}

export default AvailableReport