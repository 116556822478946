import { useState, useContext } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'

// MUIS
import Button from '@mui/material/Button'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'

// SERVICES
import { postSendInvitation } from 'services/depoOwner'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'

// UTILITIES
import { signOutUser } from 'utilities/authentication'

const DialogAddOrEditDevice = () => {
  const layoutClasses = useLayoutStyles()

  const { 
    auth,
    setAuth,
    setSnackbarObject, 
  } = useContext(AllPagesContext)
  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)

  const [ email, setEmail ] = useState('')

  const handleActionButtonClick = async (inputType) => {
    setIsDialogAddOrEditOpen(false)

    if (inputType === 'cancel') setEmail('')
    else if (inputType === 'save') {
      const resultSendInvitation = await postSendInvitation({ email }, auth.accessToken)

      setEmail('')

      if (resultSendInvitation.status === 200) {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Success inviting the email',
        })
      }
      else if (resultSendInvitation.status === 403) signOutUser(setAuth)
    }
  }

  return (
    <DialogAddOrEdit>
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {/* CLOSE ICON */}
        <IconClose
          className={layoutClasses.dialogAddOrEditIconClose}
          onClick={() => setIsDialogAddOrEditOpen(false)}
        />

        {/* TITLE */}
        <Typography className='textSemiBold'>
          Invite a Device
        </Typography>
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* CONSGINEE FORM LABEL */}
        <Typography
          variant='subtitle2'
          className='formLabel'
        >
          Email
        </Typography>

        {/* CONSGINEE FORM */}
        <FormControl 
          variant='filled'
          hiddenLabel
          className='formTextField'
          color='secondary'
        >
          {/* CONSGINEE INPUT */}
          <FilledInput 
            placeholder='Answer'
            type='email'
            name='email'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormControl>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button 
          variant='outlined'
          onClick={() => handleActionButtonClick('cancel')}
        >
          Cancel
        </Button>

        {/* SAVE BUTTON */}
        <Button 
          variant='contained'
          onClick={() => handleActionButtonClick('save')}
        >
          Invite
        </Button>
      </CustomDialogActions>
    </DialogAddOrEdit>
  )
}

export default DialogAddOrEditDevice