import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import DialogAddOrEditDevice from './DialogAddOrEditDevice'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCancel from '@mui/icons-material/Cancel'
import IconCheckCircle from '@mui/icons-material/CheckCircle'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { 
  deleteRejectJoinRequest,
  postApproveJoinRequest, 
} from 'services/depoOwner'
import { postDepoWorkerAccountListSearch } from 'services/depoWorker'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './devicesUseStyles'

// UTILITIES
import { signOutUser } from 'utilities/authentication'
import { 
  convertDate,
  getThisWeekDateAndTime, 
} from 'utilities/date'

const Devices = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()

  const { 
    setAuth,
    auth,
    setSnackbarObject, 
  } = useContext(AllPagesContext)

  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)

  const handleDialogAddOrEditOpen = () => {
    setIsDialogAddOrEditOpen(true)
  }

  const initialColumns = [
    {
      field: 'androidId',
      headerName: 'Android ID',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'createdDate',
      headerName: 'Date',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => convertDate(params.value),
    },
    {
      field: 'accountStatus',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        let color
        if (params.value && params.value.toLowerCase() === 'active') color = 'success'
        else if (params.value && params.value.toLowerCase() === 'inactive') color = 'error'

        if (typeof params.id === 'number') return (
          <Chip
            label={params.value.toLowerCase()} 
            variant='outlined'
            color={color}
            size='small'
            className={layoutClasses.columnStatusChip}
          />
        )
      }
    },
    {
      field: 'actionsPermanent',
      headerName: 'Actions',
      flex: 1,
      minWidth: 225,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        if (typeof params.id === 'number' && params.row.accountStatus.toLowerCase() === 'inactive') {
          return (
            <Stack 
              direction='row'
              columnGap='8px'
            >
              {/* APPROVE BUTTON */}
              <Button
                variant='contained'
                startIcon={<IconCheckCircle/>}
                color='success'
                size='small'
                disableElevation
                onClick={() => handleActionButtonClick('approve', params.row)}
                className='textTransformNone'
              >
                Approve
              </Button>
  
              {/* REJECT BUTTON */}
              <Button
                variant='contained'
                startIcon={<IconCancel/>}
                color='error'
                size='small'
                disableElevation
                onClick={() => handleActionButtonClick('reject', params.row)}
                className='textTransformNone'
              >
                Reject
              </Button>
            </Stack>
          )
        }
        else if (typeof params.id === 'number' && params.row.accountStatus.toLowerCase() === 'active') {         
          return (
            <Stack 
              direction='row'
              columnGap='8px'
            >
              {/* EDIT BUTTON */}
              <Button
                variant='contained'
                startIcon={<IconEdit/>}
                color='info'
                size='small'
                disableElevation
                onClick={() => handleActionButtonClick('edit', params.row)}
                className={classes.columnActionsButtonEdit}
              >
                Edit
              </Button>
  
              {/* DELETE BUTTON */}
              <Button
                variant='contained'
                startIcon={<IconDelete/>}
                color='primary'
                size='small'
                disableElevation
                onClick={() => handleActionButtonClick('delete', params.row)}
                className={classes.columnActionsButtonDelete}
              >
                Delete
              </Button>
            </Stack>
          )
        }
      }
    },
  ]

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Status',
      value: 'accountStatus',
    },
  ]

  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState('id')
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen ] = useState(false)
  const [ dateRangeTimeValue, setDateRangeTimeValue ] = useState(getThisWeekDateAndTime())
  const [ tableData, setTableData ] = useState([])
  const [ dialogConfirmationObject, setDialogConfirmationObject ] = useState({})
  const [ mustReloadDataGrid, setMustReloadDataGrid ] = useState(true)
  const [ search, setSearch ] = useState('')

  const groupingColDef = {
    headerName: 'Status',
    renderCell: (params) => {
      let tempColor
      if (params.row.accountStatus && params.row.accountStatus.toLowerCase() === 'active') tempColor = 'success'
      else if (params.row.accountStatus && params.row.accountStatus.toLowerCase() === 'inactive') tempColor = 'error'
      if (!params.rowNode.isAutoGenerated) return (
        <Chip
          label={params?.row?.accountStatus?.toLowerCase()} 
          variant='outlined'
          color={tempColor}
          size='small'
          className={layoutClasses.columnStatusChip}
        />
      )

      return (
        <Typography 
          variant='subtitle1' 
          className={layoutClasses.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if(
      // TO DO: CHANGE THESE FIELDS LATER
      (selectedGroupBy.value && inputItem.field === 'androidId') ||
      (selectedGroupBy.value && inputItem.field === 'accountStatus')
    ) {}
    else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)
  }

  const handleActionButtonClick = (inputActionType, inputRowData) => {
    if (inputActionType === 'approve' || inputActionType === 'reject' || inputActionType === 'delete') {
      let confirmationTitle

      if (inputActionType === 'approve') confirmationTitle = 'Approve confirmation'
      else if (inputActionType === 'reject') confirmationTitle = 'Reject confirmation'
      else if (inputActionType === 'delete') confirmationTitle = 'Delete confirmation'

      setDialogConfirmationObject({
        type: inputActionType,
        confirmationTitle: confirmationTitle,
        confirmationCaption: inputRowData.fullName,
        ...inputRowData,
      })
    }
    else if (inputActionType === 'edit') {}
  }

  const reloadDepoWorkerAccountListData = async () => {
    let isMounted = true
    const abortController = new AbortController()

    await loadDepoWorkerAccountListData(isMounted, abortController)

    isMounted = false
    abortController.abort()
  }

  const handleConfirmationContinueButtonClick = async () => {
    let resultJoinRequest

    // APPROVING THE DEVICE
    if (dialogConfirmationObject.type === 'approve') {
      resultJoinRequest = await postApproveJoinRequest(
        {androidId: dialogConfirmationObject.androidId}, 
        auth.accessToken)
    }
    // REJECTING/DELETING THE DEVICE
    else if (dialogConfirmationObject.type === 'reject' || dialogConfirmationObject.type === 'delete') {
      resultJoinRequest = await deleteRejectJoinRequest(dialogConfirmationObject.androidId, auth.accessToken)
    }

    // SHOWING A SUCCESS SNACKBAR AFTER SUCCESSFULLY APPROVING/REJECTING/DELETING THE DEVICE
    if (
      (dialogConfirmationObject.type === 'approve' && resultJoinRequest.status === 200) ||
      (dialogConfirmationObject.type === 'reject' && resultJoinRequest.status === 204) ||
      (dialogConfirmationObject.type === 'delete' && resultJoinRequest.status === 204)
    ) {
      let message

      if (dialogConfirmationObject.type === 'approve') message = 'Success approving a device'
      else if (dialogConfirmationObject.type === 'reject') message = 'Success rejecting a device'
      else if (dialogConfirmationObject.type === 'delete') message = 'Success deleting a device'

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: message,
      })

      setDialogConfirmationObject({})

      reloadDepoWorkerAccountListData()
    }
    else if (resultJoinRequest.status === 403) signOutUser(setAuth)
    // TO DO: HANDLE MORE ERROR 40X AND 50X FROM CALLING THE API HERE
  }

  const loadDepoWorkerAccountListData = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const newFilters = reject(isEmpty, filters)

    const resultDepoWorkerAccountListData = await postDepoWorkerAccountListSearch(
      inputAbortController.signal, 
      {
        page: pageNumber,
        size: pageSize,
        sort: order && orderBy ? `${orderBy},${order}` : 'id,desc',
      },
      {
        globalSearch: search,
        ...newFilters,
      },
      auth.accessToken
    )

    if (resultDepoWorkerAccountListData.status === 200 && inputIsMounted) {
      setTotal(resultDepoWorkerAccountListData.data.totalElements)
      setTableData(resultDepoWorkerAccountListData.data.rows)
    }

    setIsDataGridLoading(false)
    setMustReloadDataGrid(false)
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    mustReloadDataGrid && loadDepoWorkerAccountListData(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filters, search, pageSize, pageNumber, order, orderBy])

  useEffect(() => {
    setMustReloadDataGrid(true)
  }, [filters, search, pageSize, pageNumber, order, orderBy])

  // HANDLE DATA GRID COLUMNS WHILE GROUPING 
  useEffect(() => {
    if(selectedGroupBy.value) {
  
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if(item.field === selectedGroupBy.value) item.hide = true
        else item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
  
    } else {
  
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  
  }, [selectedGroupBy])

  return (
    <>
      {/* APPBAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Devices'
        hasFab={true}
        fabOnClickButton={handleDialogAddOrEditOpen}
      />

      {/* CONTENT */}
      <LoadingPaper isLoading={isDataGridLoading}>
        <DataGridFilters
          // COLUMN
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          // FILTER
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          // GROUP BY ROW
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
          // DATE RANGE TIME
          dateRangeValue={dateRangeTimeValue}
          isDateRangeTimePickerOpen={isDateRangeTimePickerOpen} 
          setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
          handleSelectDateRangePickerButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelDateRangePickerButtonClick={() => setIsDateRangeTimePickerOpen(false)}
          // DOWNLOAD
          isDownloadEnabled={selectionModel.length > 0}
          handleDownloadButtonClick={() => console.log('download')}
        />

        <DataGridTable
          // BASE
          initialColumns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          rows={tableData}
          // PAGINATION
          total={total}
          page={pageNumber}
          pageSize={pageSize}
          // ORDER
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPageNumber}
          setPageSize={setPageSize}
          // FILTER
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          // GROUP BY ROW
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [ row.accountStatus, row.id ]}
          groupingColDef={groupingColDef}
          // SELECTION
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
        />
      </LoadingPaper>

      {/* DIALOG ADD OR EDIT DEVICE */}
      <DialogAddOrEditDevice/>

      {/* DIALOG CONFIRMATION */}
      <DialogConfirmation
        title={dialogConfirmationObject && dialogConfirmationObject.confirmationTitle}
        caption={dialogConfirmationObject && dialogConfirmationObject.confirmationCaption}
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        cancelButtonText='Cancel'
        continueButtonText={dialogConfirmationObject ? dialogConfirmationObject.type : '-'}
        onContinueButtonClick={handleConfirmationContinueButtonClick}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
      />
    </>
  )
}

export default Devices