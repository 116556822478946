// MUIS
import TextField from '@mui/material/TextField'

// MUI X DATA GRIDS
import { useGridApiContext } from '@mui/x-data-grid'

// MUI X DATE PICKERS
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const CellEditDateAndTimePicker = (props) => {
  const { 
    id, 
    value, 
    field, 
    type, 
    ...restOfProps
  } = props

  const apiContextRef = useGridApiContext()

  let Component
  if (type === 'datePicker') Component = DatePicker
  else if (type === 'dateAndTimePicker') Component = DateTimePicker

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Component
        {...restOfProps}
        renderInput={(props) => <TextField {...props} />}
        value={value}
        onChange={(newValue) => apiContextRef.current.setEditCellValue({
          id, 
          field, 
          value: newValue,
        })}
      />
    </LocalizationProvider>
  )
}

export default CellEditDateAndTimePicker