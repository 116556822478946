//APIS
import { axiosPrivate } from 'apis/axios'

export const getDepoFleetManagerList = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      `/api/depo-fleet?size=${inputQueryParams}`,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const createNewItem = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.post(
      '/api/items',
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const editItem = async (inputSignal, inputToken, inputBodyParams, inputQueryParams) => {
  try {
    const response = await axiosPrivate.put(
      `/api/items?id=${inputQueryParams}`,
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const deleteItems = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axiosPrivate.delete(
      '/api/items',
      { 
        headers: {'Authorization': `Bearer ${inputToken}`},
        signal: inputSignal, 
        data: inputBodyParams,
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getDepoItemList = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      'api/items',
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}


export const searchDepoItemList = async (inputSignal, inputToken, inputQueryParams, inputBodyParams) => {
  try {
    const response = await axiosPrivate.post(
      `/api/items/search?page=${inputQueryParams.page}&size=${inputQueryParams.size}`,
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getFixedItemName = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      'api/item-type?page=0&size=100',
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}