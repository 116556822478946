import React from 'react'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUIS ICON
import FileUploadIcon from '@mui/icons-material/FileUpload'
import LockIcon from '@mui/icons-material/Lock'

// STYLES
import useStyles from '../profileUseStyles'

const PhotoProfile = (props) => {
  const classes = useStyles()

  const { photoProfile,
    setDialogConfirmationObject,
  } = props

  return (
    <Stack 
      width={200} 
      marginRight='40PX' 
      direction='column'
    >
      {/* PHOTO PROFILE */}
      <Box 
        component='img'
        src={photoProfile}
        className={classes.photoProfile}
      />

      {/* CAPTION */}
      <Typography className={classes.caption} variant='caption'>
        File size: maximum 10,000,000 bytes (10 Megabytes). Allowed file extensions: .JPG .JPEG .PNG
      </Typography>

      {/* UPLOAD IMAGE */}
      <Stack 
        className={classes.uploadButton} 
        component='label' 
        direction='row'
        marginTop='10px'
        alignItems='center'
        onChange={(e)=> console.log(e)}
      >
        <FileUploadIcon className={classes.iconButton}/>
        <Typography className={classes.textButton}>
          UPLOAD LOGO
        </Typography>
        <input hidden accept='image/*' multiple type='file' />
      </Stack>

      {/* CHANGE PASSWORD */}
      <Stack 
        className={classes.uploadButton} 
        component='label' 
        direction='row'
        marginTop='10px'
        alignItems='center'
        onClick={(e) => setDialogConfirmationObject(e)}
      >
        <LockIcon className={classes.iconButton}/>
        <Typography className={classes.textButton}>
          CHANGE PASSWORD
        </Typography>
      </Stack>
    </Stack>
  )
}

export default PhotoProfile