import { useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheck from '@mui/icons-material/Check'

// SERVICES
import { getRegisterResendEmail } from 'services/depoOwner'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

const FormFinish = (props) => {
  const { form } = props

  const layoutClasses = useLayoutStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const handleLinkClick = async () =>{
    // RE-SENDING THE EMAIL
    const resultResendEmail = await getRegisterResendEmail(form.email)

    // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY RE-SENDING THE CODE
    if(resultResendEmail.status === 200){
      setSnackbarObject({
        open:true,
        severity:'success',
        title:'',
        message: `Successfully resend activation code to ${form.email}`
      })
    } 
    // SHOW ERROR MESSAGE AFTER FAILED TO RE-SENDING THE CODE
    else{  
      let errorMessage = ''

      if(resultResendEmail.status==='No server Response') errorMessage = 'No Server Response' 
      else errorMessage = `Error ${resultResendEmail.data.status}: ${resultResendEmail.data.detail}`
      
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: 'Failed to resend the activation code',
        message: errorMessage
      })
    }
  }

  return (
    <Stack alignItems='center'>
      {/* SUCCESS ICON */}
      <Avatar 
        variant='rounded'
        className={`${layoutClasses.infoAvatar} ${layoutClasses.infoItem}`}
      >
        <IconCheck className={layoutClasses.infoAvatarIcon}/>
      </Avatar>

      {/* SENT TEXT */}
      <Typography
        variant='subtitle1'
        className={layoutClasses.infoItem}
      >
        We have sent an activation link to<br/>
        <span className='textSemiBold'>
          {form.email}
        </span>
      </Typography>

      {/* SIGN IN BUTTON */}
      <Button
        href='/sign-in'
        variant='contained'
        className={layoutClasses.infoItem}
        size='large'
        fullWidth
      >
        Sign In
      </Button>

      {/* CLICK EMAIL TEXT */}
      <Typography
        variant='body2'
        className={layoutClasses.infoItem}
      >
        Click the link from your email to activate<br/>
        your account
      </Typography>

      {/* RESEND TEXT */}
      <Typography
        variant='body2'
        className={layoutClasses.infoItem}
      >
        Didn't receive the link?&nbsp;
        <Link
          href='#'
          onClick={handleLinkClick}
          underline='hover'
        >
          RESEND
        </Link>
      </Typography>
    </Stack>
  )
}

export default FormFinish