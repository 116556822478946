// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  statusFilterContainer: {
    padding: '16px 24px',
    borderBottom: '1px solid #0000001F',
    alignItems: 'center'
  },
  filterStatusText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginRight: 24,
  },
  listStatusContainer: {
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 33,
    padding: '10px 20px',
    cursor: 'pointer',
    marginRight: 8,
  },
  formIcon: {
    color: '#00000099',
    marginRight: 12,
    marginTop: 12,
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500,
  },
  typography2: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  typography3: {
    fontSize: 14,
    fontWeight: 500,
  },
  typography4: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  typography5: {
    fontSize: 18,
    fontWeight: 600,
  },
  priceDetailContainer: {
    padding: 20,
    backgroundColor: '#35B56514',
    border: '1px solid #35B565',
    borderRadius: 4,
    marginTop :20,
  },
  dotIcon: {
    width: 8,
    height: 8,
    color: theme.palette.text.secondary
  },
  paymentDetailRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    boxShadow: '0px 4px 40px 0px #0000000D',
    width: 478,
  },
  checklistIcon : {
    width: 84,
    height: 84,
    marginBottom: 30,
    color: theme.palette.success.main,
  },
  orderFormItemName: {
    fontSize: 14,
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zoom: 0.85
  },
  orderFormDesc: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zoom: 0.85
  },
  fieldNumber : {
    '& .MuiInputBase-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }
  },
}))

export default useStyles