import { useContext } from 'react'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import {
  Stack, 
  Typography,
  Button,
} from '@mui/material'

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit'

// STYLES 
import useStyles from '../../orderUseStyles'

const ReviewContact = (props) => {
  const { setIsEditingContact } = props
  const classes = useStyles()
  const {orderForms} = useContext(PrivateLayoutContext)
  
  return (
    <>
      {/* TITLE */}
      <Typography className={classes.typography1}>
        Contact
      </Typography>

      {/* NAME */}
      <Stack
        marginTop='20px'
        direction='row'
        height='72px'
        justifyContent='space-between'
        alignItems='center'
        borderTop='1px solid #0000001F'
        borderBottom='1px solid #0000001F'
      >
        <Typography className={classes.typography3}>
          {orderForms?.name}
        </Typography>

        <Button 
          startIcon={<EditIcon/>}
          variant='outlined'
          onClick={() => setIsEditingContact(true)}
        >
          Edit
        </Button>
      </Stack>

      {/* PHONE NUMBER */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          Phone Number
        </Typography>

        <Typography className={classes.typography3}>
          {orderForms?.phoneNumber}
        </Typography>
      </Stack>

      {/* EMAIL */}
      <Stack marginTop='20px'>
        <Typography className={classes.typography4}>
          Email
        </Typography>

        <Typography className={classes.typography3}>
          {orderForms?.email}
        </Typography>
      </Stack>
    </>
  )
}

export default ReviewContact