// VALUES
import { values } from 'constants/values'

import { createContext, useState, useRef } from 'react'

const PrivateLayoutContext = createContext()

const PrivateLayoutContextProvider = (props) => {
  // DIALOG ADD OR EDIT
  const pageRef = useRef()
  const [ isDialogAddOrEditOpen, setIsDialogAddOrEditOpen ] = useState(false)

  // DRAWER
  const [ isDrawerExpanded, setIsDrawerExpanded ] = useState(true) // BOOLEAN

  // ORDERS
  const [orderForms, setOrderForms] = useState(values?.initialOrderForms)
  const [orderListItems, setOrderListItems] = useState(values?.initialOrderListItems)

  return (
    <PrivateLayoutContext.Provider
      value={{
        // APP BAR
        isDialogAddOrEditOpen, setIsDialogAddOrEditOpen,
        // DRAWER
        isDrawerExpanded, setIsDrawerExpanded,
        // LAYOUT
        pageRef,
        // ORDERS
        orderForms, setOrderForms,
        orderListItems, setOrderListItems
      }}
    >
      {props.children}
    </PrivateLayoutContext.Provider>
  )
}

export { PrivateLayoutContextProvider, PrivateLayoutContext }