import { useState, useContext } from 'react'

// ASSETS
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect.svg'

//COMPONENTS
import ForgotPasswordFormFinal from './ForgotPasswordFormFinal'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI LABS
import LoadingButton from '@mui/lab/LoadingButton'

//SERVICES
import { postForgotPassword } from 'services/depoOwner'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

// UTILITIES
import { getDefaultErrorMessage } from 'utilities/string'

const ForgotPassword = () => {
  const layoutClasses = useLayoutStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)
  const [ selectedForm, setSelectedForm ] = useState(1)
  const [ email, setEmail ] = useState('')

  const handleFormButtonClick = async (inputEvent) => {
    inputEvent.preventDefault()
    if (selectedForm === 1) {
      const resultForgotPassword = await postForgotPassword(email)

      // SHOW SUCCESS MESSAGE AFTER ABLE TO FIND EMAIL
      if (resultForgotPassword.status === 200) {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully send request',
        })
        setSelectedForm(2)
      }
      // SHOW ERROR MESSAGE AFTER FAILED TO FIND EMAIL
      else {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Email is not registered!',
          message: getDefaultErrorMessage(resultForgotPassword),
        })
      }
    }
  }

  return (
    <form 
      className={layoutClasses.root}
      onSubmit={handleFormButtonClick}
    >
      {/* FIRST FORM */}
      {selectedForm === 1 && 
      <>
        {/* PRODUCT LOGO */}
        <Box
          component='img'
          src={LogoProductNleConnet}
          alt=''
          className={layoutClasses.productLogo}
        />

        {/* TITLE */}
        <Typography 
          variant='h6'
          className={layoutClasses.pageTitle}
        >
          Forgot Password
        </Typography>
        
        {/* CAPTION */}
        <Typography
          className={layoutClasses.pageTitle}
          variant='caption'
        >
          Password change notification will be sent to your email!
        </Typography>

        {/* EMAIL FORM */}
        <FormControl 
          variant='standard'
          className='formTextField'
          color='secondary'
        >
          {/* EMAIL INPUT LABEL */}
          <InputLabel>
            Company Email
          </InputLabel>

          {/* EMAIL INPUT */}
          <Input 
            autoFocus
            type='email'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          {/* EMAIL HELPER TEXT */}
          <FormHelperText error>
            Email is required!
          </FormHelperText>
        </FormControl>
      </>}

      {/* FINAL FORM */}
      {selectedForm === 2 &&
      <ForgotPasswordFormFinal email={email}/>}
      
      {/* ACTION BUTTONS */}
      {selectedForm === 1 &&       
      <Stack       
        direction='row'
        spacing='12px'
        className={layoutClasses.formButtonContainer}
      >   
        {/* SIGN IN BUTTON*/}
        <Button
          variant='outlined'
          className={layoutClasses.formButton}
          href='/sign-in'
        >
          Sign In
        </Button>

        {/* RESET BUTTON */}
        <LoadingButton
          variant='contained'
          className={layoutClasses.formButton}
          type='submit'
        >
          Reset
        </LoadingButton>
      </Stack>}
    </form>
  )
  
}

export default ForgotPassword