// MUIS
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheck from '@mui/icons-material/Check'

// SERVICES
import { postForgotPassword } from 'services/depoOwner'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

const ForgotPasswordFormFinal = (props) => {
  const { email } = props

  const layoutClasses = useLayoutStyles()

  const handleOpenEmailClick = () => {
    const newWindow = window.open(
      `https://mail.google.com/mail/u/${email}`,
      '_blank',
      'noopener,noreferrer'
    )

    if (newWindow) newWindow.opener = null
  }

  return (
    <Stack alignItems='center'>
      {/* SUCCESS ICON */}
      <Avatar 
        variant='rounded'
        className={`${layoutClasses.infoAvatar} ${layoutClasses.infoItem}`}
      >
        <IconCheck className={layoutClasses.infoAvatarIcon}/>
      </Avatar>

      {/* SENT TEXT */}
      <Typography
        variant='subtitle1'
        className={layoutClasses.infoItem}
      >
        We have sent a reset password link to {' '}
        <span className='textSemiBold'>
          {email}
        </span>
      </Typography>


      {/* OPEN EMAIL BUTTON */}
      <Button
        onClick={() => handleOpenEmailClick()}
        variant='contained'
        className={layoutClasses.infoItem}
        size='large'
        fullWidth
      >
        Open Email
      </Button>

      {/* CLICK EMAIL TEXT */}
      <Typography
        variant='body2'
        className={layoutClasses.infoItem}
      >
        Click the link in your email to change your account password
      </Typography>

      {/* RESEND TEXT */}
      <Typography
        variant='body2'
        className={layoutClasses.infoItem}
      >
        Didn't receive the link?&nbsp;
        <Link
          underline='hover'
          href='#'
          onClick={() => postForgotPassword(email) }
        >
          RESEND
        </Link>
      </Typography>
    </Stack>
  )
}

export default ForgotPasswordFormFinal