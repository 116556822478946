const reportDummy = [
  {
    id: 1,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 2,
    date: '15 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 3,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate out',
    fleetManager: 'KTMC',
    depo: 'Jakarta',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 4,
    date: '15 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'SINU',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 5,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 6,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 7,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 8,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 9,
    date: '14 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
  {
    id: 10,
    date: '16 Jul 2022',
    time: '10:26:00',
    type: 'Gate in',
    fleetManager: 'CMA-CGM',
    depo: 'Semarang',
    containerNo: 'TRHU7857252',
    isoCode: '45G1',
    tare: 2765,
    payload: 28420,
    maxGross: 2419,
  },
]

export default reportDummy