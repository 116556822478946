//APIS
import { axiosPrivate } from 'apis/axios'

// QUERY
import { stringify } from 'query-string'

export const getMoveStatisticSCountDuration = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      `/api/gate-moves/count-duration?duration=${inputQueryParams}`,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getFleetManagerCountDuration = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axiosPrivate.get(
      `/api/gate-moves/count-duration/fleet-manager?duration=${inputQueryParams}`,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postGateMoveListSearch = async (
  inputSignal, 
  inputRequestParams,
  inputBodyParams, 
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      `/api/gate-moves/search?${stringify(inputRequestParams)}`,
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putUpdateGateMoveItem = async (
  inputSignal,
  inputParams,
  inputToken
) => {
  try {
    const response = await axiosPrivate.put(
      '/api/gate-moves',
      inputParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postCreateNewGateMove = async (
  inputSignal,
  inputBodyParams, 
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      '/api/gate-moves',
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postCreateMultipleGateMove = async (
  inputSignal,
  inputBodyParams, 
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      '/api/gate-moves/multiple',
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}