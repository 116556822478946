// MUI STYLES
import { makeStyles } from '@mui/styles'
import { borderRadius } from '@mui/system'

const useStyles = makeStyles((theme) => ({
  // LEFT CONTENT
  mainContent: {
    padding: 20,
    flexDirection: 'row'
  },
  photoProfile: {
    width: 200,
    height: 200,
    borderRadius: 4,
    border: `1px solid ${theme.palette.text.disabled}`,
    marginBottom: 10,
  },
  caption: {
    color: theme.palette.text.disabled
  },
  uploadButton: {
    width: '100%',
    border: `1px solid ${theme.palette.text.disabled}`,
    padding: '5px 12px',
    cursor: 'pointer',
    borderRadius: 4,
    color: theme.palette.text.disabled
  },
  iconButton: {
    marginRight: 12,
  },
  textButton: {
    fontSize: 13,
    fontWeight: 500,
  },

  // RIGHT CONTENT COMPANY INFORMATION
  tableContainer: {
    marginTop: 10,
    height: 300,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius:4,
  },
  dataGrid: {
    '& .MuiDataGrid-cell:focus' : {
      outline : 'none'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer:focus' : {
      outline : 'none'
    },
    '& .MuiDataGrid-columnSeparator' : {
      display: 'none'
    }
  },
  imageIcon: {
    color: theme.palette.text.disabled,
    textTransform: 'none'
  },

  // RIGHT CONTENT EDIT PROFILE
}))

export default useStyles