import React from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'

// SERVICE
import { editUserProfile } from 'services/profile'
import { useContext } from 'react'

const EditProfile = (props) => {
  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const {
    updateProfileForm,
    setUpdateProfileForm,
    setIsEditProfile,
    reloadData,
  } = props

  const listForm = [
    {
      label: 'Person in charge',
      type: 'text',
      value: '',
      name: 'personInCharge'
    },
    {
      label: 'Organization name',
      type: 'text',
      value: '',
      name: 'organizationName'
    },
    // {
    //   label: 'Company email',
    //   type: 'email',
    //   value: '',
    //   name: 'email'
    // },
    {
      label: 'Phone number',
      type: 'number',
      value: '',
      name: 'phoneNumber'
    },
    {
      label: 'Alamat',
      type: 'text',
      value: '',
      name: 'address'
    },
    // {
    //   label: 'NPWP number',
    //   type: 'text',
    //   value: '',
    //   name: 'npwpNumber'
    // },
    // {
    //   label: 'SIUB number',
    //   type: 'number',
    //   value: '',
    //   name: 'siubNumber'
    // },
    // {
    //   label: 'NIB number',
    //   type: 'number',
    //   value: '',
    //   name: 'nibNumber'
    // },
    // {
    //   label: 'KTA ASDEKI',
    //   type: 'number',
    //   value: '',
    //   name: 'ktaAsdeki'
    // },
  ]

  const handleUpdateProfileFormChange = (event) => {
    setUpdateProfileForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSaveButton = async () => {
    const abortController = new AbortController()

    const bodyParams = {
      phoneNumber: updateProfileForm.phoneNumber,
      fullName : updateProfileForm.personInCharge,
      organizationName: updateProfileForm.organizationName,
      address: updateProfileForm.address
    }
    
    const resultUpdateProfile = await editUserProfile(abortController.signal, auth.accessToken, bodyParams)

    if(resultUpdateProfile.status === 200) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully edit the profile',
      })
      reloadData(true, abortController.signal)
      setIsEditProfile(false)
    } 

    else if ( resultUpdateProfile.status !== 200) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed edit the profile',
      })
    }

    abortController.abort()
  }
  
  return (
    <>
      <Stack width='100%'>
        {/* PROFIL INFORMATION */}
        <Stack>
          {/* TITLE */}
          <Stack 
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            marginBottom='20px'
          >
            <Typography variant='h6'>
              Profil information
            </Typography>

            {/* BUTTON */}
            <Stack direction='row' spacing={2}>
              <Button 
                variant='outlined'
                onClick={() => setIsEditProfile(false)}
              >
                Cancel
              </Button>

              <Button 
                variant='outlined'
                onClick={() => handleSaveButton()}
              >
                Save
              </Button>
            </Stack>
          </Stack>

          {/* FORMS */}
          {listForm.map((item, index) => (
            <Stack 
              key={index}
              marginBottom='10px'
            >
              <TextField 
                label={item.label} 
                variant='standard' 
                type={item.type}
                value={updateProfileForm[item.name]}
                name={item.name}
                onChange={handleUpdateProfileFormChange}
              />
            </Stack>
          ))}
          
        </Stack>
      </Stack>
    </>
  )
}

export default EditProfile