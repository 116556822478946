import { useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContextProvider } from 'contexts/PrivateLayoutContext'

// LAYOUT
import Private from 'layouts/Private/Private'

// UTILITIES
import { isObjectEmpty } from 'utilities/validation'

function PrivateRoute(props) {
  const { children } = props

  const { auth } = useContext(AllPagesContext)

  return (
    isObjectEmpty(auth) ?
      <>
        {window.open(`${process.env.REACT_APP_API_LANDING_URL}`, '_self')}
      </> :
      <PrivateLayoutContextProvider>
        <Private>   
          {children}
        </Private> 
      </PrivateLayoutContextProvider>
  )
}

export default PrivateRoute
