import React from 'react'

// MUIS
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Box'

// MUI ICON
import CheckIcon from '@mui/icons-material/Check'

// STYLES
import useStyles from './moveStatisticUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const MoveStatistic = (props) => {
  const { listButtonMS, 
    handleListButtonClick,
    totalMoves,
  } = props
  const classes = useStyles()
  return (
    <Box>
      {/* TITLE */}
      <Typography variant='body2'>
        Total Moves
      </Typography>

      <Stack 
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        {/* TOTAL MOVES */}
        <Typography className={classes.totalMove}>
          {addSeparatorsForNumber(totalMoves)}
        </Typography>

        <Stack direction='row'>
          {listButtonMS.map((item, index) => (
            <ListItem 
              key={index}
              className={classes.listItem}
              sx={{backgroundColor : item.isClicked ? '#F9664914' : 'transparent'}}
            >
              <ListItemButton
                className={classes.listItemButton} 
                onClick={() => handleListButtonClick(item)}>
                {/* CHECKLIST ICON */}
                {item.isClicked && <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon/>
                </ListItemIcon>}
                  
                {/* TEXT BUTTON */}
                <ListItemText 
                  className={classes.textButton}
                  sx={(theme) => ({color : item.isClicked ? theme.palette.primary.main : 'black'})}
                >
                  {item.day}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </Stack>

      </Stack>
    </Box>
  )
}

export default MoveStatistic