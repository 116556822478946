import React, { useContext, useState } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'
import FinishOrder from './FinishOrder/FinishOrder'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import OrderForm from './OrderForms/OrderForm'
import ReviewOrders from './ReviewOrders/ReviewOrders'

// CONSTANT
import { values } from 'constants/values'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'

// MOMENT
import moment from 'moment'

// MUIS
import {
  Button,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography,
  IconButton,
} from '@mui/material'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'

// SERVICE
import { createNewUnloadingOrder, createNewLoadingOrder, xenditPayment } from 'services/order'

const DialogAddNewOrders = ({ reloadData }) => {
  const {
    setIsDialogAddOrEditOpen,
    orderListItems, setOrderListItems,
    orderForms, setOrderForms,
  } = useContext(PrivateLayoutContext)

  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  let initialListContainer = [
    {
      containerNo: '',
      containerType: '',
      id: 1,
    }
  ]

  const listStep = ['Order', 'Checkout', 'Payment', 'Finish']

  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isValidatingForms, setIsValidatingForms] = useState(false)

  // HANDLE TOTAL PRICE
  let totalPrice = 0
  for (let i = 0; i < orderListItems.length; i++) {
    totalPrice += orderListItems[i].price * orderListItems[i].quantity
  }

  // GET DIALOG CONTENT
  const getDialogContent = () => {
    if (activeStep === 0) return <OrderForm
      setIsLoading={setIsLoading}
      isValidatingForms={isValidatingForms}
    />
    else if (activeStep === 1) return <ReviewOrders />
    else if (activeStep === 2) return <></>
    else if (activeStep === 3) return <FinishOrder />
  }

  // GET CONTINE BUTTON TEXT
  const getContinueButtonText = () => {
    if (activeStep === 0) return 'Next'
    else if (activeStep === 1) return 'Pay Now'
    else if (activeStep === 3) return 'Done'
    else return 'Next'
  }

  // FUNCTION HANDLE SELECT PAYMENT METHOD API
  const handleXenditPaymentAPI = async (inputBookingOrderID) => {
    setIsLoading(true)
    const abortController = new AbortController()

    const bodyParams = {
      depo_id: auth?.id,
      booking_header_id: inputBookingOrderID,
      bank_code: orderForms?.paymentMethod?.value,
      name: orderForms?.name,
      phone_number: orderForms?.phoneNumber,
      expected_amount: totalPrice
    }

    const resultXenditPayment = await xenditPayment(abortController.signal, bodyParams, auth.accessToken)

    if (resultXenditPayment.status === 200) {
      // OPEN XENDIT URL
      window.open(`${resultXenditPayment?.data?.invoice_url}`, '_blank', 'noopener')

      // SHOW SNACKBAR
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully create the order',
      })

      // RELOAD TABLE DATA
      reloadData(true, abortController.signal)

      // MOVE TO FINISH PAGE
      setActiveStep(3)

      setIsLoading(false)
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to create the order',
      })

      setIsLoading(false)
    }
  }

  // HANDLE FORMS VALIDATION
  const handleFormsValidation = () => {
    // CHECK IS ITEMS CHOOSED & CHECK CONTAINER NUMBER FIELD
    let isItemChoosed = true
    orderListItems.forEach((item) => {
      if ((orderForms.booking.label === 'Unloading' && item.containerNo === '') || !item.value) isItemChoosed = false
    })

    // VALIDATING ALL FORMS
    if (
      orderForms.name === '' || orderForms.phoneNumber === '' ||
      orderForms.email === '' || !orderForms.date ||
      orderForms.bolNo === '' || orderForms.consignee === '' ||
      orderForms.npwp === '' || orderForms.npwpAddress === '' ||
      orderForms.paymentMethod === '' || !isItemChoosed
    ) {
      setIsValidatingForms(true)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Required fields must be filled in',
      })
    } else {
      setIsValidatingForms(false)
      setActiveStep(1)
    }
  }

  // HANDLE ACTIVE STEP (FOOTER BUTTON CLICK)
  const handleActiveStep = async () => {
    const abortController = new AbortController()

    // PUSH FROM ORDER TO CHECKOUT
    if (activeStep === 0) handleFormsValidation()

    // PROCESS ORDER / CALL API
    else if (activeStep === 1) {
      let bodyParams = {
        depo_id: auth?.id,
        tx_date: moment(orderForms?.date).format('YYYY-MM-DDTHH:mm:ss.00Z'),
        full_name: orderForms?.name,
        phone_number: orderForms?.phoneNumber,
        email: orderForms?.email,
        bill_landing: orderForms?.bolNo,
        consignee: orderForms?.consignee,
        npwp: orderForms?.npwp,
        npwp_address: orderForms?.npwpAddress,
      }

      // WHILE BOOKING TYPE = UNLOADING
      if (orderForms?.booking?.label === 'Unloading') {
        // SET DETAIL REQUEST
        bodyParams.detailRequests = orderListItems.map((item) => {
          return {
            itemId: item?.value?.id,
            price: item?.value?.price,
            container_number: item?.containerNo
          }
        })

        // CALL CREATE NEW UNLOADING ORDER API
        const resultCreateNewUnloadingOrder = await createNewUnloadingOrder(abortController.signal, auth.accessToken, bodyParams)

        if (resultCreateNewUnloadingOrder.status === 200) {
          // CALL XENDIT PAYMENT API
          handleXenditPaymentAPI(resultCreateNewUnloadingOrder?.data?.id)
        } else {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: '',
            message: 'Failed to create the unloading order',
          })
        }
      }

      // WHILE BOOKING TYPE = LOADING
      else if (orderForms?.booking?.label === 'Loading') {
        // SET DETAIL REQUEST
        bodyParams.detailRequests = orderListItems.map((item) => {
          return {
            itemId: item?.value?.id,
            price: item?.price,
            quantity: item?.quantity
          }
        })

        // CALL CREATE LOADING ORDER API
        const resultCreateNewLoadingOrder = await createNewLoadingOrder(abortController.signal, auth.accessToken, bodyParams)

        if (resultCreateNewLoadingOrder.status === 200) {
          // CALL XENDIT PAYMENT API
          handleXenditPaymentAPI(resultCreateNewLoadingOrder?.data?.id)
        } else {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: '',
            message: 'Failed to create the loading order',
          })
        }
      }
    }

    // CLOSE DIALOG & CLEAR STATES
    else if (activeStep === 3) {
      setOrderListItems(initialListContainer)
      setOrderForms(values.initialOrderForms)
      setActiveStep(0)
      setIsDialogAddOrEditOpen(false)
    }
  }

  return (
    <DialogAddOrEdit containerWidth='704px'>
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {/* TITLE */}
        <Stack
          direction='row'
          margin='12px 20px 0px'
          alignItems='center'
          spacing={1}
        >
          {/* CLOSE ICON */}
          <IconButton onClick={() => {
            setOrderListItems(initialListContainer)
            setOrderForms(values.initialOrderForms)
            setActiveStep(0)
            setIsDialogAddOrEditOpen(false)
            setIsValidatingForms(false)
          }}>
            <IconClose />
          </IconButton>

          {/* TITLE */}
          <Typography variant='h6'>
            Add New Order
          </Typography>
        </Stack>
      </CustomDialogTitle>

      {/* STEPPER */}
      <Stack
        padding='30px 40px 40px'
        borderBottom='1px solid #0000001F'
      >
        <Stepper activeStep={activeStep}>
          {listStep.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>

      {/* DIALOG CONTENT */}
      <CustomDialogContent sx={{ padding: '28px 40px!important' }}>
        {getDialogContent()}
      </CustomDialogContent>

      {/* DIALOG ACTION */}
      <CustomDialogActions>
        <Stack
          direction='row'
          spacing={2}
        >
          {/* CANCEL BUTTON */}
          {activeStep !== 3 &&
            <Button
              variant='outlined'
              onClick={() => {
                setOrderListItems(initialListContainer)
                setOrderForms(values.initialOrderForms)
                setActiveStep(0)
                setIsDialogAddOrEditOpen(false)
              }}
            >
              Cancel
            </Button>
          }

          {/* SAVE BUTTON */}
          <Button
            variant='contained'
            onClick={handleActiveStep}
          >
            {getContinueButtonText()}
          </Button>
        </Stack>
      </CustomDialogActions>

      {/* LOADING COOMPONENT */}
      <LoadingComponent isLoading={isLoading} />
    </DialogAddOrEdit>
  )
}

export default DialogAddNewOrders