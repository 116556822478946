import React from 'react'

// MUIS
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './loadingComponentUseStyles'

const LoadingComponent = (props) => {
  const { isLoading } = props
  const classes = useStyles()

  return (
    <Modal disableAutoFocus open={isLoading}>
      <Stack className={classes.root}>
        <CircularProgress/>
        <Typography 
          marginTop='24px'
          variant='h6'
        >
          Please Wait
        </Typography>
      </Stack>
    </Modal>
  )
}

export default LoadingComponent