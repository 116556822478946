import { useState, useEffect, useContext } from 'react'
import moment from 'moment'

// ASSETS
import LoadIcon from 'assets/images/logos/orders-load-icon.svg'
import UnloadingIcon from 'assets/images/logos/orders-unloading-icon.svg'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import DialogAddNewOrders from './AddNewOrder/DialogAddNewOrders'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import Flyout from 'components/Flyout/Flyout'
import ItemDetail from './ItemDetail/ItemDetail'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'
// NOTE : dialog import CSV is temporarily hidden by reques.
import DialogImportCSV from './DialogImportCSV/DialogImportCSV'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

// MUI ICONS
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICE
import { searchDepoListOrders } from 'services/order'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './orderUseStyles'

// UTILITIES
import {
  addSeparatorsForNumber,
  capitalizeEachWord,
} from 'utilities/string'
import { convertDate } from 'utilities/date'

const Booking = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()
  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)
  const { auth } = useContext(AllPagesContext)

  const initialListFilterStatus = [
    {
      title: 'All',
      isActived: true,
    },
    {
      title: 'TDR',
      isActived: false,
    },
    {
      title: 'Unpaid',
      isActived: false,
    },
    {
      title: 'Paid',
      isActived: false,
    },
    {
      title: 'Canceled',
      isActived: false,
    },
  ]

  const initialColumns = [
    {
      field: 'disbursement_status',
      headerName: 'Settlement Status',
      flex: 1,
      minWidth: 110,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        let label = ''
        let bgColor = ''
        let variant = 'contained'
        let color = 'white'
        if (params?.row?.booking_status === 'CANCEL' && !params.value) { label = 'Canceled'; bgColor = '#E31B0C' }
        else if (params.row.invoice_url === null && !params.value) { label = 'Expired'; bgColor = '#939393' }
        else if (params?.row?.booking_status === 'WAITING' && !params.value) { label = 'Pending'; bgColor = 'white'; variant = 'outlined'; color = 'black' }
        else if (params?.row?.booking_status === 'SUCCESS' && params.value) { label = 'Succes'; bgColor = '#4CAF50' }
        else if (params?.row?.booking_status === 'SUCCESS' && !params.value) { label = 'Pending'; bgColor = 'white'; variant = 'outlined'; color = 'black' }

        if (typeof params.id === 'number') return <Stack
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <Chip
            label={label}
            sx={{ backgroundColor: bgColor, color: color }}
            variant={variant}
          />
        </Stack>
      }
    },
    {
      field: 'disbursement_date',
      headerName: 'Settlement Date',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (typeof params.id === 'number') return <>
          {params.value ? convertDate(params.value, 'dd MMM yyyy HH:mm aa') : '-'}
        </>
      }
    },
    {
      field: 'booking_status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        let color
        let title
        if (params.value === 'SUCCESS') { color = '#3B873E'; title = 'Paid' }
        else if (params.value === 'WAITING' && params.row.invoice_url !== null) { color = '#C77700'; title = 'Unpaid' }
        else if (params.value === 'tdr') { color = '#0288D1'; title = 'TDR' }
        else if (params.value === 'CANCEL') { color = '#E31B0C'; title = 'Canceled' }
        else if (params.row.invoice_url === null) { color = '#939393'; title = 'Booking Expired' }

        if (typeof params.id === 'number') return (
          <Stack
            direction='row'
            alignItems='center'
          >
            <Stack
              width='8px'
              height='8px'
              borderRadius='50px'
              marginRight='8px'
              sx={{ backgroundColor: color }}
            />

            <Typography
              color={color}
              fontSize='inherit'>
              {title}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'booking_type',
      headerName: 'Booking',
      flex: 1,
      minWidth: 90,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (typeof params.id === 'number') return <Stack
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <Box
            component='img'
            src={params.value === 'LOADING' ? LoadIcon : UnloadingIcon}
          />

          <Box>{params.value === 'LOADING' ? 'Loading' : 'Unloading'}</Box>
        </Stack>
      }
    },
    {
      field: 'tx_date',
      headerName: 'Booking Date',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        return <>
          {convertDate(params.value, 'dd MMM yyyy')}
        </>
      }
    },
    {
      field: 'paid_date',
      headerName: 'Transaction Date',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (typeof params.id === 'number') return <>
          {params.value ? convertDate(params.value, 'dd MMM yyyy HH:mm aa') : '-'}
        </>
      }
    },
    {
      field: 'bill_landing',
      headerName: 'Bill of Lading No.',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'full_name',
      headerName: 'Customer Name',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'invoice_no',
      headerName: 'Invoice',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        if (typeof params.id === 'number') return params.value ?? '-'
      }
    },
    {
      field: 'total_amount',
      headerName: 'Total Amount ',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        if (typeof params.id === 'number') return (
          <Stack
            direction='row'
            alignContent='center'
            justifyContent='space-between'
            width='100%'
            maxWidth='100px'
          >
            <Box>
              IDR
            </Box>
            <Box>
              {calculatetotalPrice(params?.row?.items)}
            </Box>
          </Stack>
        )
      }
    },
    // {
    //   field: 'actions',
    //   headerName: '',
    //   width: 150,
    //   hide: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   areFilterAndSortShown: false,
    //   renderCell: (params) => {
    //     return <Stack 
    //       direction='row'
    //       className={layoutClasses.columnActions}
    //     >
    //       {/* EDIT BUTTON */}
    //       <CustomTooltipBlack
    //         onClick={() => {}}
    //         title='Edit' 
    //         placement='bottom'
    //       >
    //         <IconButton>
    //           <EditIcon/>
    //         </IconButton>
    //       </CustomTooltipBlack>

    //       {/* DUPLICATE BUTTON */}
    //       <CustomTooltipBlack 
    //         onClick={() => {}}
    //         title='Duplicate' 
    //         placement='bottom'
    //       >
    //         <IconButton>
    //           <FileCopyIcon/>
    //         </IconButton>
    //       </CustomTooltipBlack>

    //       {/* DELETE BUTTON */}
    //       <CustomTooltipBlack 
    //         onClick={() => setDialogConfirmationObject(params.row)}
    //         title='Delete' 
    //         placement='bottom'
    //       >
    //         <IconButton>
    //           <DeleteIcon/>
    //         </IconButton>
    //       </CustomTooltipBlack>
    //     </Stack>
    //   },
    // },
  ]

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Booking',
      value: 'booking_type',
    },
  ]

  const groupingColDef = {
    headerName: 'Booking',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) return params.row.booking_type

      return (
        <Typography
          variant='subtitle1'
          className={layoutClasses.groupingRow}
          noWrap
        >
          {capitalizeEachWord(params?.rowNode?.groupingKey?.replaceAll('_', ' '))}
        </Typography>
      )
    },
  }

  const flyoutWidth = 387

  const [isDataGridLoading, setIsDataGridLoading] = useState(false)
  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [total, setTotal] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const [filters, setFilters] = useState({})
  const [selectionModel, setSelectionModel] = useState([])
  const [isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen] = useState(false)
  const [dateRangeTimeValue, setDateRangeTimeValue] = useState([null, null])
  const [tableData, setTableData] = useState([])
  const [dialogConfirmationObject, setDialogConfirmationObject] = useState({})
  const [search, setSearch] = useState('')
  const [isFlyoutShown, setIsFlyoutShown] = useState(false)
  const [itemDetail, setItemDetail] = useState(null)
  const [isImportingCsv, setIsImportingCsv] = useState(false)
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false)
  const [listFilterStatus, setListFilterStatus] = useState(initialListFilterStatus)
  const [filterByStatus, setFilterByStatus] = useState('All')

  const loadDepoListOrder = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)
    let queryParams = {
      page: pageNumber,
      size: pageSize,
    }

    const newFilters = reject(isEmpty, filters)
    if (search) newFilters.globalSearch = search

    if (filterByStatus === 'Unpaid') newFilters.booking_status = 'WAITING'
    if (filterByStatus === 'TDR') newFilters.booking_status = 'TDR'
    if (filterByStatus === 'Paid') newFilters.booking_status = 'SUCCESS'
    if (filterByStatus === 'Canceled') newFilters.booking_status = 'CANCELED'

    if (dateRangeTimeValue[0] !== null && dateRangeTimeValue[1] !== null) {
      newFilters.from = moment(dateRangeTimeValue[0]).format('YYYY-MM-DDTHH:mm:ss.00Z')
      newFilters.to = moment(dateRangeTimeValue[1]).format('YYYY-MM-DDTHH:mm:ss.00Z')
    }

    const resultDepoListOrder = await searchDepoListOrders(inputAbortController.signal, auth.accessToken, newFilters, queryParams)

    if (resultDepoListOrder.status === 200 && inputIsMounted) {
      setIsDataGridLoading(false)
      setTableData(resultDepoListOrder?.data?.rows)
      setTotal(resultDepoListOrder?.data?.totalElements)
    } else setIsDataGridLoading(false)
  }

  const calculatetotalPrice = (inputItem) => {
    let tempTotalPrice = 0
    let calculate = inputItem.forEach((item) => (tempTotalPrice += item?.price ?? 0))
    return addSeparatorsForNumber(tempTotalPrice)
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (selectedGroupBy.value && inputItem.field === 'booking_type') { }
    else { tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const handleListFilterStatusClick = (inputItem) => {
    const newListFilter = [...listFilterStatus].map((item) => {
      if (item.title === inputItem.title) item.isActived = true
      else item.isActived = false
      return item
    })
    setListFilterStatus(newListFilter)
    setFilterByStatus(inputItem.title)
  }

  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)
  }

  const flyoutIcon = () => {
    return (
      <Stack
        direction='row'
        width={isFlyoutShown ? '455px' : 'auto'}
        justifyContent='space-between'
        alignItems='center'
      >
        {isFlyoutShown &&
          <Typography variant='h6'>
            Information
          </Typography>}

        <IconButton onClick={() => setIsFlyoutShown(current => !current)}>
          <KeyboardTabIcon sx={{
            color: '#0000008A',
            transition: 'transform 0.5s',
            transform: isFlyoutShown ? 'rotate(0deg)' : 'rotate(180deg)'
          }} />
        </IconButton>
      </Stack>
    )
  }

  useEffect(() => {
    if (selectionModel.length === 0) setItemDetail(null)
    else if (selectionModel.length === 1) {
      const selectedItem = [...tableData].find((item) => item.id === selectionModel[0])
      setItemDetail(selectedItem)
      if (!isFlyoutShown) setIsFlyoutShown(true)
    }
    else if (selectionModel.length > 1) {
      setItemDetail(null)
    }
  }, [selectionModel])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListOrder(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filters, search, pageSize, pageNumber, order, orderBy, filterByStatus, dateRangeTimeValue])

  // HANDLE DATA GRID COLUMNS WHILE GROUPING 
  useEffect(() => {
    if (selectedGroupBy.value) {

      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === selectedGroupBy.value) item.hide = true
        else item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)

    } else {

      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }

  }, [selectedGroupBy])

  return (
    <>
      {/* APPBAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Orders'
        hasFab={true}
        hasSpeedDial={false}
        fabOnClickButton={() => setIsDialogAddOrEditOpen(true)}
        setIsImportingCsv={setIsImportingCsv}
        isSpeedDialOpen={isSpeedDialOpen}
        setIsSpeedDialOpen={setIsSpeedDialOpen}
        extraComponent={flyoutIcon()}
      />

      {/* CONTENT & FLYOUT */}
      <Stack
        direction='row'
        position='relative'
        height='100%'
        sx={{ paddingRight: isFlyoutShown ? `${flyoutWidth + 24}px` : 0 }}
      >
        <LoadingPaper isLoading={isDataGridLoading}>
          <DataGridFilters
            // COLUMN
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            // FILTER
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            // GROUP BY ROW
            groupByList={groupByList}
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
            // DATE RANGE TIME
            isWithDateTimePicker={true}
            isWithTimePicker={false}
            dateRangeValue={dateRangeTimeValue}
            isDateRangeTimePickerOpen={isDateRangeTimePickerOpen}
            setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
            handleSelectDateRangePickerButtonClick={handleSelectDateRangePickerButtonClick}
            handleCancelDateRangePickerButtonClick={() => setIsDateRangeTimePickerOpen(false)}
          />

          {/* FILTER STATUS */}
          <Stack
            direction='row'
            className={classes.statusFilterContainer}
          >
            <Typography className={classes.filterStatusText}>
              Filter Status
            </Typography>

            {listFilterStatus.map((item, index) => (
              <Stack
                key={index}
                className={classes.listStatusContainer}
                sx={{ border: item.isActived ? '1px solid #F96649' : '1px solid #00000014' }}
                onClick={() => handleListFilterStatusClick(item)}
              >
                <Typography
                  variant='body2'
                  color={item.isActived ? '#F96649' : '#0000008A'}
                >
                  {item.title}
                </Typography>
              </Stack>
            ))}
          </Stack>

          <DataGridTable
            // BASE
            initialColumns={initialColumns}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            rows={tableData}
            // PAGINATION
            total={total}
            page={pageNumber}
            pageSize={pageSize}
            // ORDER
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPageNumber}
            setPageSize={setPageSize}
            // FILTER
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            // GROUP BY ROW
            selectedGroupBy={selectedGroupBy}
            getTreeDataPath={(row) => [row.booking_type, row.id]}
            groupingColDef={groupingColDef}
            // SELECTION
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
        </LoadingPaper>

        {/* FLYOUT */}
        <Flyout isFlyoutShown={isFlyoutShown} flyoutWidth={flyoutWidth}>
          {itemDetail ?
            <ItemDetail
              itemDetail={itemDetail}
              reloadData={loadDepoListOrder}
            /> :
            <Typography
              variant='subtitle2'
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              Select an entry from the list
            </Typography>
          }
        </Flyout>
      </Stack>

      {/* DIALOG ADD NEW ORDERS */}
      <DialogAddNewOrders reloadData={loadDepoListOrder} />

      {/* DIALOG IMPORT CSV */}
      <DialogImportCSV
        setIsImportingCsv={setIsImportingCsv}
        setIsSpeedDialOpen={setIsSpeedDialOpen}
        setIsDataGridLoading={setIsDataGridLoading}
        isImportingCsv={isImportingCsv}
      />
    </>
  )
}

export default Booking