const dummyPayment = [
  {
    id: 1,
    date: '21 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 2,
    date: '22 Jul 2022',
    payment: '12:48 AM - Gate out Evergreen at Depo Jakarta',
    paymentMethod: 'BCA',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 3,
    date: '22 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 4,
    date: '22 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 5,
    date: '23 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 6,
    date: '22 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 7,
    date: '23 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 8,
    date: '23 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 9,
    date: '23 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BRI',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
  {
    id: 10,
    date: '22 Jul 2022',
    payment: '12:48 AM - Gate in Evergreen at Depo Jakarta',
    paymentMethod: 'BCA',
    nominal: 'Rp. 2.500.000',
    totalNominal: 'Rp. 2.500.000'
  },
]

export default dummyPayment