import { useState } from 'react'
import PropTypes from 'prop-types'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// RAMDA
import { isEmpty } from 'ramda'

// STYLES
import useStyles from './dialogChangePasswordUseStyles'

const DialogChangePassword = (props) => {
  const classes = useStyles()

  const {
    dialogConfirmationObject,
    setDialogConfirmationObject,
    onContinueButtonClick,
    onCancelButtonClick,
    setNewPassword,
  } = props
  
  const [ showCurrentPassword, setShowCurrentPassword ] = useState(false)
  const [ showNewPassword, setShowNewPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)

  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') return {}
    else setDialogConfirmationObject({})
  }

  const handleNewpasswordFormChange = (event) => {
    setNewPassword(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <Dialog
      open={!isEmpty(dialogConfirmationObject)}
      onClose={handleDialogClose}
      className={classes.root}
    >
      {/* TITLE */}
      <DialogTitle className='padding0'>
        <Typography variant='h6'>
          Change password
        </Typography>
      </DialogTitle>

      {/* CAPTION */}
      <DialogContent className={classes.dialogContent}>
        {/* CURRENT PASSWORD */}
        <Stack marginBottom='10px'>
          <FormControl
            fullWidth
            variant='standard'
            color='secondary'
          >
            <InputLabel>
              Current password
            </InputLabel>
            <Input 
              type={showCurrentPassword ? 'text' : 'password'}
              name='currentPassword'
              onChange={handleNewpasswordFormChange}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowCurrentPassword(current => !current)}
                    edge='end'
                  >
                    {showCurrentPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            
          </FormControl>
        </Stack>

        {/* NEW PASSWORD */}
        <Stack marginBottom='10px'>
          <FormControl
            fullWidth
            variant='standard'
            color='secondary'
          >
            <InputLabel>
              New password
            </InputLabel>
            <Input 
              type={showNewPassword ? 'text' : 'password'}
              name='newPassword'
              onChange={handleNewpasswordFormChange}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowNewPassword(current => !current)}
                    edge='end'
                  >
                    {showNewPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            
          </FormControl>
        </Stack>

        {/* CONFIRM NEW PASSWORD */}
        <FormControl
          fullWidth
          variant='standard'
          color='secondary'
        >
          <InputLabel>
            Confirm new password
          </InputLabel>
          <Input 
            type={showConfirmPassword ? 'text' : 'password'}
            name='confirmNewPassword'
            onChange={handleNewpasswordFormChange}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowConfirmPassword(current => !current)}
                  edge='end'
                >
                  {showConfirmPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                </IconButton>
              </InputAdornment>
            }
          />
            
        </FormControl>
      </DialogContent>

      {/* DIALOG ACTIONS */}
      <DialogActions className='padding0'>
        <Stack direction='row'spacing={2}>
          {/* CANCEL BUTTON */}
          <Button 
            variant='outlined'
            onClick={onCancelButtonClick}
            className='flex1'
          >
            Cancel
          </Button>

          {/* CONTINUE BUTTON */}
          <Button 
            variant='contained'
            onClick={onContinueButtonClick}
            className='flex1'
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

DialogChangePassword.defaultProps = {
  dialogConfirmationObject: {},
}

DialogChangePassword.propTypes = {
  dialogConfirmationObject: PropTypes.object.isRequired,
  setDialogConfirmationObject: PropTypes.func.isRequired,
  onContinueButtonClick: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
}
export default DialogChangePassword
