import { useState } from 'react'

// COMPONENTS
import DataGridTable from 'components/DataGridTable/DataGridTable'
import BankAccount from '../BankAccount/BankAccount'

// MUIS
import { Button, Grid, Stack, Typography } from '@mui/material'

// STYLES
import useStyles from '../profileUseStyles'

const CompanyInformation = (props) => {
  
  const { detailCompany,
    initialColumns, 
    rows,
    setIsEditProfile,
  } = props

  const classes = useStyles()

  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState({})
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState('date')
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])

  return (
    <>
      <Stack width='100%'>
        {/* COMPANY INFORMATION */}
        <Stack
          direction='row'
        >
          {/*  LEFT CONTENT */}
          <Stack width='60%'>
            {/* TITLE */}
            <Typography variant='h6'>
              Company information
            </Typography>

            {/* DETAIL INFORMATION */}
            {detailCompany. map((item,index) => (
              <Grid container spacing={2} marginBottom='8px' key={index}>
                <Grid item xs={2.5}>
                  <Typography variant='caption'>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item >
                  <Typography variant='caption'>
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}

            {/* EDIT ACCOUNT */}
            <Stack 
              width='155px'
              marginTop='6px'
            >
              <Button 
                variant='contained'
                size='large'
                onClick={() => setIsEditProfile(true)}
              >
                EDIT ACCOUNT
              </Button>
            </Stack>
          </Stack>

          {/* RIGHT CONTENT */}
          <BankAccount/>
        </Stack>

        {/* FILE ATTACHMENT */}
        <Stack marginTop='30px'>
          {/* <Typography variant='h6'>
            File attachment
          </Typography> */}

          {/* DOCUMENT TABLE */}
          {/* <Stack className={classes.tableContainer}>
            <DataGridTable
              // BASE
              initialColumns={initialColumns}
              selectedColumnList={selectedColumnList}
              setSelectedColumnList={setSelectedColumnList}
              rows={rows}
              // PAGINATION
              total={total}
              page={pageNumber}
              pageSize={pageSize}
              // ORDER
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setPage={setPageNumber}
              setPageSize={setPageSize}
              // FILTER
              setFilters={setFilters}
              isFilterOn={isFilterOn}
              // GROUP BY ROW
              selectedGroupBy={selectedGroupBy}
              getTreeDataPath={(row) => [ row.accountStatus, row.id ]}
              groupingColDef={{}}
              // SELECTION
              selectionModel={selectionModel} 
              setSelectionModel={setSelectionModel}
              hideFooter={true}
              pagination={false}
              disableSelectionOnClick
              checkboxSelection={false}
            />
          </Stack> */}
        </Stack>
      </Stack>
    </>
  )
}

export default CompanyInformation