import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

// ASSETS
import BackgroundForgotPassword from 'assets/images/backgrounds/authentication-forgot-password.png'
import BackgroundResetPassword from 'assets/images/backgrounds/authentication-reset-password.png'
import BackgroundWelcome from 'assets/images/backgrounds/authentication-welcome.png'

// MUIS
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './authenticationUseStyles'

const Authentication = (props) => {
  const { children } = props

  const classes = useStyles()
  
  const location = useLocation()

  const getBackgroundImage = (inputPath) => {
    if (inputPath.includes('sign-in') 
    || inputPath.includes('sign-up') 
    || inputPath.includes('company-legalities')) return BackgroundWelcome
    else if (inputPath.includes('forgot-password')) return BackgroundForgotPassword
    else if (inputPath.includes('reset-password')) return BackgroundResetPassword
  }

  return (
    <Stack 
      className={`${classes.root} no-zoom`}
      sx={{ backgroundImage: `url(${getBackgroundImage(location.pathname)})` }}
    >
      <Paper className={classes.content}>
        {/* CHILDREN */}
        {children}
      </Paper>
    </Stack>
  )
}

Authentication.defaultProps = {}

Authentication.propTypes = { children: PropTypes.node.isRequired }

export default Authentication