import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// ASSETS
import LogoCompany from 'assets/images/logos/product-nle-connect-white.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// CUSTOM COMPONENTS
import CustomDrawer, { DrawerHeader } from 'components/Customs/CustomDrawer'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

// DATA
import { drawerNavigationList } from './drawerNavigationList'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconContentCopy from '@mui/icons-material/ContentCopy'
import IconLogout from '@mui/icons-material/Logout'
import IconMenuOpen from '@mui/icons-material/MenuOpen'

// STYLES
import useStyles from './drawerUseStyles'

// UTILITIES
import { signOutUser } from 'utilities/authentication'

const Drawer = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const { 
    auth, setAuth,
    setSnackbarObject, 
  } = useContext(AllPagesContext)
  const { isDrawerExpanded, setIsDrawerExpanded } = useContext(PrivateLayoutContext)

  const [ dialogConfirmationObject, setDialogConfirmationObject ] = useState({})

  const ID = auth.organizationCode
  const isNavigationActive = (inputPath) => {
    if (location.pathname === inputPath) return true
    else return false
  }

  const handleIdButtonClick = () => {
    navigator.clipboard.writeText(ID)
    setSnackbarObject({
      open: true,
      severity: 'info',
      title: '',
      message: 'Success copying the ID to clipboard',
    })
  }

  return (
    <CustomDrawer 
      variant='permanent' 
      open={isDrawerExpanded}
      className='zoom'
    >
      {/* HEADER */}
      <DrawerHeader>
        {/* TOGGEL DRAWER ICON */}
        <IconButton   
          className={classes.headerIconToggle}
          onClick={() => setIsDrawerExpanded(current => !current)}
        >
          <IconMenuOpen/>
        </IconButton>
        
        {/* COMPANY LOGO */}
        {isDrawerExpanded &&
        <Box
          component='img'
          src={LogoCompany}
          alt=''
          className={classes.headerLogoCompany}
        />}
      </DrawerHeader>

      {/* ID */}
      <ListItem>
        <ListItemButton 
          className={`${classes.navigationItemButton} ${classes.idButton}`}
          onClick={() => handleIdButtonClick()}
        >
          {/* ICON */}
          <ListItemIcon className={classes.navigationItemIcon}>
            <IconContentCopy/>
          </ListItemIcon>

          {/* TEXT */}
          <ListItemText 
            primary={`ID: #${ID}`}
            className={classes.navigationItemText}
          />
        </ListItemButton>
      </ListItem>

      {/* NAVIGATION LIST */}
      <List disablePadding>
        {drawerNavigationList.map((item, index) => (
          <ListItem key={index}>
            <ListItemButton 
              href={item.path}
              className={isNavigationActive(item.path)
                ? `${classes.navigationItemButtonActive} ${classes.navigationItemButton}`
                : classes.navigationItemButton
              }
            >
              {/* ICON */}
              <ListItemIcon className={isNavigationActive(item.path)
                ? `${classes.navigationItemIconActive} ${classes.navigationItemIcon}`
                : classes.navigationItemIcon
              }>
                <item.icon/>
              </ListItemIcon>

              {/* TEXT */}
              <ListItemText 
                primary={item.title}
                className={classes.navigationItemText}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* LOGOUT BUTTON */}
      <ListItem className={classes.logoutItem}>
        <ListItemButton 
          className={`${classes.navigationItemButton} ${classes.logoutButton}`}
          sx={(theme) => ({border : isDrawerExpanded ? `1px solid ${theme.palette.primary.light}` : 'none'})}
          onClick={() => navigate('/profile')}
        >
          {/* AVATAR */}
          <ListItemIcon className={classes.navigationItemIcon}>
            <Avatar 
              className={classes.logoutAvatar}
              sx={{ marginLeft: !isDrawerExpanded ? '-4px' : 'inherit' }}
            >
              {auth.fullName && auth.fullName[0].toUpperCase()}
            </Avatar>
          </ListItemIcon>

          {/* TEXT */}
          <ListItemText 
            primary={
              <Typography
                variant='subtitle2'
                noWrap
                className={classes.logoutItemTextPrimary}
              >
                {auth.fullName ?? 'null'}
              </Typography>
            }
            secondary='Depo Owner'
            className={classes.logoutItemText}
          />

          {/* ICON */}
          <IconLogout 
            className={classes.logoutItemIconExtra}
            onClick={(event) => {
              event.stopPropagation()
              setDialogConfirmationObject({show: true})
            }}
          />
        </ListItemButton>
      </ListItem>

      {/* DIALOG CONFIRMATION */}
      <DialogConfirmation
        title='Sign out confirm'
        caption='Are you sure to sign out?'
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        cancelButtonText='Cancel'
        continueButtonText='Sign Out'
        onContinueButtonClick={() => signOutUser(setAuth)}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
      />
    </CustomDrawer>
  )
}

export default Drawer