// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

// MUI LABS
import LoadingButton from '@mui/lab/LoadingButton'

// MUI ICONS
import FileUploadIcon from '@mui/icons-material/FileUpload'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

const CompanyLegalities = () => {
  const layoutClasses = useLayoutStyles()

  return (
    <Box className={layoutClasses.root}>
      {/* TITLE */}
      <Typography variant='h4'>
        Company Legality
      </Typography>

      {/* EMPTY CAPTION */}
      <Typography
        className={layoutClasses.caption}
        variant='subtitle1'
      >
      </Typography>

      {/* ADDRESS FORM */}
      <FormControl 
        variant='standard'
        className='formTextField'
        color='secondary'
      >

        {/* ADDRESS INPUT LABEL */}
        <InputLabel>
          Alamat
        </InputLabel>

        {/* ADDRESS INPUT */}
        <Input 
          type='text' 
          name='address'
        />
      </FormControl>

      {/* NPWP FORM */}
      <FormControl 
        variant='standard'
        className='formTextField'
        color='secondary'
      >

        {/* NPWP INPUT LABEL */}
        <InputLabel>
          NPWP Number
        </InputLabel>

        {/* NPWP INPUT */}
        <Input 
          type='text' 
          name='npwp'
        />
      </FormControl>

      {/* SIUP FORM */}
      <FormControl 
        variant='standard'
        className='formTextField'
        color='secondary'
      >
        {/* SIUP INPUT LABEL */}
        <InputLabel>
          SIUP Number
        </InputLabel>

        {/* SIUP INPUT */}
        <Input 
          type='text' 
          name='siup'
        />
      </FormControl>

      {/* NIB FORM */}
      <FormControl 
        variant='standard'
        className='formTextField'
        color='secondary'
      >
        {/* NIB INPUT LABEL */}
        <InputLabel>
          NIB Number
        </InputLabel>

        {/* NIB INPUT */}
        <Input 
          type='text' 
          name='nib'
        />
      </FormControl>

      {/* KTA ASDEKI FORM */}
      <FormControl 
        variant='standard'
        className='formTextField'
        color='secondary'
      >
        {/* KTA ASDEKI INPUT LABEL */}
        <InputLabel>
          KTA ASDEKI
        </InputLabel>

        {/* KTA ASDEKI INPUT */}
        <Input 
          type='text' 
          name='kta'
        />
      </FormControl>

      {/* UPLOAD FILE */}
      <Typography
        variant='subtitle2'
        className='formLabel'
        color='text.secondary'
      >
        Upload file NPWP, SIUP, NIB dan KTA ASDEKI
      </Typography>

      {/* UPLOAD FORM */}
      <FormControl 
        variant='filled'
        hiddenLabel
        className='formTextField'
        color='secondary'
      >
        {/* UPLOAD BUTTON */}
        <LoadingButton
          className={layoutClasses.buttonSecondary}
          size='large'
          variant='text'
          startIcon={<FileUploadIcon/>}
        >
          Add files
        </LoadingButton>
      </FormControl>

      {/* SUBMIT BUTTON */}
      <LoadingButton
        className={layoutClasses.buttonContained}
        size='large'
        variant='contained'
      >
        Submit
      </LoadingButton>
    </Box>
  )
}

export default CompanyLegalities