import { useState, useEffect } from 'react'

// MUIS
import { 
  Checkbox,
  InputBase, 
  Stack, 
  Typography, 
} from '@mui/material'

// MUI ICONS
import SearchIcon from '@mui/icons-material/Search'

// STYLES
import useStyles from '../reportUseStyles'

const FleetManager = (props) => {
  const classes = useStyles()

  const { 
    isContentExpanded,
    fleetManagerList,
    setFleetManagerList,
  } = props

  const [search, setSearch] = useState('')

  const handleCheckbox = (inputItem) => {
    const newFleetManager = [...fleetManagerList].map((item) => {
      if(item.id === inputItem.id) item.isChecked = !item.isChecked

      return item
    })
    setFleetManagerList(newFleetManager)
  }

  // HANDLE SEARCH BAR
  useEffect(() => {
    const newFleetManager = [...fleetManagerList].map((item) => {
      if(item.name && item.name.toLowerCase().includes(search.toLowerCase())) item.isShown = true
      else item.isShown = false

      return item
    })

    setFleetManagerList(newFleetManager)
  }, [search])

  return (
    <Stack 
      className={classes.fleetManagerRoot}
      left={isContentExpanded ? 240 : 40}
      sx={{transition: 'left 0.5s'}}
    >
      {/* TITLE */}
      <Stack padding='24px 20px 0px 20px'>
        <Typography className={classes.typography1}>
          Fleet manager
        </Typography>
      </Stack>

      {/* SEARCH BAR */}
      <Stack
        sx={{backgroundColor: '#EEEEEE'}}
        height='44px'
        marginTop='16px'
        justifyContent='center'
        alignItems='center'
      >
        <Stack
          height='28px'
          width='220px'
          borderRadius='4px'
          sx={{backgroundColor: '#FFF'}}
          direction='row'
          padding='4px 10px'
          alignItems='center'
        >
          <SearchIcon className={classes.fleetManagerSearchIcon}/>
          <InputBase 
            className={classes.fleetManagerInputBase}
            placeholder='Quick search'
            onChange={(e) => setSearch(e.target.value)}
          />

        </Stack>

      </Stack>

      {/* FLEET MANAGER LIST */}
      <Stack
        height='88%'
        overflow='auto'
      >
        {fleetManagerList.map((item, index) => (
          item.isShown &&
            <Stack
              key={index}
              height='32px'
              padding='8px 22px'
              borderBottom='1px solid #0000001F'
              direction='row'        
            >
              <Checkbox
                disableRipple
                checked={item.isChecked}
                size='small'
                onChange={() => handleCheckbox(item)}
              />

              <Typography variant='caption'>
                {item.name}
              </Typography>
            </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default FleetManager