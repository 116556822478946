// MUIS
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'

const CustomDialogTitle = styled(({ className, ...props }) => (
  <DialogTitle
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  padding: '28px 20px 0px',
  display: 'flex',
  alignItems: 'center',
}))

export default CustomDialogTitle
