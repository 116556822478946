// CSV IMPORTER
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'

// FILES
import ExampleFile from 'files/gate_move_csv_example.csv'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dialogImportCSVUSeStyles'

const ImportCsvComponent = (props) => {
  
  const {setListData,
    setIsDataImported,
  } = props

  const classes = useStyles()

  const listFields= [
    {
      name: 'Process Type',
    },
    {
      name: 'Date & Time',
    },
    {
      name: 'Depot',
    },
    {
      name: 'Fleet Manager',
    },
    {
      name: 'Container No',
    },
    {
      name: 'Iso Code',
    },
    {
      name: 'Amount',
    },
    {
      name: 'Condition',
    },
    {
      name: 'Damaged By',
    },
    {
      name: 'Clean',
    },
    {
      name: 'Grade',
    },
    {
      name: 'Date Manufactured',
    },
    {
      name: 'Order Number',
    },
    {
      name: 'Customer',
    },
    {
      name: 'Vessel',
    },
    {
      name: 'Voyage',
    },
    {
      name: 'Discharge Port',
    },
    {
      name: 'Delivery Port',
    },
    {
      name: 'Carrier',
    },
    {
      name: 'Transport ID',
    },
    {
      name: 'Driver Name',
    },
    {
      name: 'Tare',
    },
    {
      name: 'Payload',
    },
    {
      name: 'Max Gross',
    },
    {
      name: 'Remarks',
    },
    {
      name: 'Longitude',
    },
    {
      name: 'Latitude',
    },
  ]

  return (
    <Stack padding='16px 24px' height='520px' sx={{overflowY: 'scroll'}}>
      <Typography variant='body2'>
        You can import Gate Moves by uploading CSV file with data. If file contains a header fields, they will be detected automatically, otherwise, you can specify them manually.
      </Typography>

      {/* EXAMPLE FILE */}
      <a
        href={ExampleFile}
        download='Example_File'
        target='_blank'
        rel='noreferrer'
      >
        <Typography 
          variant='body2'
          className={classes.fileExample}
        >
          File Example
        </Typography>
      </a>
      <br/>

      <Typography variant='body2'>
        Following fields are available for import:
      </Typography>

      {/* LIST FIELDS */}
      <Stack paddingLeft='14px' marginTop='12px' direction='row'>
        <Stack>
          {listFields.slice(0,7).map((item,index)=> (
            <Stack key={index} direction='row' alignItems='center'>
              <Stack
                width='4px'
                height='4px'
                marginRight='4px'
                borderRadius='50px'
                sx={{backgroundColor: 'black'}}
              />
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Stack marginLeft='48px'>
          {listFields.slice(7,14).map((item,index)=> (
            <Stack key={index} direction='row' alignItems='center'>
              <Stack
                width='4px'
                height='4px'
                marginRight='4px'
                borderRadius='50px'
                sx={{backgroundColor: 'black'}}
              />
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Stack marginLeft='48px'>
          {listFields.slice(14,21).map((item,index)=> (
            <Stack key={index} direction='row' alignItems='center'>
              <Stack
                width='4px'
                height='4px'
                marginRight='4px'
                borderRadius='50px'
                sx={{backgroundColor: 'black'}}
              />
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Stack marginLeft='48px'>
          {listFields.slice(21,30).map((item,index)=> (
            <Stack key={index} direction='row' alignItems='center'>
              <Stack
                width='4px'
                height='4px'
                marginRight='4px'
                borderRadius='50px'
                sx={{backgroundColor: 'black'}}
              />
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <br/>

      <Typography variant='body2' sx={{fontWeight: 600}} >
        Import CSV File (maximum size 10 Mb) :
      </Typography>

      {/* IMPORTER */}
      <Stack
        padding='24px 24px 0px 24px'
        direction='row'
        justifyContent='center'
      >
        <Stack width='100%' className={`${classes.importer} no-zoom`}>
          <Importer
            chunkSize={10000}
            assumeNoHeaders={false}
            restartable={false}
            processChunk={async (rows) => {
              setListData(rows)
            }}
            onClose={() => {
              setIsDataImported(true)
            }}
          >
            <ImporterField name='process_type' label='Process Type' />
            <ImporterField name='tx_date' label='Date & Time' />
            <ImporterField name='depot' label='Depot' />
            <ImporterField name='fleet_manager' label='Fleet Manager' />
            <ImporterField name='container_number' label='Container No' />
            <ImporterField name='iso_code' label='Iso Code' />
            <ImporterField name='amount' label='Amount' />
            <ImporterField name='condition' label='Condition' />
            <ImporterField name='damage_by' label='Damaged By' />
            <ImporterField name='clean' label='Clean' />

            <ImporterField name='grade' label='Grade' />
            <ImporterField name='date_manufacturer' label='Date Manufactured' />
            <ImporterField name='order_number' label='Order Number' />
            <ImporterField name='customer' label='Customer' />
            <ImporterField name='vessel' label='Vessel' />
            <ImporterField name='voyage' label='Voyage' />
            <ImporterField name='discharge_port' label='Discharge Port' />
            <ImporterField name='delivery_port' label='Delivery Port' />
            <ImporterField name='carrier' label='Carrier' />
            <ImporterField name='transport_number' label='Transport ID' />

            <ImporterField name='driver_name' label='Driver Name' />
            <ImporterField name='tare' label='Tare' />
            <ImporterField name='payload' label='Payload' />
            <ImporterField name='max_gross' label='Max Gross' />
            <ImporterField name='remarks' label='Remarks' />
            <ImporterField name='longitude' label='Longitude' />
            <ImporterField name='latitude' label='Latitude' />
          </Importer>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ImportCsvComponent