import { useState } from 'react'

// MUIS
import { 
  Collapse, 
  IconButton, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Stack, 
  Typography, 
} from '@mui/material'

// MUI ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SubjectIcon from '@mui/icons-material/Subject'
import TextFormatIcon from '@mui/icons-material/TextFormat'

// STYLES
import useStyles from './itemDetailUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const ItemDetail = (props) => {
  const { itemDetail } = props
  const classes = useStyles()

  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <Stack>
      <Stack 
        direction='row' 
        alignItems='center'
        justifyContent='space-between'
      >
        {/* MAIN TITLE */}
        <Typography className={classes.mainTitle}>
          Main Information
        </Typography>

        {/* EXPAND ICON */}
        <IconButton onClick={() => setIsExpanded(current => !current)}>
          <ExpandMoreIcon sx={{
            transform : isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.7s'
          }}/>
        </IconButton>

      </Stack>
      {/* COLLAPSE CONTENT */}
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        {/* STATUS */}
        <List className={classes.list} disablePadding>
          <ListItem className={classes.listItem}>
            <ListItemAvatar className={classes.listAvatar}>
              <FiberManualRecordIcon className={classes.listIcon}/>
            </ListItemAvatar>
            <ListItemText primary='Status' secondary={itemDetail?.status === true ? 'Active' : 'Inactive'}/>
          </ListItem>
        </List>

        {/* NAME */}
        <List className={classes.list} disablePadding>
          <ListItem className={classes.listItem}>
            <ListItemAvatar className={classes.listAvatar}>
              <TextFormatIcon className={classes.listIcon}/>
            </ListItemAvatar>
            <ListItemText primary='Name' secondary={`${itemDetail?.item_name?.itemCode} - ${itemDetail?.item_name?.itemType}`}/>
          </ListItem>
        </List>

        {/* SKU */}
        <List className={classes.list} disablePadding>
          <ListItem className={classes.listItem}>
            <ListItemAvatar className={classes.listAvatar}>
              <CalendarViewDayIcon className={classes.listIcon}/>
            </ListItemAvatar>
            <ListItemText primary='SKU' secondary={itemDetail?.sku}/>
          </ListItem>
        </List>

        {/* DESCRIPTION */}
        <List className={classes.list} disablePadding>
          <ListItem className={classes.listItem}>
            <ListItemAvatar className={classes.listAvatar}>
              <SubjectIcon className={classes.listIcon}/>
            </ListItemAvatar>
            <ListItemText primary='Description' secondary={itemDetail?.description}/>
          </ListItem>
        </List>

        {/* SELLING PRICE */}
        <List className={classes.list} disablePadding>
          <ListItem className={classes.listItem}>
            <ListItemAvatar className={classes.listAvatar}>
              <LocalOfferIcon className={classes.listIcon}/>
            </ListItemAvatar>
            <ListItemText primary='Selling price' secondary={`IDR ${addSeparatorsForNumber(itemDetail?.price)}`}/>
          </ListItem>
        </List>

      </Collapse>
    </Stack>
  )
}

export default ItemDetail