// COMPONENT
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

// STYLES
import useStyles from '../../orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const DetailPaymentModal = (props) => {
  const { isPaymentDetailOpen,
    setIsPaymentDetailOpen,
    totalPrice,
    listOrder,
    fee,
  } = props
  const classes = useStyles()
  
  return (
    <Modal open={isPaymentDetailOpen}>
      <Stack className={classes.paymentDetailRoot}>
        {/* HEADER */}
        <Stack
          direction='row'
          alignItems='center'
          padding='16px 24px'
          justifyContent='space-between'
          width='100%'
        >
          <Typography className={classes.typography5}>
            Payment Details
          </Typography>
          
          <IconButton onClick={() => setIsPaymentDetailOpen(false)}>
            <CloseIcon sx={{color: '#0000008A'}}/>
          </IconButton>
        </Stack>

        <Divider/>

        {/* DETAILS */}
        <Stack
          padding='24px 24px 0px 24px'
        >
          {/* PRICE DETAIL */}
          <Typography className={classes.typography1}>
            Price Detail
          </Typography>

          {listOrder?.map((item, index) => (
            <Stack
              key={index}
              paddingTop={index === 0 ? '20px' : '16px'}
              paddingBottom='16px'
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              borderTop={index === 0 ? 'unset' : '1px dashed #D9D9D9'}
            >
              <CustomTooltipBlack
                followCursor 
                title={`${item?.value?.item_name?.itemCode} - ${item?.value?.item_name?.itemType} (${item?.value?.fleet?.fleet_manager_company}) - ${item?.quantity} X`}
                placement='bottom'
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={0.5}
                  width='100%'
                  maxWidth='250px'
                >
                  <FiberManualRecordIcon className={classes.dotIcon}/>
                  <Typography className={classes.orderFormItemName}>
                    {item?.value?.item_name?.itemCode} - {item?.value?.item_name?.itemType} ({item?.value?.fleet?.fleet_manager_company}) - {item?.quantity} X
                  </Typography>
                </Stack>
              </CustomTooltipBlack>

              <Typography className={classes.typography2}>
                Rp {addSeparatorsForNumber(item?.price)}
              </Typography>
            </Stack>
          ))}

          {/* OTHERS FEE || NOTE : TAKE OUT BY REQUEST */}
          {/* <Stack marginTop='20px' marginBottom='28px'>
            <Typography className={classes.typography1}>
              Others Fee
            </Typography>

            <Stack 
              width='100%'
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              marginTop='20px'
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={0.5}
              >
                <FiberManualRecordIcon className={classes.dotIcon}/>
                <Typography className={classes.typography2}>
                  Transaction Fee
                </Typography>
              </Stack>
              <Typography className={classes.typography2}>
                {`Rp ${addSeparatorsForNumber(fee)}`}
              </Typography>
            </Stack>
          </Stack> */}
          <Divider/>
        </Stack>

        {/* TOTAL PAYMENT */}
        <Stack
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          padding='28px 24px'
        >
          <Typography className={classes.typography1}>
            Total Payment
          </Typography>

          <Typography className={classes.typography5}>
            {`Rp ${addSeparatorsForNumber(totalPrice)}`}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default DetailPaymentModal