// COMPONENT
import CustomDataGridPro from '../CustomDataGrid/CustomDataGrid'

// DUMMY
import reportPaymentDummy from './reportPaymentDummy'

// MUIS
import { IconButton, Stack, Typography } from '@mui/material'
import { useGridApiContext, gridClasses } from '@mui/x-data-grid-pro'

// MUI ICONS
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// STYLES
import useStyles from './reportPaymentTableUseStyles'
import useLayoutStyles from 'styles/layoutPrivate'

const ReportPaymentTable = (props) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const {isContentExpanded} = props

  const initialColumns = [
    {
      field: 'payment',
      headerName: 'Payment',
      flex: 1,
      minWidth: 300,
      hide: false,
      sortable: false,
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment method',
      flex: 1,
      minWidth: 80,
      hide: false,
      sortable: false,
    },
    {
      field: 'nominal',
      headerName: 'Nominal',
      flex: 1,
      minWidth: 80,
      hide: false,
      sortable: false,
    },
    {
      field: 'totalNominal',
      headerName: 'Total Nominal',
      flex: 1,
      minWidth: 80,
      hide: false,
      sortable: false,
    },
  ]

  // CUSTOM GRID TREE DATA
  const CustomGridTreeDataGroupingCell = (props) => {
    const { id, field, rowNode, row } = props
    const apiRef = useGridApiContext()
      
    const handleClick = (event) => {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
      apiRef.current.setCellFocus(id, field)
      event.stopPropagation()
    }
  
    if (!rowNode.isAutoGenerated) return row.date
  
    return (
      <Stack 
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography 
          variant='body2' 
          className={layoutClasses.groupingRow}
          sx={{width: 90}}
          noWrap
        >
          {rowNode?.groupingKey?.replaceAll('_', ' ')}
        </Typography>
  
        <IconButton onClick={(e) => handleClick(e)}>
          <KeyboardArrowDownIcon
            sx={{
              rotate: rowNode.childrenExpanded ? '180deg' : '0deg',
              transition: 'rotate 0.5s'
            }}
          />
        </IconButton>
      </Stack> 
    )
  }

  // GROUPING COL DEF
  const groupingColDef = {
    headerName: 'Date',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
  }

  return (
    <Stack 
      left={isContentExpanded? '260px' : '60px'}
      sx={{
        width: isContentExpanded ? '82%' : '95%',
        transition: 'left 0.5s',
      }}
      className={classes.root}
    >
      {/* HEADER */}
      <Stack
        padding='20px'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography className={classes.typography1}>
          Payment
        </Typography>

        <Stack 
          alignItems='center'
          spacing={0.5}
          direction='row'
        >
          <IconButton>
            <DeleteIcon className={classes.headerIcon}/>
          </IconButton>

          <IconButton className={classes.headerIcon}>
            <DownloadIcon/>
          </IconButton>
        </Stack>
      </Stack>

      {/* TABLE DATA */}
      <Stack 
        margin='0px 20px'
        height='77%'
        border='1px solid #0000001F'
      >
        <CustomDataGridPro
          columns={initialColumns}
          rows={reportPaymentDummy}
          getTreeDataPath={(row) => [row.date, row.id]}
          groupingColDef={groupingColDef}
          treeData={true}
          defaultGroupingExpansionDepth={-1}
          hideFooterRowCount
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
          getRowClassName={(params) => 
            typeof params.id !== 'number' ? 'gray' : 'white'
          }
          sx={{ [`& .${gridClasses.row}.gray`] : { backgroundColor: '#00000014' } }}
        />
      </Stack>

      {/* SUMMARY */}
      <Stack className={classes.summaryContainer}>
        <Stack
          height='32px'
          paddingLeft='10px'
          justifyContent='center'
          sx={{ backgroundColor: '#0000001F' }}
        >
          <Typography className={classes.typography2}>
            Summary
          </Typography>
        </Stack>

        <Stack 
          alignItems='center'
          direction='row'
          height='42px'
          paddingLeft='10px'
        >
          <Typography 
            marginRight='160px'
            variant='caption'
          >
            Payment
          </Typography>

          <Typography className={classes.typography3}>
            Rp. 10.500.000
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ReportPaymentTable