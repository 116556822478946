import { useState, useEffect } from 'react'

// ASSETS
import ReportBackground from 'assets/images/backgrounds/report-background.svg'

// COMPONENTS
import AvailableReport from './AvailableReport/AvailableReport'
import FleetManager from './FleetManager/FleetManager'
import GeneratedReport from './GeneratedReport/GeneratedReport'
import Trips from './Trips/Trips'
import Payment from './Payment/Payment'
import ReportMovesTable from './ReportMovesTable/ReportMovesTable'
import ReportPaymentTable from './ReportPaymentTable/ReportPaymentTable'

// MUIS
import { Stack } from '@mui/material'

const Report = () => {
  const dummyFleetManager = [
    {
      name: 'CMA-CGM',
      isChecked: false,
      id: 1,
      isShown: true,
    },
    {
      name: 'KMTC',
      isChecked: false,
      id: 2,
      isShown: true,
    },
    {
      name: 'SINU',
      isChecked: false,
      id: 3,
      isShown: true,
    },
    {
      name: 'HPAG',
      isChecked: false,
      id: 4,
      isShown: true,
    },
    {
      name: 'SJKI',
      isChecked: false,
      id: 5,
    },
  ]

  const initialPaymentMethodList = [
    {
      name: 'BJB',
      isChecked: false
    },
    {
      name: 'BNI',
      isChecked: false
    },
    {
      name: 'BRI',
      isChecked: false
    },
    {
      name: 'BSI',
      isChecked: false
    },
    {
      name: 'BSS',
      isChecked: false
    },
    {
      name: 'Permata',
      isChecked: false
    },
    {
      name: 'Mandiri',
      isChecked: false
    },
  ]

  const [isContentExpanded, seIsContentExpanded] = useState(true)
  // MOVES
  const [isCreatingReport, setIsCreatingReport] = useState(false)
  const [isCreatingMoves, setIsCreatingMoves] = useState(false)
  const [fleetManagerList, setFleetManagerList] = useState(dummyFleetManager)
  const [isFleetManagerChoosed, setIsFleetManagerChoosed] = useState(false)
  // PAYMENT
  const [isCreatingPayment, setIsCreatingPayment] = useState(false)
  const [paymentMethodList, setPaymentMethodList] = useState(initialPaymentMethodList)
  // REPORT MOVES DATA GRID
  const [isReportMovesShown, setIsReportMovesShown] = useState(false)
  const [isReportPaymentShown, setIsReportPaymentShown] = useState(false)

  // HANDLE IS FLEET MANAGER CHOOSED
  useEffect(() => {
    const findCheckedItem = fleetManagerList.find((item) => item.isChecked === true)
    if(findCheckedItem !== undefined) setIsFleetManagerChoosed(true)
    else setIsFleetManagerChoosed(false)
  }, [fleetManagerList])

  // FUNCTION RESET FLEET MANAGER LIST
  const resetFleetMaangerList = () => {
    const newFleetManager = [...fleetManagerList].map((item) => {
      item.isChecked = false
      return item
    })
    setFleetManagerList(newFleetManager)
  }

  return (
    <Stack
      height='100%'
      sx={{ 
        backgroundImage: `url(${ReportBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >

      {/* CONTENT */}
      { isCreatingReport ? 
        // AVAILABLE REPORT
        <AvailableReport 
          isContentExpanded={isContentExpanded}
          seIsContentExpanded={seIsContentExpanded}
          setIsCreatingMoves={setIsCreatingMoves}
          setIsCreatingPayment={setIsCreatingPayment}
          resetFleetMaangerList={resetFleetMaangerList}
        /> :
        // GEENRATED REPORT
        <GeneratedReport
          isContentExpanded={isContentExpanded}
          seIsContentExpanded={seIsContentExpanded}
          setIsCreatingReport={setIsCreatingReport}
          setIsReportMovesShown={setIsReportMovesShown}
          setIsReportPaymentShown={setIsReportPaymentShown}
        />
      }

      {/* FLEET MANAGER */}
      {isCreatingMoves && 
      <FleetManager
        isContentExpanded={isContentExpanded}
        fleetManagerList={fleetManagerList}
        setFleetManagerList={setFleetManagerList}
      />
      }

      {/* TRIPS */}
      {isFleetManagerChoosed &&
      <Trips
        isContentExpanded={isContentExpanded}
        fleetManagerList={fleetManagerList}
        setFleetManagerList={setFleetManagerList}
        setIsCreatingMoves={setIsCreatingMoves}
        setIsCreatingReport={setIsCreatingReport}
      />
      }

      {/* PAYMENT */}
      {isCreatingPayment &&
      <Payment
        isContentExpanded={isContentExpanded}
        paymentMethodList={paymentMethodList}
        setPaymentMethodList={setPaymentMethodList}
        setIsCreatingPayment={setIsCreatingPayment}
        setIsCreatingReport={setIsCreatingReport}
      />
      }

      {/* REPORT MOVES TABLE */}
      {isReportMovesShown && 
      <ReportMovesTable
        isContentExpanded={isContentExpanded}
      />
      }

      {/* REPORT PAYMENT TABLE */}
      {isReportPaymentShown && 
      <ReportPaymentTable
        isContentExpanded={isContentExpanded}
      />
      }
      

    </Stack>
  )
}

export default Report