import { useContext } from 'react'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import {
  Stack, 
  Typography,
  Button,
} from '@mui/material'

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit'

// STYLES 
import useStyles from '../../orderUseStyles'

const PaymentMethod = ({ setIsEditingPaymentMethod }) => {
  const classes = useStyles()
  const { orderForms } = useContext(PrivateLayoutContext)

  return (
    <Stack marginTop='30px'>
      {/* TITLE */}
      <Typography className={classes.typography1}>
        Payment Method
      </Typography>

      {/* NAME */}
      <Stack
        marginTop='20px'
        direction='row'
        height='72px'
        justifyContent='space-between'
        alignItems='center'
        borderTop='1px solid #0000001F'
        borderBottom='1px solid #0000001F'
      >
        <Typography className={classes.typography3}>
          {orderForms?.paymentMethod?.label}
        </Typography>

        <Button 
          startIcon={<EditIcon/>}
          variant='outlined'
          onClick={() => setIsEditingPaymentMethod(true)}
        >
          Edit
        </Button>
      </Stack>
    </Stack>
  )
}

export default PaymentMethod