//APIS
import { axiosPrivate } from 'apis/axios'

// QUERY
import { stringify } from 'query-string'

// FLEET CONTROLLER
export const getFleetManager = async (inputSignal, inputQueryParams, inputToken) => {
  try {
    const response = await axiosPrivate.get(
      `/api/insw-shipping?${stringify(inputQueryParams)}`,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// REGISTER FLEET
export const postRegisterFleetManager = async (
  inputSignal,
  inputBodyParams,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      '/api/depo-fleet/register', inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// EDIT FLEET
export const putEditFleetManager = async (
  inputSignal,
  inputBodyParams,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.put(
      '/api/depo-fleet', inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// DELETE FLEET
export const deleteFleetManager = async (
  inputSignal,
  inputBodyParams,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.delete(
      `/api/depo-fleet?${stringify(inputBodyParams)}`,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// DEPO FLEET CONTROLLER
export const getDepoFleetController = async (
  inputSignal,
  inputQueryParams,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.get(
      `/api/depo-fleet?${stringify(inputQueryParams)}`,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// DEPO FLEET SEARCH
export const postDepoFleetControllerSearch = async (
  inputSignal,
  inputRequestParams,
  inputBodyParams,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      `/api/depo-fleet/search?${stringify(inputRequestParams)}`,
      inputBodyParams,
      {
        signal: inputSignal,
        headers: { 'Authorization': `Bearer ${inputToken}` },
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}