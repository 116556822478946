// MUIS
import { grey } from '@mui/material/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnStatusChip: {
    borderRadius: 4,
    textTransform: 'capitalize',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey[100],
  },
  columnIconStatus: {
    width: 8,
    height: 8,
    marginRight: 8,
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  dialogAddOrEditIconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  dialogDateRangePicker: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
    },
  },
  paperTitle: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
}))

export default useStyles