// MUIS
import { Stack, Typography } from '@mui/material'

// MUI ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// STYLES
import useStyles from '../../orderUseStyles'

const FinishOrder = () => {
  const classes = useStyles()
  return (
    <Stack
      height='100%'
      width='100%'
      justifyContent='center'
      alignItems='center'
    >
      <CheckCircleIcon className={classes.checklistIcon}/>
      <Typography 
        sx={{fontWeight: 600}}
        marginBottom='16px'
        variant='subtitle1'
      >
        Finish, the order process has been completed
      </Typography>

      <Typography className={classes.typography4}>
        Return to the order menu to view the transaction status
      </Typography>
    </Stack>
  )
}

export default FinishOrder