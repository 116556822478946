// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))

export default useStyles