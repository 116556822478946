// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  list: {
    marginBottom: 20,
  },
  listItem: {
    padding: 0,
    '& .MuiListItemText-primary' : {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.secondary
    },
    '& .MuiListItemText-secondary' : {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary
    }
  },
  listAvatar: {
    minWidth: 24,
    marginRight: 16,
  },
  listIcon : {
    color: theme.palette.text.secondary
  },
  orderDetailListItem: {
    padding: 0,
    '& .MuiListItemText-primary' : {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary
    },
    '& .MuiListItemText-secondary' : {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.secondary
    }
  },
  invoiceListItem: {
    padding: 0,
    '& .MuiListItemText-primary' : {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary
    },
    '& .MuiListItemText-secondary' : {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
}))

export default useStyles