// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: 16,
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export default useStyles