import { useState } from 'react'
import moment from 'moment'

// COMPONENTS
import ReportDateRangePicker from 'components/DateRangeTimePicker/ReportDateRangePicker'

// MUIS
import { 
  Button,
  Checkbox, 
  Dialog, 
  InputAdornment, 
  Stack, 
  Typography, 
  TextField,
} from '@mui/material'

// MUI ICONS
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// STYLES
import useStyles from '../reportUseStyles'
import useLayoutStyles from 'styles/layoutPrivate'

const Payment = (props) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const { 
    isContentExpanded,
    paymentMethodList,
    setPaymentMethodList,
    setIsCreatingReport,
    setIsCreatingPayment,
  } = props

  const initialDayList = [
    {
      name: 'Mo',
      isActived: false,
    },
    {
      name: 'Tu',
      isActived: false,
    },
    {
      name: 'We',
      isActived: false,
    },
    {
      name: 'Th',
      isActived: false,
    },
    {
      name: 'Fr',
      isActived: false,
    },
    {
      name: 'Sa',
      isActived: false,
    },
    {
      name: 'Su',
      isActived: false,
    },
  ]

  const [label, setLabel] = useState('')
  const [isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen] = useState(false)
  const [dateRangeTimeValue, setDateRangeTimeValue] = useState([null, null])
  const [dateRangeInputValue, setDateRangeInputValue] = useState('')
  const [dayList, setDayList] = useState(initialDayList)

  // HANDLE DATE RANGE PICKER
  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)

    let startDate = moment(newValue[0]).format('DD MMM YYYY')
    let endtDate = moment(newValue[1]).format('DD MMM YYYY')

    setDateRangeInputValue(`${startDate} - ${endtDate}`)
  }

  // HANDLE DAY PICKER
  const handleDayListItemClick = (inputItem) => {
    const newDayList = [...dayList].map((item) => {
      if(item.name === inputItem.name) item.isActived = !item.isActived
  
      return item
    })
  
    setDayList(newDayList)
  }

  // HANDLE PAYMENT METHOD CLICK
  const handlePaymentMethodClick = (inputItem) => {
    const newPaymentMethodList = [...paymentMethodList].map((item) => {
      if(item.name === inputItem.name) item.isChecked = !item.isChecked
      return item
    })
    setPaymentMethodList(newPaymentMethodList)
  }

  // HANDLE RESET FORMS
  const handleResetForms = () => {
    const newPaymentMethodList = [...paymentMethodList].map((item) => {
      item.isChecked = false
      return item
    })

    setPaymentMethodList(newPaymentMethodList)
    setLabel('')
    setDateRangeTimeValue([null, null])
    setDateRangeInputValue('')
    setDayList(initialDayList)
    setIsCreatingPayment(false)
    setIsCreatingReport(false)
  }

  return (
    <Stack
      left={isContentExpanded ? 240 : 40}
      sx={{transition: 'left 0.5s'}}
      className={classes.paymentRoot}
    >
      {/* TITLE */}
      <Stack padding='24px 20px 0px 20px'>
        <Typography className={classes.typography1}>
          Payment
        </Typography>
      </Stack>

      {/* CONTENTS */}
      <Stack 
        spacing={3}
        padding='0px 20px'
      >
        {/* LABEL */}
        <TextField 
          label='Label' 
          variant='standard'
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />

        {/* DATE RANGE */}
        <TextField 
          label='Date range' 
          variant='standard' 
          value={dateRangeInputValue}
          onClick={() => setIsDateRangeTimePickerOpen(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* DAY PICKER */}
        <Stack>
          <Typography className={classes.typography4}>
            Date range
          </Typography>

          <Stack
            justifyContent='space-between'
            direction='row'
            marginTop='8px'
          >
            {dayList.map((item, index) => (
              <Stack
                key={index}
                className={classes.dayPickerContainer}
                border={item.isActived ? '1px solid #F96649' : '1px solid #0000003B'}
                onClick={() => handleDayListItemClick(item)}
              >
                <Typography
                  variant='caption'
                  sx={(theme) => ({color : item.isActived ? theme.palette.primary.main : theme.palette.text.primary})}
                >
                  {item.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* PAYMENT METHOD */}
        <Stack spacing={1}>
          <Typography className={classes.typography4}>
            Payment method
          </Typography>
          <Stack>
            {paymentMethodList.map((item, index) => (
              <Stack
                key={index}
                direction='row'
                alignItems='center'
              > 
                <Checkbox
                  disableRipple
                  size='small'
                  checked={item.isChecked}
                  sx={{marginLeft: '-10px'}}
                  onChange={() => handlePaymentMethodClick(item)}
                />

                <Typography variant='caption'>
                  {item.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* FOOTER */}
        <Stack
          position='absolute'
          bottom={0}
          left={0}
          padding='10px 20px 20px 70px '
          borderTop='1px solid #0000003B'
          width='100%'
          direction='row'
          spacing={2}
        >
          <Button 
            onClick={handleResetForms}
            variant='outlined' 
            size='small'
          >
            Cancel
          </Button>

          <Button 
            variant='contained' 
            size='small'
            onClick={handleResetForms}
          >
            Build Reports
          </Button>
        </Stack>
      </Stack>


      {/* DATE RANGE TIME PICKER DIALOG */}
      <Dialog 
        open={isDateRangeTimePickerOpen}
        onClose={() => setIsDateRangeTimePickerOpen(false)} 
        className={layoutClasses.dialogDateRangePicker}
      >
        <ReportDateRangePicker
          value={dateRangeTimeValue}
          isWithTimePicker={false}
          handleSelectButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelButtonClick={() => setIsDateRangeTimePickerOpen(false)}
        />
      </Dialog>
    </Stack>
  )
}

export default Payment