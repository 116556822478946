// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnActionsButtonEdit: {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.outlinedHoverBackground,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.outlinedHoverBackground, 0.24),
    },
  },
  columnActionsButtonDelete: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.outlinedHoverBackground,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.outlinedHoverBackground, 0.24),
    },
  },
  root: {
    height: 70,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '0px 20px 0px 24px',
  },
  totalRow : {
    marginTop : 25,
    marginRight : 8,
  },
  divider: {
    marginTop: 30,
  },
  formIcon: {
    color: '#00000099',
    marginRight: 12,
    marginTop: 12,
  },
  fleetManagerForm: {
    width: '100%',
  },
  headerCloseIcon: {
    marginLeft: 15,
    marginRight: 16,
    cursor: 'pointer'
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500,
  },
  typography2: {
    fontSize: 14,
    fontWeight: 500,
  },
}))

export default useStyles