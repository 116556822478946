// APIS
import { axiosPrivate } from 'apis/axios'

// QUERY
import { stringify } from 'query-string'

export const postInventoryListSearch = async (
  inputSignal,
  inputRequestParams,
  inputBodyParams, 
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      `/api/inventories/search?${stringify(inputRequestParams)}`,
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}