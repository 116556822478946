import React, { useContext, useState, useEffect } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'

// MUIS
import {
  Autocomplete,
  Button, 
  Divider, 
  FormControlLabel, 
  FormControl,
  Radio, 
  RadioGroup, 
  Switch, 
  Stack, 
  TextField, 
  Typography,
  ListItem, 
} from '@mui/material'

// MUI ICONS
import ApartmentIcon from '@mui/icons-material/Apartment'
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay'
import IconClose from '@mui/icons-material/Close'
import SubjectIcon from '@mui/icons-material/Subject'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

// STYLES
import useStyles from './itemsUseStyles'

// SERVICE
import { 
  getDepoFleetManagerList,
  createNewItem, 
  editItem,
  getFixedItemName,
} from 'services/items'

// UTILITIES
import { addSeparatorsForNumber, getDefaultErrorMessage } from 'utilities/string'

const DialogAddOrEditItem = (props) => {
  const { 
    actionType,
    editOrDuplicateItemData,
    setActionType,
    reloadData,
  } = props

  const classes = useStyles()
  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)
  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const initialItemForm = {
    sku: '',
    name: '',
    description: '',
    sellingPrice: '',
    fleetManager: {},
  }
  
  const [loadStatus, setLoadStatus] = useState(true)
  const [loadType, setLoadType] = useState('unloading')
  const [itemForm,setItemForm] = useState(initialItemForm)
  const [fleetManagerList, setFleetManagerList] = useState([])
  const [itemName, setItemName] = useState(null)
  const [itemNameList, setItemNameList] = useState([])
  
  const handleItemFormChange = (event) => {
    setItemForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const loadDepoFleetList = async (inputIsMounted, inputAbortController) => {
    const resultDepoFLeetList = await getDepoFleetManagerList(inputAbortController.signal, auth.accessToken, '1000')
 
    if(resultDepoFLeetList.status === 200 && inputIsMounted) {
      setFleetManagerList(resultDepoFLeetList.data.rows)
    }
  }

  const loadFixedItemName = async (inputIsMounted, inputAbortController) => {
    const resultItemName = await getFixedItemName(inputAbortController.signal, auth.accessToken)

    if(resultItemName.status === 200 && inputIsMounted) {
      setItemNameList(resultItemName?.data?.rows)
    }
  }

  const handleSaveButtonClick = async () => {
    const abortController = new AbortController()
    let newPayload = {
      item_code: itemName?.itemCode,
      sku: itemForm.sku,
      description: itemForm.description,
      price: itemForm?.sellingPrice?.replaceAll('.','').replaceAll(',',''),
      type: loadType.toUpperCase(),
      status: loadStatus,
      fleetCode: itemForm?.fleetManager?.code
    }

    // CREATE NEW ITEM
    if(actionType === 'NEW_ITEM') {
      const resultCreateNewItem = await createNewItem(abortController.signal, auth.accessToken, newPayload)

      if(resultCreateNewItem.status === 200) {
        setIsDialogAddOrEditOpen(false)
        setItemName(null)
        setItemForm(initialItemForm)
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully create the new item',
        })
        reloadData(true, abortController.signal)
      } else {
        setIsDialogAddOrEditOpen(false)
        setItemName(null)
        setItemForm(initialItemForm)
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Failed to create the new item',
          message: getDefaultErrorMessage(resultCreateNewItem),
        })
      }
    }

    // DUPLICATE ITEM
    if(actionType === 'DUPLICATE_ITEM') {
      const resultCreateNewItem = await createNewItem(abortController.signal, auth.accessToken, newPayload)

      if(resultCreateNewItem.status === 200) {
        setIsDialogAddOrEditOpen(false)
        setItemName(null)
        setItemForm(initialItemForm)
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully create the new item',
        })
        reloadData(true, abortController.signal)
      } else {
        setIsDialogAddOrEditOpen(false)
        setItemName(null)
        setItemForm(initialItemForm)
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Failed to create the new item',
          message: getDefaultErrorMessage(resultCreateNewItem),
        })
      }
    }

    // EDIT ITEM
    if(actionType === 'EDIT_ITEM') {
      const resultEditItem = await editItem(
        abortController.signal, 
        auth.accessToken, 
        newPayload, 
        editOrDuplicateItemData.id
      )

      if(resultEditItem.status === 200) {
        setIsDialogAddOrEditOpen(false)
        setItemName(null)
        setItemForm(initialItemForm)
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully update the item',
        })
        reloadData(true, abortController.signal)
      } else {
        setIsDialogAddOrEditOpen(false)
        setItemForm(initialItemForm)
        setItemName(null)
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Failed to update the item',
          message: getDefaultErrorMessage(resultEditItem),
        })
      }
    }

    abortController.abort()
  }

  useEffect(() => {
    if(editOrDuplicateItemData !== null && (actionType === 'EDIT_ITEM' || actionType === 'DUPLICATE_ITEM')) {
      // SET LOAD STATUS
      setLoadStatus(editOrDuplicateItemData?.status)

      // SET LOAD TYPE
      setLoadType(editOrDuplicateItemData?.type?.toLowerCase())

      // SET ITEM NAME
      setItemName(editOrDuplicateItemData.item_name)
      
      // SET ITEM FORM
      let newInitialItemForm = {
        sku: editOrDuplicateItemData?.sku,
        description: editOrDuplicateItemData?.description,
        sellingPrice: addSeparatorsForNumber(editOrDuplicateItemData?.price),
        fleetManager: editOrDuplicateItemData?.fleet
      }
      setItemForm(newInitialItemForm)
    }
  }, [editOrDuplicateItemData, actionType])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoFleetList(isMounted, abortController)
    loadFixedItemName(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])
  
  return (
    <DialogAddOrEdit containerWidth ='704px'>
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        <Stack direction='row' marginTop='12px'>
          {/* CLOSE ICON */}
          <IconClose
            className={classes.headerCloseIcon}
            onClick={() => {
              setIsDialogAddOrEditOpen(false)
              setItemForm(initialItemForm)
              setActionType('NEW_ITEM')
            }}
          />

          {/* TITLE */}
          <Typography className='textSemiBold'>
            {actionType === 'EDIT_ITEM' ? 'Edit Items' : 'Add New Items'}
          </Typography>
        </Stack>
      </CustomDialogTitle>

      <Divider className={classes.divider}/>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        <Stack padding='8px 20px'>
          {/* INFORMATION */}
          <Typography className={classes.typography1}>
            Information
          </Typography>

          {/* TYPE & STATUS */}
          <Stack 
            marginTop='28px'
            direction='row'
          >
            {/* TYPE */}
            <Stack 
              direction='row'
              alignItems='center'
              marginRight='55px'
            >
              <Typography 
                marginRight='24px'
                className={classes.typography2}
              >
                Type
              </Typography>

              {/* RADIO GROUP */}
              <FormControl>
                <RadioGroup
                  row
                  name='loadingType'
                  value={loadType}
                  onChange={(event) => setLoadType(event.target.value)}
                >
                  <FormControlLabel 
                    value='unloading' 
                    control={<Radio size='small'/>} 
                    label='Unloading'
                    sx={{color: '#0000008A'}}
                  />
                  <FormControlLabel 
                    value='loading' 
                    control={<Radio size='small'/>} 
                    label='Loading'
                    sx={{color: '#0000008A'}}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>

            {/* STATUS */}
            <Stack 
              direction='row'
              alignItems='center'
            >
              <Typography 
                marginRight='24px'
                className={classes.typography2}
              >
                Status
              </Typography>

              <FormControl>
                <FormControlLabel 
                  control={<Switch checked={loadStatus} onChange={(event) => setLoadStatus(event.target.checked)}/>}
                  label={loadStatus ? 'Active' : 'Inactive'}
                /> 
              </FormControl>
            </Stack>
          </Stack>

          {/* FLEET MANAGER */}
          <Stack
            direction='row'
            alignItems='center'  
            marginTop='39px'       
          >
            <ApartmentIcon className={classes.formIcon}/>
            <Autocomplete
              disablePortal
              options={fleetManagerList}
              getOptionLabel={(option) => option.fleet_manager_company}
              value={itemForm.fleetManager.name ? itemForm.fleetManager : null}
              sx={{ width: '100%' }}
              onChange={(event,value) => {
                setItemForm(current => ({
                  ...current,
                  fleetManager : value,
                }))
              }}
              renderInput={(params) => <TextField {...params} variant='standard' label='Fleet Manager' />}
              renderOption={(props, option, { selected }) => (
                <ListItem {...props}>
                  <Stack
                    height='63px'
                    width='100%'
                    padding='12px 0px'
                  >
                    {/* FLEET NAME & CITY */}
                    <Stack 
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography variant='body2'>
                        {option.fleet_manager_company?? '-'}
                      </Typography>
                      <Typography variant='body2'>
                        {option.city?? '-'}
                      </Typography>
                    </Stack>

                    {/* FLEET CODE & COUNTRY  */}
                    <Stack 
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography 
                        variant='caption'
                        sx={(theme) => ({color: theme.palette.text.secondary})}
                      >
                        {option.code?? '-'}
                      </Typography>
                      <Typography 
                        variant='caption'
                        sx={(theme) => ({color: theme.palette.text.secondary})}
                      >
                        {option.country?? '-'}
                      </Typography>
                    </Stack>

                  </Stack>
                </ListItem>
              )}
            />
          </Stack>

          {/* SKU & NAME */}
          <Stack
            direction='row'
            justifyContent='space-between'
          >
            
            {/* NAME */}
            <Stack
              direction='row'
              alignItems='center'  
              marginTop='39px'
              width='48%'        
            >
              <TextFormatIcon className={classes.formIcon}/>
              <Autocomplete
                disablePortal
                options={itemNameList}
                getOptionLabel={(option) => `${option.itemCode} - ${option.itemType}`}
                value={itemName}
                sx={{ width: '100%' }}
                onChange={(event,value) => {setItemName(value)}}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} variant='standard' label='Name' />}
                renderOption={(props, option, { selected }) => (
                  <ListItem {...props}>
                    <Stack
                      height='50px'
                      width='100%'
                      justifyContent='center'
                    >
                      {/* ITEM CODE & TYPE */}
                      <Typography variant='body2'>
                        {option.itemCode} - {option.itemType}
                      </Typography>

                      {/* SIZE  */}
                      <Typography 
                        variant='caption'
                        sx={(theme) => ({color: theme.palette.text.secondary})}
                      >
                        Size {option.itemSize}
                      </Typography>
                    </Stack>
                  </ListItem>
                )}
              />
            </Stack>

            {/* SKU */}
            <Stack
              direction='row'
              alignItems='center'  
              marginTop='39px'
              width='48%'        
            >
              <CalendarViewDayIcon className={classes.formIcon}/>
              <TextField
                label='SKU'
                variant='standard'
                name='sku'
                value={itemForm.sku}
                onChange={(event) => handleItemFormChange(event)}
                className={classes.skuForm}
              />
            </Stack>
          </Stack>

          {/* DESCRIPTION */}
          <Stack
            direction='row'
            alignItems='center'  
            marginTop='39px'       
          >
            <SubjectIcon className={classes.formIcon}/>
            <TextField
              label='Description'
              variant='standard'
              name='description'
              value={itemForm.description}
              onChange={(event) => handleItemFormChange(event)}
              className={classes.skuForm}
            />
          </Stack>

          {/* SELLING PRICE */}
          <Stack
            direction='row'
            alignItems='center'  
            marginTop='39px'       
          >
            <LocalOfferIcon className={classes.formIcon}/>
            <TextField
              label='Selling Price (IDR)'
              variant='standard'
              name='sellingPrice'
              value={itemForm.sellingPrice}
              onChange={(event) => handleItemFormChange(event)}
              className={classes.skuForm}
            />
          </Stack>

        </Stack>
      </CustomDialogContent>

      {/* DIALOG ACTION */}
      <CustomDialogActions>
        <Stack 
          direction='row'
          spacing={2}
        >
          {/* CANCEL BUTTON */}
          <Button 
            variant='outlined'
            onClick={() => {
              setIsDialogAddOrEditOpen(false)
              setItemForm(initialItemForm)
            }}
          >
            Cancel
          </Button>

          {/* SAVE BUTTON */}
          <Button 
            variant='contained'
            onClick={() => handleSaveButtonClick()}
          >
            Save
          </Button>
        </Stack>
      </CustomDialogActions>
    </DialogAddOrEdit>
  )
}

export default DialogAddOrEditItem