import PropTypes from 'prop-types'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

// RAMDA
import { isEmpty } from 'ramda'

// STYLES
import useStyles from './dialogConfirmationUseStyles'

const DialogConfirmation = (props) => {
  const classes = useStyles()

  const {
    title,
    caption,
    dialogConfirmationObject,
    setDialogConfirmationObject,
    cancelButtonText,
    continueButtonText,
    onContinueButtonClick,
    onCancelButtonClick,
  } = props

  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') return {}
    else setDialogConfirmationObject({})
  }

  return (
    <Dialog
      open={!isEmpty(dialogConfirmationObject)}
      onClose={handleDialogClose}
      className={classes.root}
    >
      {/* TITLE */}
      <DialogTitle className='padding0'>
        <Typography variant='h6'>
          {title}
        </Typography>
      </DialogTitle>

      {/* CAPTION */}
      <DialogContent className={classes.dialogContent}>
        <Typography variant='subtitle1'>
          {caption}
        </Typography>
      </DialogContent>

      {/* DIALOG ACTIONS */}
      <DialogActions className='padding0'>
        <Stack direction='row' spacing={2}>
          {/* CANCEL BUTTON */}
          <Button 
            variant='outlined'
            onClick={onCancelButtonClick}
            className='flex1'
          >
            {cancelButtonText}
          </Button>

          {/* CONTINUE BUTTON */}
          <Button 
            variant='contained'
            onClick={onContinueButtonClick}
          >
            {continueButtonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirmation.defaultProps = {
  title: '',
  caption: '',
  dialogConfirmationObject: false,
  cancelButtonText: 'Cancel',
  continueButtonText: 'Delete',
}

DialogConfirmation.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  dialogConfirmationObject: PropTypes.object.isRequired,
  setDialogConfirmationObject: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  continueButtonText: PropTypes.string.isRequired,
  onContinueButtonClick: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
}

export default DialogConfirmation