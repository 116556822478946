// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 0,
  },
  fab: {
    marginRight: 12,
  },
  search: {
    overflow: 'hidden',
    marginRight: 8,
  },
  searchInputWide: {
    width: 250,
    transition: 'width 0.25s ease-in-out',
  },
  searchInputNarrow: {
    width: 0,
    transition: 'width 0.25s ease-in-out',
  },
  speedDialTooltip: {
    '& .MuiSpeedDialAction-staticTooltipLabel' : {
      display: 'none'
    }
  }
}))

export default useStyles