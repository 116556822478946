// UTILITIES
import { generateOptionList } from 'utilities/list'

const initialConditionOptionList = [
  'available', 
  'damaged', 
]

const initialProcessTypeOptionList = [
  'gate_in', 
  'gate_out', 
  'gate_in_empty', 
  'gate_out_empty', 
  'gate_in_repo', 
  'gate_out_repo',
]

const initialDamagedByOptionList = [
  'principle', 
  'user', 
]

export const movesConstants = {
  cleanOptionList: [
    {
      text: 'Yes',
      value: true,
    },
    {
      text: 'No',
      value: false,
    },
  ],
  conditionOptionList: generateOptionList(initialConditionOptionList),
  damagedByOptionList: generateOptionList(initialDamagedByOptionList),
  processTypeOptionList: generateOptionList(initialProcessTypeOptionList),
  sourceOptionList: [
    {
      text: 'FTP Server',
      value: 'FTP_Server',
    },
    {
      text: 'Mobile',
      value: 'Mobile',
    },
    {
      text: 'API',
      value: 'API',
    },
  ],
}