import { useContext, useEffect, useState } from 'react'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// DATE RANGE PICKER
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

// MUIS
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  IconButton,
  Stack, 
  TextField,
  Typography,
  Button,
} from '@mui/material'

// MUI ICONS
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import CategoryIcon from '@mui/icons-material/Category'
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import DateRangeIcon from '@mui/icons-material/DateRange'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import MapIcon from '@mui/icons-material/Map'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import SaveIcon from '@mui/icons-material/Save'
import StyleIcon from '@mui/icons-material/Style'

// SERVICE
import { getDepoListItem } from 'services/order'

// STYLES 
import useStyles from '../../orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const EditOrderDetails = (props) => {
  const { setIsEditingOrderDetails } = props
  const classes = useStyles()

  const { 
    orderForms, setOrderForms,
    orderListItems, setOrderListItems
  } = useContext(PrivateLayoutContext)

  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const bookingTypeList = [
    {
      label : 'Unloading'
    },
    {
      label : 'Loading'
    },
  ]

  const [depoListItem, setDepoListItem] = useState([])
  const [bookingType, setBookingType] = useState(true)
  const [isValidatingForms, setIsValidatingForms] = useState(false)

  // LOAD DEPO LIST ITEM
  const loadDepoListItem = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: auth?.id,
      type: orderForms.booking.label === 'Unloading' ? 'UNLOADING' : 'LOADING'
    }
    const resultDepoListItem = await getDepoListItem(inputAbortController.signal, queryParams)
    if(resultDepoListItem.status === 200 && inputIsMounted) {
      setDepoListItem(resultDepoListItem.data)
    }
  }

  // HANDLE ORDER FORM CHANGE
  const handleOrderFormsChange = (event) => {
    setOrderForms(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // ADD NEW CONTAINER LIST
  const handleAddNewContainerList = () => {
    let newForm = {
      containerNo: '',
      containerType: '',
      quantity: 1,
      id:  Math.floor(Math.random() * 1000000)
    }
    setOrderListItems(current => [...current, newForm])
  }
  
  // DELETE CONTAINER LIST
  const deleteContainerList = (inputIndex) => {
    const newList = [...orderListItems]
    newList.splice(inputIndex,1)
    setOrderListItems(newList)
  }

  // HANDLE CONTAINER NO CHANGE
  const handleContainerNoChange = (inputItem,inputEvent) => {
    const newList = [...orderListItems].map((item) => {
      if(item.id === inputItem.id) item.containerNo = inputEvent.target.value
      return item
    })
    setOrderListItems(newList)
  }

  // HANDLE QUANTITY CHANGE
  const handleQuantityChange = (inputItem,inputEvent) => {
    const newList = [...orderListItems].map((item) => {
      if(item.id === inputItem.id) item.quantity = inputEvent.target.value
      return item
    })
    setOrderListItems(newList)
  }
  
  // HANDLE ITEMS CHANGE
  const handleContainerTypeChange = (inputItem, inputValue) => {
    const newList = [...orderListItems].map((item) => {
      if(item.id === inputItem.id) {
        item.value =  inputValue
        item.tempPrice = inputValue?.price
        item.price = inputValue?.price
        item.quantity = 1
      }
      return item
    })
    setOrderListItems(newList)
  }

  // HANDLE BOOKING TYPE CHANGE
  const handleBookingTypeChange = (value) => {
    setBookingType(current => !current)
    setOrderForms(current => ({...current, booking : value}))
    setOrderListItems([
      {
        containerNo: '',
        value: '',
        quantity: 1,
        id: 1,
      }
    ])
  }

  // HANDLE FORMS VALIDATION
  const handleFormsValidation = () => {
    // CHECK IS ITEMS CHOOSED & CHECK CONTAINER NUMBER FIELD
    let isItemChoosed = true
    orderListItems.forEach((item) => {
      if((orderForms.booking.label === 'Unloading' && item.containerNo === '') || !item.value) isItemChoosed = false
    })
 
    // VALIDATING ALL FORMS
    if(
      orderForms.bolNo === '' || orderForms.consignee === '' ||
      orderForms.npwp === '' || orderForms.npwpAddress === '' || 
       !orderForms.date || !isItemChoosed
    ) {
      setIsValidatingForms(true)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Required fields must be filled in',
      })
    } else {
      setIsValidatingForms(false)
      setIsEditingOrderDetails(false)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListItem(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [bookingType])

  return (
    <>
      {/* TITLE */}
      <Stack
        marginTop='20px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography className={classes.typography1}>
          Order Details
        </Typography>

        <Button 
          startIcon={<SaveIcon/>}
          variant='outlined'
          onClick={handleFormsValidation}
        >
          Save
        </Button>
      </Stack>

      {/* BOOKING & DATE */}
      <Stack
        direction='row'
        justifyContent='space-between' 
        marginTop='20px'
      >
            
        {/* BOOKING */}
        <Stack
          direction='row'
          alignItems='center' 
          width='48%'        
        >
          <CategoryIcon className={classes.formIcon}/>
          <Autocomplete
            fullWidth
            disablePortal
            options={bookingTypeList}
            value={orderForms.booking || null}
            onChange={(event, value) => handleBookingTypeChange(value)}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={(params) => <TextField {...params} variant='standard' label='Booking' />}
          />
        </Stack>

        {/* DATE */}
        <Stack
          direction='row'
          alignItems='center'
          width='48%'        
        >
          <DateRangeIcon className={classes.formIcon}/>
          <FormControl 
            fullWidth
            error
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label='Date mobile'
                inputFormat='dd MMMM yyyy'
                value={orderForms?.date}
                onChange={(newValue) => setOrderForms(current => ({...current, date: newValue}))}
                renderInput={(params) => <TextField fullWidth {...params} variant='standard' label='Date' />}
              />
            </LocalizationProvider>
            {/* HELPER TEXT */}
            {(isValidatingForms && !orderForms.date) && 
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>

      {/* BOL NO & CONSIGNEE */}
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop='20px'
      >
        {/* BOL NO */}
        <Stack
          direction='row'
          alignItems='center'  
          width='48%'        
        >
          <BookmarkIcon className={classes.formIcon}/>
          <FormControl 
            fullWidth
            error
          >
            <TextField
              fullWidth
              label={orderForms.booking.label === 'Unloading' ? 'Bill of Lading No.' : 'Delivery Order No.'}
              variant='standard'
              name='bolNo'
              value={orderForms.bolNo}
              onChange={(event) => handleOrderFormsChange(event)}
              error={(isValidatingForms && orderForms.bolNo === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && orderForms.bolNo === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>

        {/* CONSIGNEE */}
        <Stack
          direction='row'
          alignItems='center'  
          width='48%'        
        >
          <ApartmentIcon className={classes.formIcon}/>
          <FormControl 
            fullWidth
            error
          >
            <TextField
              fullWidth
              label={orderForms.booking.label === 'Unloading' ? 'Consignee' : 'Shipper'}
              variant='standard'
              name='consignee'
              value={orderForms.consignee}
              onChange={(event) => handleOrderFormsChange(event)}
              error={(isValidatingForms && orderForms.consignee === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && orderForms.consignee === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>

      {/* NPWP & NPWP ADDRESS */}
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop='20px'
      >
        {/* NPWP */}
        <Stack
          direction='row'
          alignItems='center'  
          width='48%'        
        >
          <FormatListNumberedIcon className={classes.formIcon}/>
          <FormControl 
            fullWidth
            error
          >
            <TextField
              type='number'
              className={classes.fieldNumber}
              fullWidth
              label='NPWP'
              variant='standard'
              name='npwp'
              value={orderForms.npwp}
              onChange={(event) => handleOrderFormsChange(event)}
              error={(isValidatingForms && orderForms.npwp === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && orderForms.npwp === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>

        {/* NPWP ADDRESS */}
        <Stack
          direction='row'
          alignItems='center'  
          width='48%'        
        >
          <MapIcon className={classes.formIcon}/>
          <FormControl 
            fullWidth
            error
          >
            <TextField
              fullWidth
              label='NPWP Company Address'
              variant='standard'
              name='npwpAddress'
              value={orderForms.npwpAddress}
              onChange={(event) => handleOrderFormsChange(event)}
              error={(isValidatingForms && orderForms.npwpAddress === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && orderForms.npwpAddress === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>

      {/* ITEMS */}
      {orderListItems.map((item, index) => (
        <Stack
          key={index}
          marginTop='20px'
          spacing={2}
        >
          {/* LIST ITEMS */}
          <Stack
            direction='row'
            alignItems='center'        
          >
            <Stack
              direction='row'
              alignItems='center'
              width='91%'
            >
              <StyleIcon className={classes.formIcon}/>
              <FormControl 
                fullWidth
                error
              >
                <Autocomplete
                  fullWidth
                  disablePortal
                  options={depoListItem}
                  value={item?.value || null}
                  onChange={(event, value) => handleContainerTypeChange(item, value)}
                  isOptionEqualToValue={(options, value) => options?.item_name?.itemCode === value.item_name?.itemCode}
                  renderInput={(params) => <TextField {...params} variant='standard' label='Items' />}
                  getOptionLabel={(option) => `${option.item_name.itemCode} - ${option.item_name.itemType}`}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Stack width='100%'>
                        {/* ITEM NAME & PRICE */}
                        <Stack 
                          direction='row'
                          justifyContent='space-between'
                        >
                          <Typography 
                            variant='body2'
                            sx={{fontWeight: 500}}
                          >
                            {option?.item_name?.itemCode} - {option?.item_name?.itemType}
                          </Typography>

                          <Typography variant='caption'>
                            {`Rp. ${addSeparatorsForNumber(option.price)}`}
                          </Typography>
                        </Stack>

                        {/* FLEET MANAGER */}
                        <Typography variant='caption'>
                          {option?.fleet?.fleet_manager_company}
                        </Typography>

                        {/* SKU */}
                        <Typography variant='caption'>
                          {option.sku}
                        </Typography>
                      </Stack>
                    </li>
                  )}
                />
                {/* HELPER TEXT */}
                {(isValidatingForms && !item.value) && 
                <FormHelperText>This field can't be empty</FormHelperText>}
              </FormControl>

            </Stack>
            {/* COUNTER BUTTON */}
            <Stack
              direction='row'
              alignItems='center'
            >
              {/* REMOVE CONTAINER */}
              <IconButton 
                onClick={() => deleteContainerList(index)}
                disabled={orderListItems.length > 1 ? false : true}
                sx={(theme) => ({color: theme.palette.primary.main})}
              >
                <RemoveCircleOutlineIcon/>
              </IconButton>

              {/* ADD CONTAINER */}
              <IconButton 
                onClick={() => handleAddNewContainerList()}
                sx={(theme) => ({color: theme.palette.primary.main})}
              >
                <AddCircleOutlineIcon/>
              </IconButton>
            </Stack>
          </Stack>

          {/* CONTAINER NO / QUANTITY */}
          {orderForms.booking.label === 'Unloading' ? 
          // CONTAINER NO
            <Stack
              direction='row'
              alignItems='center'       
            >
              <ChromeReaderModeIcon className={classes.formIcon}/>
              <FormControl 
                fullWidth
                error
              >
                <TextField
                  fullWidth
                  label='Container No'
                  variant='standard'
                  name='containerNo'
                  value={item?.containerNo}
                  onChange={(event) => handleContainerNoChange(item,event)}
                  error={(isValidatingForms && item.containerNo === '') ? true : false}
                />
                {/* HELPER TEXT */}
                {(isValidatingForms && item.containerNo === '') && 
                <FormHelperText>This field can't be empty</FormHelperText>}
              </FormControl>
            </Stack> : 

          // QUANTITY
            <Stack
              direction='row'
              alignItems='center'       
            >
              <ControlPointDuplicateIcon className={classes.formIcon}/>
              <TextField
                fullWidth
                disabled={item?.value?.id === undefined ? true : false}
                type='number'
                inputProps={{min: 1}}
                label='Quantity'
                variant='standard'
                name='quantity'
                value={item?.quantity}
                onChange={(event) => handleQuantityChange(item,event)}
              />
            </Stack>
          }
        </Stack>
      ))}
    </>
  )
}

export default EditOrderDetails