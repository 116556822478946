// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  totalMove : {
    fontSize : 34,
    fontWeight : 400
  },
  textButton : {
    fontWeight : 600,
    fontSize : 13,
  },
  listItem : {
    borderRadius : 4,
    padding : '6px 0px',
    height : 30,
    marginLeft : 15,
    width : 'max-content',
  },
  checkIcon : {
    minWidth : 34,
    color : theme.palette.primary.main
  },
  listItemButton : {
    '&:hover' : {
      backgroundColor : 'transparent !important'
    }
  }
}))

export default useStyles