// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[3],
    background: 'white',
    height: '96%',
    position: 'absolute',
    top: 18,
    borderRadius: 4,
  },
  typography1: {
    fontSize: 16,
    fontWeight: 600,
  },
  typography2: {
    fontSize: 12,
    fontWeight: 600,
  },
  typography3: {
    fontSize: 12,
    fontWeight: 500,
  },
  headerIcon: {
    color: theme.palette.primary.main
  },
  summaryContainer: {
    width: 333,
    height: 74,
    border: '1px solid #0000001F',
    marginLeft: 20,
    marginTop: 20,
  }
}))

export default useStyles