// CSV IMPORTER
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'

// FILES
import ExampleFile from 'files/gate_move_csv_example.csv'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dialogImportCSVUseStyles'

const ImportCsvComponent = (props) => {
  
  const {setListData,
    setIsDataImported,
  } = props

  const classes = useStyles()

  const listFields= [
    {
      name: 'Status',
    },
    {
      name: 'Booking',
    },
    {
      name: 'Transaction Date',
    },
    {
      name: 'Bill of Lading No.',
    },
    {
      name: 'Customer Name',
    },
    {
      name: 'Invoice',
    },
    {
      name: 'Total Amount (IDR)',
    },
  ]

  return (
    <Stack padding='16px 24px' height='520px' sx={{overflowY: 'scroll'}}>
      <Typography variant='body2'>
        You can import TDR List by uploading CSV file with data. If file contains a header fields, they will be detected automatically, otherwise, you can specify them manually.
      </Typography>

      {/* EXAMPLE FILE */}
      <a
        href='#'
        // download='Example_File'
        // target='_blank'
        // rel='noreferrer'
      >
        <Typography 
          variant='body2'
          className={classes.fileExample}
        >
          File Example
        </Typography>
      </a>
      <br/>

      <Typography variant='body2'>
        Following fields are available for import:
      </Typography>

      {/* LIST FIELDS */}
      <Stack paddingLeft='14px' marginTop='12px' direction='row'>
        <Stack>
          {listFields.slice(0,4).map((item,index)=> (
            <Stack key={index} direction='row' alignItems='center'>
              <Stack
                width='4px'
                height='4px'
                marginRight='4px'
                borderRadius='50px'
                sx={{backgroundColor: 'black'}}
              />
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Stack marginLeft='48px'>
          {listFields.slice(4,10).map((item,index)=> (
            <Stack key={index} direction='row' alignItems='center'>
              <Stack
                width='4px'
                height='4px'
                marginRight='4px'
                borderRadius='50px'
                sx={{backgroundColor: 'black'}}
              />
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <br/>

      <Typography variant='body2' sx={{fontWeight: 600}} >
        Import CSV File (maximum size 10 Mb) :
      </Typography>

      {/* IMPORTER */}
      <Stack
        padding='24px 24px 0px 24px'
        direction='row'
        justifyContent='center'
      >
        <Stack width='100%' className={`${classes.importer} no-zoom`}>
          <Importer
            chunkSize={10000}
            assumeNoHeaders={false}
            restartable={false}
            processChunk={async (rows) => {
              setListData(rows)
            }}
            onClose={() => {
              setIsDataImported(true)
            }}
          >
            <ImporterField name='status' label='Status' />
            <ImporterField name='booking' label='Booking' />
            <ImporterField name='tx_date' label='Transaction Date' />
            <ImporterField name='bolNo' label='Bill of Lading No' />
            <ImporterField name='customer' label='Customer Name' />
            <ImporterField name='invoice' label='Invoice' />
            <ImporterField name='amount' label='Total Amount (IDR)' />
          </Importer>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ImportCsvComponent