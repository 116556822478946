import { useState } from 'react'

// MUIS
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

const Form1 = (props) => {
  const { 
    form, handleFormChange, 
    errorForm,
  } = props

  const layoutClasses = useLayoutStyles()

  const [ showPassword, setShowPassword ] = useState(false)

  return (
    <>
      {/* TITLE */}
      <Typography 
        variant='h6'
        className={layoutClasses.pageTitle}
      >
        Sign Up
      </Typography>

      {/* EMAIL FORM */}
      <FormControl 
        variant='standard'
        className={layoutClasses.formInput}
        color='secondary'
        error={Boolean(errorForm.email)}
      >
        {/* EMAIL INPUT LABEL */}
        <InputLabel>
          Company Email
        </InputLabel>

        {/* EMAIL INPUT */}
        <Input 
          autoFocus
          type='email' 
          name='email' 
          value={form.email} 
          onChange={handleFormChange}
        />

        {/* EMAIL HELPER TEXT */}
        {Boolean(errorForm.email) &&
        <FormHelperText>
          {errorForm.email}
        </FormHelperText>}
      </FormControl>

      {/* PHONENUMBER FORM */}
      <FormControl 
        variant='standard'
        className={layoutClasses.formInput}
        color='secondary'
        error={Boolean(errorForm.phoneNumber)}
      >
        {/* PHONENUMBER INPUT LABEL */}
        <InputLabel>
          Phone Number
        </InputLabel>

        {/* PHONENUMBER INPUT */}
        <Input 
          type='text' 
          name='phoneNumber' 
          value={form.phoneNumber} 
          onChange={handleFormChange}
        />

        {/* PHONE NUMBER HELPER TEXT */}
        {Boolean(errorForm.phoneNumber) &&
        <FormHelperText>
          {errorForm.phoneNumber}
        </FormHelperText>}
      </FormControl>

      {/* PASSWORD FORM */}
      <FormControl 
        variant='standard'
        className={layoutClasses.formInput}
        color='secondary'
        error={Boolean(errorForm.password)}
      >
        {/* PASSWORD INPUT LABEL */}
        <InputLabel>
          Password
        </InputLabel>

        {/* PASSWORD INPUT */}
        <Input 
          type={showPassword ? 'text' : 'password'}
          name='password'
          value={form.password}
          onChange={handleFormChange}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={() => setShowPassword(current => !current)}
                edge='end'
              >
                {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
              </IconButton>
            </InputAdornment>
          }
        />

        {/* PASSWORD HELPER TEXT */}
        {Boolean(errorForm.password) &&
        <FormHelperText>
          {errorForm.password}
        </FormHelperText>}
      </FormControl>

      {/* ADDRESS INPUT */}
      <FormControl 
        variant='standard'
        className={layoutClasses.formInput}
        color='secondary'
        error={Boolean(errorForm.email)}
      >
        {/* EMAIL INPUT LABEL */}
        <InputLabel>
          Address Company
        </InputLabel>

        {/* EMAIL INPUT */}
        <Input 
          autoFocus
          type='text' 
          name='address' 
          value={form.address} 
          onChange={handleFormChange}
        />

        {/* ADDRESS HELPER TEXT */}
        {Boolean(errorForm.email) &&
        <FormHelperText>
          {errorForm.address}
        </FormHelperText>}
      </FormControl>
    </>
  )
}

export default Form1