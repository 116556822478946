// MUI ICONS
import AnalyticsIcon from '@mui/icons-material/Analytics'
import IconDownload from '@mui/icons-material/Download'
import IconHome from '@mui/icons-material/Home'
import IconInventory from '@mui/icons-material/Inventory'
import IconSmartphone from '@mui/icons-material/Smartphone'
import IconApartment from '@mui/icons-material/Apartment'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'

export const drawerNavigationList = [
  {
    title: 'Home',
    icon: IconHome,
    path: '/',
  },
  {
    title: 'Moves',
    icon: IconDownload,
    path: '/moves',
  },
  {
    title: 'Inventory',
    icon: IconInventory,
    path: '/inventory',
  },
  {
    title: 'Report',
    icon: AnalyticsIcon,
    path: '/report',
  },
  {
    title: 'Devices',
    icon: IconSmartphone,
    path: '/devices',
  },
  {
    title: 'Fleet manager',
    icon: IconApartment,
    path: '/fleet-manager',
  },
  {
    title: 'Orders',
    icon: WysiwygIcon,
    path: '/orders',
  },
  {
    title: 'Items',
    icon: AllInboxIcon,
    path: '/items',
  },
]
