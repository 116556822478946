export const values = {
  // ALL PAGES
  fontFamily: [ 'Poppins', 'sans-serif' ].join(','),

  // DRAWER
  drawerWidth: 256,
  drawerWidthOnMinimized: 80,
  
  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },

  // ORDERS
  initialOrderForms: {
    name: '',
    phoneNumber: '',
    email: '',
    booking: {label: 'Unloading'},
    date: '',
    bolNo: '',
    npwp: '',
    npwpAddress: '',
    consignee: '',
    paymentMethod: '',
  },
  
  initialOrderListItems: [
    {
      containerNo: '',
      value: '',
      quantity: 1,
      id: 1,
    }
  ]
}