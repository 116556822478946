// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      padding: 24,
      width: 444,
      borderRadius: 4,
    },
  },
  dialogContent: {
    margin: '24px 0px',
    padding: 0,
  },
}))

export default useStyles
