import {useContext} from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

// SERVICE
import { createDepoBankAccount } from 'services/profile'

// STYLES
import useStyles from './dialogAddOrChangeBankAccountUseStyles'

const DialogAddOrChangeBankAccount = (props) => {
  const classes = useStyles()
  const { auth, setSnackbarObject } = useContext(AllPagesContext)

  const { 
    isDialogBankAccountOpen, 
    setIsDialogBankAccountOpen,
    accountBankForms,
    setAccountBankForms,
    reloadData,
  } = props

  const initialForms = {
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    description: '',
  }

  // LIST OF BANKS
  const listOfBanks = [
    {
      label: 'PT. BCA (Bank Central Asia) TBK / BCA',
      value: 'BCA',
    },
    {
      label: 'PT. Bank Mandiri / Mandiri',
      value: 'MANDIRI',
    },
    {
      label: 'PT. Bank Negara Indonesia (BNI) (Persero) / BNI',
      value: 'BNI',
    },
    {
      label: 'PT. Bank RAKYAT Indonesia (BRI) (Persero) / BRI',
      value: 'BRI',
    },
    {
      label: 'PT. Bank SYARIAH Indonesia / BSI',
      value: 'BSI',
    },
    {
      label: 'PT. Bank PERMATA TBK / PERMATA',
      value: 'PERMATA',
    },
    {
      label: 'PT. Bank JABAR DAN BANTEN / BJB',
      value: 'BJB',
    },
  ]

  // HANDLE CLOSE DIALOG
  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') return {}
    else setIsDialogBankAccountOpen(false)
  }

  // HANDLE ACCOUNT BANK FORMS CHANGE
  const handleFormsChange = (event) => {
    setAccountBankForms(current => {
      return {
        ...current,
        [event.target.name] : event.target.value
      }
    })
  }

  // HANDLE DISABLE SAVE BUTTON
  const handleDisableSaveButton = () => {
    if(
      accountBankForms.bankName === '' || accountBankForms.accountHolderName === '' ||
      accountBankForms.accountNumber === '' || accountBankForms.description === ''
    ) return true
    else return false 
  }

  // HANDLE SAVE BUTTON 
  const handleSaveButton = async () => {
    const abortController = new AbortController()
    let bodyParams = {
      bank_code: accountBankForms.bankName.value,
      account_holder_name: accountBankForms.accountHolderName,
      account_number: accountBankForms.accountNumber,
      description_bank: accountBankForms.description,
      default_bank: true
    }
    
    const resultCreateDepoBankAccount = await createDepoBankAccount(abortController.signal, auth.accessToken, bodyParams)
    if(resultCreateDepoBankAccount.status === 200) {
      setIsDialogBankAccountOpen(false)
      setAccountBankForms(initialForms)
      reloadData(true, abortController.signal)
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully update Depo Bank Account',
      })
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to update Depo Bank Account',
      })
    }
  }

  return (
    <Dialog
      className={classes.root}
      open={isDialogBankAccountOpen}
      onClose={handleDialogClose}
    >
      {/* TITLE */}
      <DialogTitle className='padding0'>
        <Typography variant='h6'>
          Add Bank Account
        </Typography>
      </DialogTitle>

      {/* CONTENT */}
      <DialogContent className={classes.dialogContent}>
        {/* BANK NAME */}
        <Autocomplete
          disableClearable
          value={accountBankForms.bankName || null}
          name='bankName'
          options={listOfBanks}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(event, value) => setAccountBankForms(current => {
            return {
              ...current,
              bankName : value
            }
          })}
          renderInput={(params) => <TextField {...params} label='Bank Name' variant='standard' />}
        />

        {/* ACCOUNT HOLDER NAME */}
        <TextField 
          label='Account Holder Name' 
          value={accountBankForms.accountHolderName}
          name='accountHolderName'
          variant='standard' 
          fullWidth
          sx={{marginTop: '10px'}}
          onChange={(event) => handleFormsChange(event)}
        />

        {/* ACCOUNT NUMBER */}
        <TextField 
          label='Account Number' 
          value={accountBankForms.accountNumber}
          name='accountNumber'
          variant='standard' 
          type='number'
          fullWidth
          sx={{marginTop: '10px'}}
          onChange={(event) => handleFormsChange(event)}
          className={classes.fieldNumber}
        />

        {/* DESCRIPTION BANK */}
        <TextField 
          label='Description Bank' 
          value={accountBankForms.description}
          name='description'
          variant='standard' 
          fullWidth
          sx={{marginTop: '10px'}}
          onChange={(event) => handleFormsChange(event)}
        />
      </DialogContent>

      {/* DIALOG ACTIONS */}
      <DialogActions className='padding0'>
        <Stack 
          direction='row'
          spacing={2}
          marginTop='6px'
        >
          {/* CANCEL BUTTON */}
          <Button 
            variant='outlined'
            onClick={() => {
              setIsDialogBankAccountOpen(false)
              setAccountBankForms(initialForms)
            }}
            className='flex1'
          >
            Cancel
          </Button>

          {/* CONTINUE BUTTON */}
          <Button 
            variant='contained'
            onClick={handleSaveButton}
            className='flex1'
            disabled={handleDisableSaveButton()}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAddOrChangeBankAccount