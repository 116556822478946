import { useState, useContext } from 'react'

// ASSETS
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// MUI LABS
import LoadingButton from '@mui/lab/LoadingButton'

// SERVICES
import { postResetPassword } from 'services/depoOwner'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

// UTILITIES
import { getDefaultErrorMessage } from 'utilities/string'
import { isPasswordFormatValid } from 'utilities/validation'
import { useSearchParams } from 'react-router-dom'
import ResetPasswordSuccess from './ResetPasswordSuccess'

const ResetPassword = () => {
  const layoutClasses = useLayoutStyles()

  const [searchParams] = useSearchParams()
  const { setSnackbarObject } = useContext(AllPagesContext)
  const [ selectedForm, setSelectedForm ] = useState(1)

  const token = searchParams.get('token')

  const initialResetForm = {
    newPassword: '',
    confirmPassword: '',
    token: '',
  }

  const initialErrorForm = {
    newPassword: null,
    confirmPassword: null,
  }

  const [ resetForm, setResetForm ] = useState(initialResetForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ showPassword, setShowPassword ] = useState(false)

  const handleResetFormChange = (event) => {
    setResetForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const updateErrorForm = (inputName, inputNewValue) => {
    setErrorForm(current => ({
      ...current,
      [inputName]: inputNewValue,
    }))
  } 

  const handleFormButtonClick = async (inputEvent) => {
    inputEvent.preventDefault()

    if (selectedForm === 1) {
      if (!isPasswordFormatValid(resetForm.newPassword)) updateErrorForm('newPassword', 'Must contain at least 1 uppercase, lowercase and numeric character.')
      else {
        updateErrorForm('newPassword', null)
      }

      if (isPasswordFormatValid(resetForm.newPassword) && resetForm.newPassword !== resetForm.confirmPassword) updateErrorForm('confirmPassword', ' The password is not the same as the real one!.')
      else updateErrorForm('confirmPassword', null)

      if (isPasswordFormatValid(resetForm.newPassword) && resetForm.newPassword === resetForm.confirmPassword) {
        // RESET PASSWORD
        const resultResetPassword = await postResetPassword({
          password: resetForm.newPassword,
          confirm_password: resetForm.confirmPassword,
          token: token,
        })

        // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY CHANGE PASSWORD
        if (resultResetPassword.status === 200) {
          setSnackbarObject({
            open: true,
            severity: 'success',
            title: '',
            message: 'Successfully reset password',
          })
          setSelectedForm(2)
        }
        // SHOW ERROR MESSAGE AFTER FAILED TO REGISTR THE USER
        else {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: 'Failed to reset password',
            message: getDefaultErrorMessage(resultResetPassword),
          })
        }
      }
    }
  }

  return (
    <form 
      className={layoutClasses.root}
      onSubmit={handleFormButtonClick}
    >
      {/* FIRST FORM */}
      {selectedForm === 1 && 
      <>
        {/* PRODUCT LOGO */}
        <Box
          component='img'
          src={LogoProductNleConnet}
          alt=''
          className={layoutClasses.productLogo}
        />

        {/* TITLE */}
        <Typography variant='h6'>
          Reset Password
        </Typography>

        {/* EMPTY CAPTION */}
        <Typography
          variant='caption'
          className={layoutClasses.pageTitle}
        />

        {/* NEW PASSWORD FORM */}
        <FormControl 
          variant='standard'
          className='formTextField'
          color='secondary'
          error={Boolean(errorForm.newPassword)}
        >
          {/* NEW PASSWORD INPUT LABEL */}
          <InputLabel>
            New Password
          </InputLabel>

          {/* NEW PASSWORD INPUT */}
          <Input
            autoFocus 
            type={showPassword ? 'text' : 'password'}
            name='newPassword'
            value={resetForm.newPassword}
            onChange={handleResetFormChange}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowPassword(current => !current)}
                  edge='end'
                >
                  {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                </IconButton>
              </InputAdornment>
            }
          />

          {/* NEW PASSWORD HELPER TEXT */}
          {Boolean(errorForm.newPassword) &&         
          <FormHelperText>
            {errorForm.newPassword}
          </FormHelperText>}
        </FormControl>

        {/* CONFIRM PASSWORD  FORM */}
        <FormControl 
          variant='standard'
          className='formTextField'
          color='secondary'
          error={Boolean(errorForm.confirmPassword)}
        >
        
          {/* CONFIRM PASSWORD INPUT LABEL */}
          <InputLabel>
            Confirm New Password
          </InputLabel>

          {/* CONFIRM PASSWORD  INPUT */}
          <Input 
            type={showPassword ? 'text' : 'password'}
            name='confirmPassword'
            onChange={handleResetFormChange}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowPassword(current => !current)}
                  edge='end'
                >
                  {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                </IconButton>
              </InputAdornment>
            }
          />

          {/* CONFIRM PASSWORD  HELPER TEXT */}
          {Boolean(errorForm.confirmPassword) &&     
          <FormHelperText>
            {errorForm.confirmPassword}
          </FormHelperText>}
        </FormControl>
      </>}

      {/* FINAL FORM */}
      {selectedForm === 2 &&
      <ResetPasswordSuccess/>}      

      {/* ACTION BUTTONS */}
      {selectedForm === 1 && 
      <Stack
        direction='row'
        className={layoutClasses.formButtonContainer}
      >
        {/* CHANGE PASSWORD BUTTON */}
        <LoadingButton
          className={layoutClasses.formButton}
          variant='contained'
          type='submit'
        >
          Save New Password
        </LoadingButton>
      </Stack>}
    </form>
  )
  
}

export default ResetPassword