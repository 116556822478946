import { Stack, Typography, Button, Box } from '@mui/material'
import { useState, useContext, useEffect } from 'react'

// ASSETS
import BankBCA from 'assets/images/logos/BankLogos/bank-bca.svg'
import BankBRI from 'assets/images/logos/BankLogos/bank-bri.svg'
import BankBNI from 'assets/images/logos/BankLogos/bank-bni.svg'
import BankBJB from 'assets/images/logos/BankLogos/bank-bjb.svg'
import BankBSI from 'assets/images/logos/BankLogos/bank-bsi.svg'
import BankMandiri from 'assets/images/logos/BankLogos/bank-mandiri.svg'
import BankPermata from 'assets/images/logos/BankLogos/bank-permata.svg'

// COMPONENTS
import DialogAddOrChangeBankAccount from './DialogAddOrChangeBankAccount'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// SERVICE 
import { getDepoBankAccount } from 'services/profile'

const BankAccount = () => {
  const { auth } = useContext(AllPagesContext)

  const initialForms = {
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    description: '',
  }

  // LIST OF BANKS
  const listOfBanks = [
    {
      label: 'PT. BCA (Bank Central Asia) TBK / BCA',
      value: 'BCA',
    },
    {
      label: 'PT. Bank Mandiri / Mandiri',
      value: 'MANDIRI',
    },
    {
      label: 'PT. Bank Negara Indonesia (BNI) (Persero) / BNI',
      value: 'BNI',
    },
    {
      label: 'PT. Bank RAKYAT Indonesia (BRI) (Persero) / BRI',
      value: 'BRI',
    },
    {
      label: 'PT. Bank SYARIAH Indonesia / BSI',
      value: 'BSI',
    },
    {
      label: 'PT. Bank PERMATA TBK / PERMATA',
      value: 'PERMATA',
    },
    {
      label: 'PT. Bank JABAR DAN BANTEN / BJB',
      value: 'BJB',
    },
  ]

  const [isDialogBankAccountOpen, setIsDialogBankAccountOpen] = useState(false)
  const [accountBankData, setAccountBankData] = useState(undefined)
  const [accountBankForms, setAccountBankForms] = useState(initialForms)

  // FETCH DEPO BANK DATA
  const loadDepoBank = async (inputIsMounted, inputAbortController) => {
    const resultDepoBank = await getDepoBankAccount(inputAbortController.signal, auth.accessToken)

    if(resultDepoBank.status === 200 && inputIsMounted) {
      setAccountBankData(resultDepoBank?.data[0])
    }
  }

  // HANDLE GET BANK DEPO NAME
  const getBankDepoName = (inputData) => {
    if(inputData === 'BCA') return 'PT. BCA (Bank Central Asia) TBK / BCA'
    else if(inputData === 'MANDIRI') return 'PT. Bank Mandiri / Mandiri'
    else if(inputData === 'BNI') return 'PT. Bank Negara Indonesia (BNI) (Persero) / BNI'
    else if(inputData === 'BSI') return 'PT. Bank SYARIAH Indonesia / BSI'
    else if(inputData === 'PERMATA') return 'PT. Bank PERMATA TBK / PERMATA'
    else if(inputData === 'BJB') return 'PT. Bank JABAR DAN BANTEN / BJB'
    else if(inputData === 'BRI') return 'PT. Bank RAKYAT Indonesia (BRI) (Persero) / BRI'
  }

  // HANDLE GET BANK LOGO
  const getBankLogo = (inputData) => {
    if(inputData === 'BCA') return BankBCA
    else if(inputData === 'MANDIRI') return BankMandiri
    else if(inputData === 'BNI') return BankBNI
    else if(inputData === 'BSI') return BankBSI
    else if(inputData === 'PERMATA') return BankPermata
    else if(inputData === 'BJB') return BankBJB
    else if(inputData === 'BRI') return BankBRI
  }

  // HANDLE EDIT DEPO BANK ACCOUNT
  const handleEditBankAccount = () => {
    setAccountBankForms({
      bankName: listOfBanks.find((item) => item.value === accountBankData.bank_code),
      accountHolderName: accountBankData?.account_holder_name,
      accountNumber: accountBankData?.account_number,
      description: accountBankData?.description_bank,
    })
    setIsDialogBankAccountOpen(true)
  }

  // SIDE EFFECT LOAD DEPO BANK
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadDepoBank(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack >
      {/* MAIN TITLE */}
      <Typography variant='h6'>
        List of Bank Accounts
      </Typography>

      {/* CONTENT */}
      {accountBankData === undefined ? 
        // BUTTON ADD OR CHANGE ACCOUNT BANK 
        <Stack 
          marginTop='6px'
          width='155px'
        >
          <Button 
            variant='contained'
            size='large'
            onClick={() => setIsDialogBankAccountOpen(true)}
          >
            add account
          </Button>
        </Stack> : 
        // ACCOUNT BANK PREVIEW
        <Stack>
          {/* BANK LOGO & INFORMATION */}
          <Stack 
            direction='row'
            alignItems='center'
            spacing={2}
          >
            {/* BANK LOGO */}
            <Box
              component='img'
              src={getBankLogo(accountBankData?.bank_code)}
            />

            {/* INFORMATIONS */}
            <Stack>
              {/* BANK NAME */}
              <Typography
                variant='caption'
                color='text.secondary'
              >
                {getBankDepoName(accountBankData?.bank_code)}
              </Typography>

              {/* ACCOUNT NUMBER */}
              <Typography
                variant='caption'
                sx={{fontWeight: 600}}
              >
                {accountBankData?.account_number}
              </Typography>

              {/* ACCOUNT HOLDER NAME */}
              <Typography variant='caption'>
                a.n <span style={{fontWeight : 600}}> {accountBankData?.account_holder_name} </span>
              </Typography>

              {/* DESCRIPTION */}
              <Typography variant='caption'>
                {accountBankData?.description_bank}
              </Typography>
            </Stack>
          </Stack>

          {/* BUTTON CHANGE ACCOUNT BANK */}
          <Stack 
            marginTop='12px'
            width='155PX'
          >
            <Button 
              variant='contained'
              size='large'
              onClick={handleEditBankAccount}
            >
              edit account
            </Button>
          </Stack>
        </Stack>
      }

      {/* DIALOG ADD OR CHANGE BANK ACCOUNT */}
      <DialogAddOrChangeBankAccount
        isDialogBankAccountOpen={isDialogBankAccountOpen}
        setIsDialogBankAccountOpen={setIsDialogBankAccountOpen}
        accountBankForms={accountBankForms}
        setAccountBankForms={setAccountBankForms}
        reloadData={loadDepoBank}
      />
    </Stack>
  )
}

export default BankAccount