// MUIS
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICON
import CheckIcon from '@mui/icons-material/Check'

// STYLES
import useStyles from './moveFleetManagerUseStyles'

// UTILITIES
import { addSeparatorsForNumber } from 'utilities/string'

const InsightList = (props) => {
  const { list,
    listButtonFM,
    handleListButtonClickFM,
    handlefleetManagerItemClick } = props
  
  const classes = useStyles()
  
  return (
    <>
      <Stack 
        direction='row'
        justifyContent='space-between'
        marginBottom='20px'
      >
        <Typography 
          variant='h6'
          className={classes.paperTitle}
        >
          Moves by fleet manager
        </Typography>

        <Stack direction='row'>
          {listButtonFM.map((item, index) => (
            <ListItem 
              key={index}
              className={classes.listItem}
              sx={{backgroundColor : item.isClicked ? '#F9664914' : 'transparent'}}
            >
              <ListItemButton
                className={classes.listItemButton} 
                onClick={() => handleListButtonClickFM(item)}>
                {/* CHECKLIST ICON */}
                {item.isClicked && <ListItemIcon className={classes.checkIcon}>
                  <CheckIcon/>
                </ListItemIcon>}
                  
                {/* TEXT BUTTON */}
                <ListItemText 
                  className={classes.textButton}
                  sx={(theme) => ({color : item.isClicked ? theme.palette.primary.main : 'black'})}
                >
                  {item.day}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </Stack>
      </Stack>

      <Grid 
        container
        spacing='12px'
        marginBottom='40px'
        justifyContent={list.length < 5 ? 'flex-start' : 'space-between'}
      >
        {list?.map((item, index) => (
          <Grid
            item
            xs={2.25}
            key={index}
          >
            <Stack 
              onClick={() => handlefleetManagerItemClick(item)}
              className={classes.itemRoot}
              sx={(theme) => ({borderBottom : item.isActive ? `4px solid ${item.color}` : `1px solid ${theme.palette.divider}`})}>
              {/* HEADER */}
              <Stack 
                direction='row'
                alignItems='center'
                className={classes.headerRoot}
              >
                {/* HEADER TITLE */}
                <Typography
                  variant='subtitle2'
                  className='textSemiBold'
                >
                  {item.name}
                </Typography>
              </Stack>

              {/* VALUE */}
              <Typography
                variant='h4'
                className='textMedium'
              >
                {addSeparatorsForNumber(item.totalMove)}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default InsightList