// COMPONENTS
import Chart from '../Chart'
import MoveFleetManager from '../Move Fleet Manager/MoveFleetManager'
import MoveStatistic from '../MoveStatistic/MoveStatistic'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './mainContentUseStyles'

const MainContentItem = (props) => {
  const { 
    title, 
    fleetManagerList,
    chartOptions,
    chartSeriesList,
    type,
    listButtonMS,
    handleListButtonClick,
    handleListButtonClickFM,
    handlefleetManagerItemClick,
    totalMoves,
    listButtonFM,
  } = props

  const layoutClasses = useLayoutStyles()
  const classes = useStyles()
  
  return (
    <Stack className={classes.root}>
      {/* TITLE */}
      <Typography
        variant='h6'
        className={layoutClasses.paperTitle}
      >
        {title}
      </Typography>

      {/* INSIGHT LIST */}
      {
        type === 'moveStatistic' ?
          <MoveStatistic 
            listButtonMS={listButtonMS}
            totalMoves={totalMoves}
            handleListButtonClick={handleListButtonClick} /> :
          <MoveFleetManager 
            list={fleetManagerList}
            listButtonFM={listButtonFM}
            handleListButtonClickFM={handleListButtonClickFM}
            handlefleetManagerItemClick={handlefleetManagerItemClick} /> 
      }

      {/* CHART */}
      <Chart
        options={chartOptions}
        series={chartSeriesList}
      />
    </Stack>
  )
}

export default MainContentItem