// PAGES
import Booking from 'pages/Orders/Orders'
import CompanyLegalities from 'pages/CompanyLegalities/CompanyLegalities'
import Devices from 'pages/Devices/Devices'
import FleetManager from 'pages/FleetManager/FleetManager'
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword'
import Home from 'pages/Home/Home'
import Impersonate from 'pages/Impersonte/Impersonate'
import Inventory from 'pages/Inventory/Inventory'
import Items from 'pages/Items/Items'
import Moves from 'pages/Moves/Moves'
import Report from 'pages/Report/Report'
import Profile from 'pages/Profile/Profile'
import ResetPassword from 'pages/ResetPassword/ResetPassword'
import SignIn from 'pages/SignIn/SignIn'
import SignUp from 'pages/SignUp/SignUp'

const routes = [
  // AUTHENTICATION
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  {
    path: '/sign-up',
    element: <SignUp/>,
    routeType: 'authentication',
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword/>,
    routeType: 'authentication',
  },
  {
    path: '/reset-password',
    element: <ResetPassword/>,
    routeType: 'authentication',
  },
  {
    path:'/company-legalities',
    element:<CompanyLegalities/>,
    routeType:'authentication'
  },
  // PRIVATE
  {
    path: '/',
    element: <Home/>,
    routeType: 'private',
  },
  {
    path: '/moves',
    element: <Moves/>,
    routeType: 'private',
  },
  {
    path: '/devices',
    element: <Devices/>,
    routeType: 'private',
  },
  {
    path: '/inventory',
    element: <Inventory/>,
    routeType: 'private',
  },
  {
    path: '/profile',
    element: <Profile/>,
    routeType: 'private',
  },
  {
    path: '/fleet-manager',
    element: <FleetManager/>,
    routeType: 'private',
  },
  {
    path: '/items',
    element: <Items/>,
    routeType: 'private',
  },
  {
    path: '/orders',
    element: <Booking/>,
    routeType: 'private',
  },
  {
    path: '/report',
    element: <Report/>,
    routeType: 'private',
  },
  // FREE
  {
    path: '/impersonate/token=:token',
    element: <Impersonate/>,
    routeType: 'free',
  },
]

export default routes