import { useContext, useState } from 'react'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  FormControl,
  FormHelperText,
  Stack, 
  TextField,
  Typography,
  Button,
} from '@mui/material'

// MUI ICONS
import EmailIcon from '@mui/icons-material/Email'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import SaveIcon from '@mui/icons-material/Save'
import TextFormatIcon from '@mui/icons-material/TextFormat'

// STYLES 
import useStyles from '../../orderUseStyles'

const EditContact = (props) => {
  const { setIsEditingContact } = props
  const classes = useStyles()
  const { setSnackbarObject} = useContext(AllPagesContext)
  const { 
    orderForms, 
    setOrderForms,
  } = useContext(PrivateLayoutContext)

  const [isValidatingForms, setIsValidatingForms] = useState(false)
  
  // HANDLE ORDER FORM CHANGE
  const handleOrderFormsChange = (event) => {
    setOrderForms(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // HANDLE FORMS VALIDATION
  const handleFormsValidation = () => {
    // VALIDATING ALL FORMS
    if(
      orderForms.name === '' ||
      orderForms.phoneNumber === '' ||
      orderForms.email === '' 
    ) {
      setIsValidatingForms(true)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Required fields must be filled in',
      })
    } else {
      setIsValidatingForms(false)
      setIsEditingContact(false)
    }
  }

  return (
    <>
      {/* TITLE */}
      <Stack
        marginTop='20px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography className={classes.typography1}>
          Contact Order Details
        </Typography>

        <Button 
          startIcon={<SaveIcon/>}
          variant='outlined'
          onClick={handleFormsValidation}
        >
          Save
        </Button>
      </Stack>

      {/* CONTENT */}
      {/* FULL NAME */}
      <Stack
        direction='row'
        alignItems='center'  
        marginTop='20px'
        width='100%'        
      >
        <TextFormatIcon className={classes.formIcon}/>
        <FormControl 
          fullWidth
          error
        >
          <TextField
            fullWidth
            label='Full Name'
            variant='standard'
            name='name'
            value={orderForms.name}
            onChange={(event) => handleOrderFormsChange(event)}
            error={(isValidatingForms && orderForms.name === '') ? true : false}
          />
          {/* HELPER TEXT */}
          {(isValidatingForms && orderForms.name === '') && 
          <FormHelperText>This field can't be empty</FormHelperText>}
        </FormControl>
      </Stack>

      {/* PHONE NUMBER */}
      <Stack
        direction='row'
        alignItems='center'  
        marginTop='20px'
        width='100%'        
      >
        <ContactPhoneIcon className={classes.formIcon}/>
        <FormControl 
          fullWidth
          error
        >
          <TextField
            className={classes.fieldNumber}
            fullWidth
            label='Phone Number'
            type='number'
            variant='standard'
            name='phoneNumber'
            value={orderForms.phoneNumber}
            onChange={(event) => handleOrderFormsChange(event)}
            error={(isValidatingForms && orderForms.phoneNumber === '') ? true : false}
          />
          {/* HELPER TEXT */}
          {(isValidatingForms && orderForms.phoneNumber === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
        </FormControl>
      </Stack>

      {/* EMAIL */}
      <Stack
        direction='row'
        alignItems='center'  
        marginTop='20px'
        width='100%'        
      >
        <EmailIcon className={classes.formIcon}/>
        <FormControl 
          fullWidth
          error
        >
          <TextField
            fullWidth
            label='Email'
            variant='standard'
            name='email'
            type='email'
            value={orderForms.email}
            onChange={(event) => handleOrderFormsChange(event)}
            error={(isValidatingForms && orderForms.email === '') ? true : false}
          />
          {/* HELPER TEXT */}
          {(isValidatingForms && orderForms.email === '') && 
          <FormHelperText>This field can't be empty</FormHelperText>}
        </FormControl>
      </Stack>
    </>
  )
}

export default EditContact