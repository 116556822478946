import { useState } from 'react'
import moment from 'moment'

// COMPONENTS
import ReportDateRangePicker from 'components/DateRangeTimePicker/ReportDateRangePicker'

// MUIS
import { 
  Button,
  Dialog, 
  InputAdornment, 
  Slider, 
  Stack, 
  Typography, 
  TextField, 
} from '@mui/material'

// MUI ICONS
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// STYLES
import useStyles from '../reportUseStyles'
import useLayoutStyles from 'styles/layoutPrivate'

const Trips = (props) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const { 
    isContentExpanded,
    fleetManagerList,
    setFleetManagerList,
    setIsCreatingMoves,
    setIsCreatingReport,
  } = props

  const initialDayList = [
    {
      name: 'Mo',
      isActived: false,
    },
    {
      name: 'Tu',
      isActived: false,
    },
    {
      name: 'We',
      isActived: false,
    },
    {
      name: 'Th',
      isActived: false,
    },
    {
      name: 'Fr',
      isActived: false,
    },
    {
      name: 'Sa',
      isActived: false,
    },
    {
      name: 'Su',
      isActived: false,
    },
  ]

  const listFilterTimeButton = [
    {
      name: 'Day'
    },
    {
      name: 'Night'
    },
    {
      name: 'Always'
    }
  ]

  const [isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen] = useState(false)
  const [dateRangeTimeValue, setDateRangeTimeValue] = useState([null, null])
  const [dateRangeInputValue, setDateRangeInputValue] = useState('')
  const [label, setLabel] = useState('')
  const [dayList, setDayList] = useState(initialDayList)
  const [timeValue, setTimeValue] = useState([0, 86399])

  // HANDLE DATE RANGE PICKER
  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)

    let startDate = moment(newValue[0]).format('DD MMM YYYY')
    let endtDate = moment(newValue[1]).format('DD MMM YYYY')

    setDateRangeInputValue(`${startDate} - ${endtDate}`)
  }

  // HANDLE DAY PICKER
  const handleDayListItemClick = (inputItem) => {
    const newDayList = [...dayList].map((item) => {
      if(item.name === inputItem.name) item.isActived = !item.isActived

      return item
    })

    setDayList(newDayList)
  }

  // HANDLE SLIDER CHANGE
  const handleSliderChange = (event, value) => {
    setTimeValue(value)
  }

  // FUNCTION CONVER SECONDS TO HOURS AND MINUTES
  const convertSecondsToHoursAndMinutes = (inputValue) => {
    const newDate = moment().startOf('day').toDate()
    const addSeconds = moment(newDate).add(inputValue, 'seconds').toDate() 

    return moment(addSeconds).format('HH:mm')
  }

  // FUNCTION HANDLE FILTER TIME CLICK
  const handleFilterTIme = (inputValue) => {
    if(inputValue.name === 'Day') setTimeValue([21600, 64800])
    else if(inputValue.name === 'Night') setTimeValue([64800, 86399])
    else setTimeValue([0, 86399])
  }

  // HANDLE RESET FORMS
  const handleResetForms = () => {
    const newFleetManager = [...fleetManagerList].map((item) => {
      item.isChecked = false
      return item
    })

    // RESET FLEET MANAGER LIST
    setFleetManagerList(newFleetManager)
    // RESET FORMS
    setDateRangeInputValue('')
    setDateRangeTimeValue([null, null])
    setTimeValue([0, 86399])
    setDayList(initialDayList)
    setLabel('')
    setIsCreatingMoves(false)
    setIsCreatingReport(false)
  }

  return (
    <Stack 
      className={classes.tripsRoot}
      left={isContentExpanded ? 480 : 280}
      sx={{transition: 'left 0.5s'}}
    >
      {/* TITLE */}
      <Stack padding='24px 20px'>
        <Typography className={classes.typography1}>
          Trips
        </Typography>
      </Stack>

      {/* CONTENTS */}
      <Stack 
        spacing={3}
        padding='0px 20px'
      >
        {/* LABEL */}
        <TextField 
          label='Label' 
          variant='standard'
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />

        {/* DATE RANGE */}
        <TextField 
          label='Date range' 
          variant='standard' 
          value={dateRangeInputValue}
          onClick={() => setIsDateRangeTimePickerOpen(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* DAY PICKER */}
        <Stack>
          <Typography className={classes.typography4}>
            Date range
          </Typography>

          <Stack
            justifyContent='space-between'
            direction='row'
            marginTop='8px'
          >
            {dayList.map((item, index) => (
              <Stack
                key={index}
                className={classes.dayPickerContainer}
                border={item.isActived ? '1px solid #F96649' : '1px solid #0000003B'}
                onClick={() => handleDayListItemClick(item)}
              >
                <Typography
                  variant='caption'
                  sx={(theme) => ({color : item.isActived ? theme.palette.primary.main : theme.palette.text.primary})}
                >
                  {item.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* CONTROL TIME */}
        <Stack>
          <Typography className={classes.typography4}>
            Control time from {convertSecondsToHoursAndMinutes(timeValue[0])} to {convertSecondsToHoursAndMinutes(timeValue[1])}
          </Typography>

          {/* GrowR */}
          <Stack className={`${classes.timeSlider} no-zoom`}>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={timeValue}
              min={0}
              max={86399}
              onChange={handleSliderChange}
              valueLabelDisplay='off'
            />
          </Stack>

          {/* FILTER TIME BUTTONS */}
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={2}
          >
            {listFilterTimeButton.map((item, index) => (
              <Typography
                key={index}
                variant='caption'
                color='primary'
                sx={{cursor: 'pointer'}}
                onClick={() => handleFilterTIme(item)}
              >
                {item.name}
              </Typography>
            ))}
          </Stack>

        </Stack>
      </Stack>

      {/* FOOTER */}
      <Stack
        position='absolute'
        bottom={0}
        left={0}
        padding='10px 20px 20px 70px '
        borderTop='1px solid #0000003B'
        width='100%'
        direction='row'
        spacing={2}
      >
        <Button 
          onClick={handleResetForms}
          variant='outlined' 
          size='small'
        >
          Cancel
        </Button>

        <Button 
          variant='contained' 
          size='small'
          onClick={handleResetForms}
        >
          Build Reports
        </Button>
      </Stack>

      {/* DATE RANGE TIME PICKER DIALOG */}
      <Dialog 
        open={isDateRangeTimePickerOpen}
        onClose={() => setIsDateRangeTimePickerOpen(false)} 
        className={layoutClasses.dialogDateRangePicker}
      >
        <ReportDateRangePicker
          value={dateRangeTimeValue}
          isWithTimePicker={false}
          handleSelectButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelButtonClick={() => setIsDateRangeTimePickerOpen(false)}
        />
      </Dialog>
    </Stack>
  )
}

export default Trips