// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  generatedReportRoot: {
    boxShadow: theme.shadows[3],
    background: 'white',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 4,
  },
  verticalText: {
    writingMode: 'vertical-lr',
    textTransform: 'uppercase',
    marginTop: 16,
    fontSize: 14,
    fontWeight: 500,
    transform: 'rotate(180deg)'
  },
  typography1: {
    fontSize: 16,
    fontWeight: 600,
  },
  typography2: {
    fontSize: 12,
    fontWeight: 400,
  },
  typography3: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  typography4: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  movesContainer: {
    marginTop: 4,
    padding: '4px 20px',
    cursor: 'pointer',
    '&:hover' : {
      backgroundColor: '#F9664914'
    }
  },
  fleetManagerRoot: {
    borderLeft: '1px solid #0000001F',
    background: 'white',
    height: '100%',
    position: 'absolute',
    top: 0,
    borderRadius: 4,
    width: 240
  },
  fleetManagerSearchIcon: {
    width: 12,
    color: theme.palette.text.secondary,
    marginRight: 10,
  },
  fleetManagerInputBase: {
    '& .MuiInputBase-input' : {
      fontSize: '12px'
    }
  },
  tripsRoot: {
    borderLeft: '1px solid #0000001F',
    background: 'white',
    height: '100%',
    position: 'absolute',
    top: 0,
    borderRadius: 4,
    width: 300
  },
  dayPickerContainer: {
    justifyContent: 'center',
    direction: 'row',
    width: '32px',
    height: '32px',
    alignItems: 'center',
    borderRadius: '1px',
    cursor: 'pointer'
  },
  timeSlider: {
    marginTop: 12,
    padding: '0px 10px',
    '& .MuiSlider-track' : {
      color: theme.palette.secondary.main
    },
    '& .MuiSlider-thumb' : {
      color: 'white'
    },
    '& .MuiSlider-rail' : {
      color: theme.palette.text.secondary
    }
  },
  paymentRoot: {
    borderLeft: '1px solid #0000001F',
    background: 'white',
    height: '100%',
    position: 'absolute',
    top: 0,
    borderRadius: 4,
    width: 300
  },
}))

export default useStyles