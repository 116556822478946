// MUIS
import DialogActions from '@mui/material/DialogActions'
import { styled } from '@mui/material/styles'

const CustomDialogActions = styled(({ className, ...props }) => (
  <DialogActions
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: '12px 20px',
}))

export default CustomDialogActions