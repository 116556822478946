import React, { useContext, useState } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'

// MUIS
import { Button, TextField, Autocomplete, Divider, Typography, Stack, Popper } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import ApartmentIcon from '@mui/icons-material/Apartment'

// REACT WINDOW
import { VariableSizeList } from 'react-window'

// STYLES
import useStyles from './fleetManagerUseStyles'
import { forwardRef, useRef, useEffect, createContext } from 'react'

// SERVICES
import { getFleetManager, postRegisterFleetManager, putEditFleetManager } from 'services/fleetManager'

// UTILITIES
import { getDefaultErrorMessage } from 'utilities/string'

// STYLED POPPER
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

const DialogAddOrEditFleet = (props) => {
  const {
    actionType,
    editFleetManager,
    reloadData,
    setEditFleetManager,
  } = props

  const classes = useStyles()
  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)
  const {
    auth,
    setSnackbarObject,
  } = useContext(AllPagesContext)

  const initialFleetForm = {
    id: '',
    name: '',
    fleet: '',
    fleet_code: '',
  }
  const [fleetForm, setFleetForm] = useState(initialFleetForm)
  const [fleetList, setFleetList] = useState([])

  const handleActionButtonClick = async (inputEvent) => {
    const abortController = new AbortController()

    if (inputEvent === 'cancel') {
      setFleetForm(initialFleetForm)
      setIsDialogAddOrEditOpen(false)
    }
    else if (inputEvent === 'save') {
      // ADD FLEET MANAGER
      if (actionType === 'ADD_FLEET_MANAGER') {
        const resultSaveFleetManager = await postRegisterFleetManager(
          abortController.signal,
          {
            name: fleetForm.name,
            fleet_code: fleetForm.fleet.code,
          },
          auth.accessToken,
        )
        // SHOW SUCCESS MESSAGE
        if (resultSaveFleetManager.status === 200) {
          setSnackbarObject({
            open: true,
            severity: 'success',
            title: '',
            message: 'Successfully add fleet manager',
          })
          setIsDialogAddOrEditOpen(false)
          setFleetForm(initialFleetForm)
          reloadData(true, abortController.signal)
        }
        // SHOW ERROR MESSAGE
        else {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: 'Failed to add fleet manager',
            message: getDefaultErrorMessage(resultSaveFleetManager),
          })
        }
      }

      // EDIT FLEET MANAGER
      else if (actionType === 'EDIT_FLEET_MANAGER') {
        const resultEditFleetManager = await putEditFleetManager(
          abortController.signal,
          {
            id: fleetForm.id,
            name: fleetForm.name,
            fleet_code: fleetForm.fleet.code,
          },
          auth.accessToken,
        )

        // SHOW SUCCESS MESSAGE
        if (resultEditFleetManager.status === 200) {
          setSnackbarObject({
            open: true,
            severity: 'success',
            title: '',
            message: 'Successfully edit fleet manager',
          })
          setIsDialogAddOrEditOpen(false)
          setFleetForm(initialFleetForm)
          reloadData(true, abortController.signal)
        }
        // SHOW ERROR MESSAGE
        else {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: 'Failed to edit fleet manager',
            message: getDefaultErrorMessage(resultEditFleetManager),
          })
        }
      }
    }
  }

  // GET ALL FLEETS
  const loadFleetManagerList = async (inputIsMounted, inputAbortController) => {
    const resultFleetManagerList = await getFleetManager(
      inputAbortController.signal,
      {
        size: 200
      },
      auth.accessToken,
    )

    if (resultFleetManagerList.status === 200 && inputIsMounted) {
      setFleetList(resultFleetManagerList.data.rows)
    }
  }

  // RESET CACHE
  function useResetCache(data) {
    const ref = useRef(null)
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true)
      }
    }, [data])
    return ref
  }

  // OUTER ELEMENT
  const OuterElementContext = createContext({})
  const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
  })

  // RENDER ROW
  function renderRow(props) {
    const LISTBOX_PADDING = 8
    const { data, index, style } = props
    const dataSet = data[index]
    const inlineStyle = {
      ...style,
      top: style.top + LISTBOX_PADDING,
    }

    return (
      <Stack
        component='li'
        {...dataSet[0]}
        nowrap='true'
        style={inlineStyle}
      >
        <Stack
          direction='row'
          width='100%'
          justifyContent='space-between'
        >
          {/* COMPANY NAME */}
          <Typography variant='body2'>
            {dataSet[1].description ?? '-'}
          </Typography>
        </Stack>

        <Stack
          direction='row'
          width='100%'
          justifyContent='space-between'
        >
          {/* FLEET CODE */}
          <Typography
            sx={{ color: '#00000099' }}
            variant='caption'
          >
            {dataSet[1].code ?? '-'}
          </Typography>
        </Stack>
      </Stack>
    )
  }

  // ADAPTER FOR REACT WINDOW
  const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData = []
    children.forEach((item) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    })

    const itemCount = itemData.length
    const itemSize = 50

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={300}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  })

  useEffect(() => {
    if (actionType === 'EDIT_FLEET_MANAGER') {
      // SET FORM
      let newItem = [...fleetList].filter((item) => item.code === editFleetManager?.code)
      let newInitialFleetForm = {
        id: editFleetManager?.depo_fleet_id,
        name: editFleetManager?.name,
        fleet: newItem[0],
      }
      setFleetForm(newInitialFleetForm)
    }
  }, [editFleetManager, actionType])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadFleetManagerList(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <DialogAddOrEdit containerWidth='704px'>
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        <Stack direction='row' marginTop='12px'>
          {/* CLOSE ICON */}
          <IconClose
            className={classes.headerCloseIcon}
            onClick={() => {
              setFleetForm(initialFleetForm)
              setIsDialogAddOrEditOpen(false)
              setEditFleetManager(null)
            }}
          />

          {/* TITLE */}
          <Typography className='textSemiBold'>
            {actionType === 'EDIT_FLEET_MANAGER' ? 'Edit Fleet Manager' : 'Add Fleet Manager'}
          </Typography>
        </Stack>
      </CustomDialogTitle>

      <Divider className={classes.divider} />

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        <Stack padding='8px 20px'>
          {/* NAME */}
          <Stack
            direction='row'
            alignItems='center'
          >
            <TextFormatIcon className={classes.formIcon} />
            {/* CONSGINEE FORM */}
            <TextField
              name='name'
              variant='standard'
              type='name'
              label='Name'
              value={fleetForm.name}
              className={classes.fleetManagerForm}
              onChange={(event) =>
                setFleetForm(current => ({
                  ...current,
                  [event.target.name]: event.target.value,
                }))}
            />
          </Stack>

          {/* FLEET MANAGER */}
          <Stack
            direction='row'
            marginTop='39px'
            alignItems='center'
          >
            <ApartmentIcon className={classes.formIcon} />

            <Autocomplete
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              options={fleetList}
              className={classes.fleetManagerForm}
              value={fleetForm?.fleet || null}
              renderOption={(props, option) => [props, option]}
              getOptionLabel={(option, value) => option?.description}
              isOptionEqualToValue={(option, value) => option.description === value.description}
              onChange={(event, value) =>
                setFleetForm(current => ({
                  ...current,
                  fleet: value,
                  fleet_code: value?.code ?? '',
                }))}
              renderInput={(params) => <TextField {...params} variant='standard' label='Fleet manager' />}
            />
          </Stack>

        </Stack>
      </CustomDialogContent>

      {/* DIALOG ACTION */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button
          variant='outlined'
          onClick={() => handleActionButtonClick('cancel')}
        >
          Cancel
        </Button>

        {/* SAVE BUTTON */}
        <Button
          variant='contained'
          onClick={() => handleActionButtonClick('save')}
        >
          Save
        </Button>
      </CustomDialogActions>
    </DialogAddOrEdit>
  )
}

export default DialogAddOrEditFleet