// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    maxWidth: '1100px',
    top: '50%',
    left: '50%',
    backgroundColor:'white',
    transform: 'translate(-50%, -50%)',
  },
  fileExample: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  dataGridContainer: {
    height: 500,
    boxShadow: theme.shadows[3],
    '& .empty-value' : {
      backgroundColor: theme.palette.error.main
    }
  },
  importer : {
    '& .CSVImporter_FileSelector' : {
      zoom: 0.85
    },
    '& .CSVImporter_ImporterFrame__headerTitle' : {
      fontSize: 14,
    },
    '& .CSVImporter_IconButton>span' : {
      width: 24,
      height: 24,
    },
    '& .CSVImporter_IconButton:hover' : {
      background: 'transparent'
    },
    '& .CSVImporter_FileStep__header' : {
      fontSize: 14
    },
    '& .CSVImporter_FormatRawPreview__pre' : {
      fontSize: 14,
    },
    '& .CSVImporter_TextButton' : {
      fontSize: 14,
    },
    '& .CSVImporter_ImporterFrame__headerSubtitle' : {
      fontSize: 14,
    },
    '& .CSVImporter_ColumnDragTargetArea__boxLabel' : {
      fontSize: 14,
      fontWeight: 500,
    }
  }
}))

export default useStyles